// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-app-dowloads__apple {
  padding: 7px 17px;
}
.mobile-app-dowloads__apple, .mobile-app-dowloads__google {
  border: 1px solid #1f2024;
  border-radius: 5px;
}
@media all and (min-width: 0px) and (max-width: 730px) {
  .mobile-app-dowloads a > img {
    width: 120px;
    height: 35px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/GoogleApple/GoogleApple.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;AAAR;AAGI;EAEI,yBAAA;EACA,kBAAA;AAFR;AAMQ;EADJ;IAEQ,YAAA;IACA,YAAA;EAHV;AACF","sourcesContent":[".mobile-app-dowloads {\n    &__apple {\n        padding: 7px 17px;\n    }\n\n    &__apple,\n    &__google {\n        border: 1px solid #1f2024;\n        border-radius: 5px;\n    }\n\n    a > img {\n        @media all and (min-width: 0px) and (max-width: 730px) {\n            width: 120px;\n            height: 35px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
