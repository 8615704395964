import React, { useEffect } from 'react';
import handlesPic from '../../assets/img/investors-handles.png'
import LogosSlider from '../../components/LogosSlider/indes';
import SIgnUpNewsletter from '../../components/SIgnUpNewsletter';
import Partners from '../../components/Partners';
import OurCases from '../../components/OurCases';
import ProductLounge from '../../components/ProductLounge';
import PoweringBusiness from '../../components/PoweringBusiness';
import HeroSection from '../../components/HeroSection';
import HeroSectionSaas from '../../components/HeroSectionSaas';

const SaasPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонента
  }, []);


  return (
    <div className="saas">
      <HeroSectionSaas/>
      <PoweringBusiness/>
      <ProductLounge/>
      <OurCases/>
      <Partners/>
    </div>
  );
}

export default SaasPage;