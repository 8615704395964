import React from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Home } from "./pages/Home";
import { Route, Routes, useParams } from "react-router-dom";
import "./sass/style.scss";
import NotFoundPage from "./pages/NotFoundPage"; 
import Investors from "./pages/Investors";
import SaasPage from "./pages/SaasPage";
import About from "./pages/About";
import Cookies from "./pages/Cookies";
import AccountData from "./pages/AccountData";
import Disclaimer from "./pages/Disclaimer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

export const App = () => {

    function Child() {
        const { refcode } = useParams();
        const originalReferrer = document.referrer;
        const encodedReferrer = encodeURIComponent(originalReferrer);
        const newLink = `http://paypolitan.page.link/?link=https://paypolitan.page.link/?link=com.paypolitan.dapp://referralCode/${refcode}&ibi=com.paypolitan.dapp&isi=1562351292&apn=com.paypolitan.app&referrer=${encodedReferrer}`;
        window.location.assign(newLink);
        return null;
    }
     
    return (
        <div className="app">
            <Header />
            <div className="main">
                <div className="container">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/product" element={<Home />} />
                        <Route path="/Investors" element={<Investors />} />
                        <Route path="/SaaSPage" element={<SaasPage />} />
                        <Route path="/About" element={<About />} />
                        <Route path="/Cookies" element={<Cookies />} />
                        <Route path="/Disclaimer" element={<Disclaimer />} />
                        <Route path="account-data" element={<AccountData />} />
                        <Route path="/policy" element={<PrivacyPolicy />} />
                        <Route path="/terms" element={<TermsAndConditions />} />
                        {/* Добавляем маршрут для страницы 404 */}
                        <Route path="*" element={<NotFoundPage />} />
                        <Route path="/referral/:refcode" element={<Child />} />
                    </Routes>
                </div>
            </div>
            <Footer />
        </div>
    );
};
  

export default App;
