// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-data {
  margin: 108px auto 60px;
}
.account-data p {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-family: "Futura PT Book", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #737380;
}
.account-data a {
  color: #bd1d83;
  text-decoration: underline;
}
.account-data .cookies h1,
.account-data .cookies h2 {
  font-family: "Futura PT Book", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/pages/AccountData/AccountData.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;AACI;EACE,uBAAA;EACA,qBAAA;EACA,sBAAA;EACA,oBAAA;EACA,yCAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACN;AAEI;EACE,cAAA;EACA,0BAAA;AAAN;AAGI;;EAEE,yCAAA;AADN","sourcesContent":[".account-data {\n    margin: 108px auto 60px;\n  \n    p {\n      margin-block-start: 1em;\n      margin-block-end: 1em;\n      margin-inline-start: 0;\n      margin-inline-end: 0;\n      font-family: \"Futura PT Book\", sans-serif;\n      font-style: normal;\n      font-weight: 500;\n      font-size: 20px;\n      line-height: 24px;\n      color: #737380;\n    }\n  \n    a {\n      color: #bd1d83;\n      text-decoration: underline;\n    }\n  \n    .cookies h1,\n    .cookies h2 {\n      font-family: \"Futura PT Book\", sans-serif;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
