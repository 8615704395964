import React from 'react';
import Icon1 from '../../assets/img/powering-business/1.svg';
import Icon2 from '../../assets/img/powering-business/2.svg';
import Icon3 from '../../assets/img/powering-business/3.svg';
import Icon4 from '../../assets/img/powering-business/4.svg';

import "./PoweringBusiness.scss";

const PoweringBusiness = () => {
  return (
    <div className="powering-business">
      <div className="powering-business__head">
        <h3 className="powering-business__title">
          Powering Businesses <span>With Blockchain</span>
        </h3>
      </div>

      <ul className="powering-business__list">
        <li className="powering-business__item">
          <div className="powering-business__item-icon">
            <img src={Icon1} alt="icon" />
          </div>

          <p className="powering-business__item-text">2 Offices</p>
        </li>

        <li className="powering-business__item">
          <div className="powering-business__item-icon">
            <img src={Icon2} alt="icon" />
          </div>

          <p className="powering-business__item-text">
            2 000<br/>
banks connected
</p>
        </li>

        <li className="powering-business__item">
          <div className="powering-business__item-icon">
            <img src={Icon3} alt="icon" />
          </div>

          <p className="powering-business__item-text">
          400 million Open <br/>
banking users (Europe)
          </p>
        </li>

        <li className="powering-business__item">
          <div className="powering-business__item-icon">
            <img src={Icon4} alt="icon" />
          </div>

          <p className="powering-business__item-text">
          170+ countries <br/> covered
          </p>
        </li>
      </ul>
    </div>
  );
}

export default PoweringBusiness;