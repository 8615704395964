import React, { useState } from 'react';

import OurStoryPic from '../../assets/img/hero-section/our-story-pic.png';
import { GetInTouchModal } from '../GetInTouchModal';
import "./HeroSection.scss";

const HeroSection = () => {
  const [isTextVisible, setIsTextVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const toggleTextVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="hero-section">
      <div className="hero-section__inner">
        <div className="hero-section__info">
          <div className="hero-section__head">
            <h1 className="hero-section__title">
              Our <span>story</span>
            </h1>
          </div>

          <p className={`hero-section__text ${isTextVisible ? '' : 'more'}`}>
            When people ask us “what is Paypolitan?” we give a simple answer: “Paypolitan is a next gen payment app: half Apple Pay, half MetaMask.” Users simply connect their existing bank accounts and external wallets and use our app for all kind of payment transactions for fiat and crypto. Paypolitan is aggregating various accounts and wallets. We are the first payment initiation service provider using blockchain technology, smart contracts and open banking APIs to provide a next-gen billing solution that meets the needs of modern payment systems for businesses and customers. Our app is totally free of charge and our virtual debit cards are completing the customer experience. Our journey started in early 2018. The app was launched in 2021 and in 2023 we opened our US corporation.
          </p>

          <button type="button" className="hero-section__more main-btn" onClick={toggleTextVisibility}>
            {isTextVisible ? 'Read more' : 'Close'}

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
            </svg>
          </button>

          <button type="button" className="hero-section__btn main-btn main-btn--yellow" onClick={toggleModal}>
            get in touch

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
            </svg>
          </button>

        </div>
        <div className="hero-section__picture">
          <img src={OurStoryPic} alt="pic" />
        </div>
      </div>

      <GetInTouchModal open={isModalOpen} onClose={toggleModal} />
    </div>
  );
}

export default HeroSection;