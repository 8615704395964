import React, { useState } from "react";
import PropTypes from "prop-types"
import { GetInTouchModalForm } from "../GetInTouchModalForm"
import { Snackbar } from "@mui/material";

const AUTO_HIDE_DURATION = 5000; // 5 sec
const ANCHOR_ORIGIN = { vertical: "top", horizontal: "center" }

export const GetInTouchModal = ({ title, open, onClose }) => {
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [failedSnackbarOpen, setFailureSnackbarOpen] = useState(false);

    const onSubmitSuccess = () => {
        onClose();
        setSuccessSnackbarOpen(true);
    }

    const onSubmitFailure = () => {
        setFailureSnackbarOpen(true)
    }

    const handleSuccessSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
    }

    const handleFailedSnackbarClose = () => {
        setFailureSnackbarOpen(false);
    }

    return <>
        <Snackbar
            message="Form submitted successfully"
            anchorOrigin={ANCHOR_ORIGIN}
            open={successSnackbarOpen}
            autoHideDuration={AUTO_HIDE_DURATION}
            onClose={handleSuccessSnackbarClose}
        />
        <Snackbar
            message="Failed to submit form"
            anchorOrigin={ANCHOR_ORIGIN}
            open={failedSnackbarOpen}
            autoHideDuration={AUTO_HIDE_DURATION}
            onClose={handleFailedSnackbarClose}
        />

        {
            open && <div className="modal show" id="modalGetIntouch">
                {/* Modal content */}
                <div className="modal-content modal-content--middle">
                    {/* Modal header */}
                    <div className="modal__header">
                        <h3 className="modal__title modal__title--xl">{title ?? "Get in touch"}</h3>
                        <button type='button' className="close-modal-btn" onClick={onClose}>
                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="22" cy="22" r="22" fill="#E4E4E4" />
                                <line x1="15.8341" y1="14.8556" x2="29.0174" y2="28.039" stroke="black" strokeWidth="2" />
                                <line x1="15.4745" y1="28.039" x2="28.6579" y2="14.8556" stroke="black" strokeWidth="2" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal__inner">
                        <GetInTouchModalForm onSuccess={onSubmitSuccess} onFailure={onSubmitFailure} />
                    </div>

                    <button type='button' className="close-modal-btn close-modal-btn--mob" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        }
    </>

}

GetInTouchModal.protTypes = {
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}