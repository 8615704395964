import React, { useEffect } from 'react';
import Partners from '../../components/Partners';
import HeroSection from '../../components/HeroSection';
import Careers from '../../components/Careers';
import Media from '../../components/Media';
import GlobalPresence from '../../components/GlobalPresence';
import Roadmap from '../../components/Roadmap';
import Members from '../../components/Members';
import Team from '../../components/Team';
import ManagementSection from '../../components/ManagementSection';
import Appinfo from '../../components/Appinfo';
import Modals from '../../components/Modals';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонента
  }, []);

  return (
    <div className="about">
      <HeroSection/>
      <Appinfo/>
      <Partners/>
      <ManagementSection/>
      <Team/>
      <Members/>
      <Roadmap/>
      <GlobalPresence/>
      <Media/>
      <Careers/>
      {/* <Modals/> */}
    </div>
  );
}

export default About;