import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const SCROLL_TIMEOUT = 350
const TOP_OFFSET = 95

export default function useHashScroll(hashId) {
    const { hash } = useLocation()
    const elementRef = useRef(null)

    useEffect(() => {
        scrollToElement(hash)
    }, [hash])


    const scrollToElement = (locationHash) => {
        setTimeout(() => {
                if(locationHash === `#${hashId}` && elementRef) {
                    const { top } = elementRef.current.getBoundingClientRect();
                    const scrollTo = top + window.scrollY - TOP_OFFSET;
                    window.scrollTo(0, scrollTo);
                } else {
                    if (!locationHash){
                        window.scrollTo(0, 0);
                    } 
                }

        },SCROLL_TIMEOUT)
    };

    return elementRef
}