// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect-wallet {
  flex-direction: row !important;
}

.wallets-bar-wrapper {
  margin-bottom: 30px;
}

.wallets-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 487px;
  padding: 15px 20px;
  border-radius: 11px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #f9f9f9;
}
@media all and (min-width: 0) and (max-width: 970px) {
  .wallets-bar {
    padding: 5px 10px;
  }
}
.wallets-bar__text {
  font-size: 18px;
  color: rgba(31, 32, 36, 0.6);
}
@media all and (min-width: 0) and (max-width: 970px) {
  .wallets-bar__text {
    font-size: 14px;
  }
}
.wallets-bar__images {
  display: flex;
  align-items: center;
}
.wallets-bar__img:not(:last-child) {
  margin-right: 9px;
}
@media all and (min-width: 0) and (max-width: 970px) {
  .wallets-bar__img:not(:last-child) {
    margin-right: 4px;
    width: 30px;
  }
}
@media all and (min-width: 0) and (max-width: 550px) {
  .wallets-bar__img:not(:last-child) {
    margin-right: 4px;
    width: 35px;
  }
}
.wallets-bar__img--1 {
  background-color: #ffd4a3;
  padding: 5px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/ConnectWallet/ConnectWallet.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,qCAAA;EACA,mBAAA;AACJ;AACI;EAXJ;IAYQ,iBAAA;EAEN;AACF;AAAI;EACI,eAAA;EACA,4BAAA;AAER;AAAQ;EAJJ;IAKQ,eAAA;EAGV;AACF;AAAI;EACI,aAAA;EACA,mBAAA;AAER;AAEQ;EACI,iBAAA;AAAZ;AAEY;EAHJ;IAIQ,iBAAA;IACA,WAAA;EACd;AACF;AACY;EARJ;IASQ,iBAAA;IACA,WAAA;EAEd;AACF;AACQ;EACI,yBAAA;EACA,YAAA;EACA,kBAAA;AACZ","sourcesContent":[".connect-wallet {\n    flex-direction: row !important;\n}\n\n.wallets-bar-wrapper {\n    margin-bottom: 30px;\n}\n\n.wallets-bar {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    max-width: 487px;\n    padding: 15px 20px;\n    border-radius: 11px;\n    border: 1px solid rgba(0, 0, 0, 0.08);\n    background: #f9f9f9;\n\n    @media all and (min-width: 0) and (max-width: 970px) {\n        padding: 5px 10px;\n    }\n\n    &__text {\n        font-size: 18px;\n        color: rgba(31, 32, 36, 0.6);\n\n        @media all and (min-width: 0) and (max-width: 970px) {\n            font-size: 14px;\n        }\n    }\n\n    &__images {\n        display: flex;\n        align-items: center;\n    }\n\n    &__img {\n        &:not(:last-child) {\n            margin-right: 9px;\n\n            @media all and (min-width: 0) and (max-width: 970px) {\n                margin-right: 4px;\n                width: 30px;\n            }\n\n            @media all and (min-width: 0) and (max-width: 550px) {\n                margin-right: 4px;\n                width: 35px;\n            }\n        }\n\n        &--1 {\n            background-color: #ffd4a3;\n            padding: 5px;\n            border-radius: 50%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
