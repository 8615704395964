import React, {useState} from 'react';
import { GetInTouchModal } from '../GetInTouchModal';

import Airlines from '../../assets/img/product-lounge/airlines.png';
import Banks from '../../assets/img/product-lounge/banks.png';
import SocialMedia from '../../assets/img/product-lounge/social-media.png';
import ourB2bPng from '../../assets/img/our-b2b-pic.png';
import ourB2bPng2x from '../../assets/img/our-b2b-pic@x2.png';
import ourB2bWebp from '../../assets/img/our-b2b-pic.webp';
import ourB2bWebp2x from '../../assets/img/our-b2b-pic@x2.webp';
import "./OurCases.scss";

const OurCases = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="our-cases">
      <div className="our-cases__head">
        <h3 className="our-cases__title">
          Our <span>b2b white </span>label use cases
        </h3>
      </div>

      <div className="our-cases__rows">
        <div className="our-cases__row our-cases__row--white product-lounge__row">
          <div className="our-cases__info product-lounge__info">
            <h4 className="our-cases__label our-cases__label--purple">Mobile carriers</h4>

            <p className="our-cases__text">
              Amidst the ever-evolving landscape of mobile communication, both network operators and MVNOs are on a quest for a fresh business model. The era of earning from minutes has dwindled as bundled packages take the lead, while SMS has become a relic of the past with the rise of free messaging services. Among these, mobile carriers stand as key candidates for adopting our revolutionary solution, given their role as providers of smartphones to the masses.
              <br/>
              <br/>
              Today, there exist approximately 2,500 mobile carriers, inclusive of MVNOs and sub-brands, boasting a colossal 5 billion global subscribers. In the European realm alone, 180 network mobile carriers cater to 1.2 billion subscribers. With Paypolitan's groundbreaking payment solution, mobile operators are poised to unlock a new era of substantial revenue, seamlessly transforming their existing subscriber base into bona fide banking clients, all while sidestepping the burdens of becoming a bank themselves.
            </p>

            <button className="our-cases__btn main-btn main-btn--yellow"  onClick={toggleModal}>
            get in touch

              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
              </svg>
            </button>
          </div>
          <div className="our-cases__picture product-lounge__picture">
            <picture>
              <source srcset={`${ourB2bPng} 1x, ${ourB2bPng2x} 2x`} type="image/png" />
              <source srcset={`${ourB2bWebp} 1x, ${ourB2bWebp2x} 2x`} type="image/webp" />
              <img src={ourB2bPng} alt="" />
            </picture>
          </div>
        </div>

        <div className="our-cases__row our-cases__row--airlines">
          <div className="our-cases__info">
            <h4 className="our-cases__label">Airlines</h4>

            <p className="our-cases__text">
            Which airline doesn't offer a labelled credit card nowadays? We have a clear focus on low-cost airlines as they are highly reactive. Focusing on pan-European low-cost airlines like Ryanair, easyJet, Eurowings and several pan-European players. Our white label solution can be labelled with the airlines’ brand and for every dollar or euro spent the user i.e. could earn free airline miles or bonuses.
            </p>

            <button className="our-cases__btn main-btn"  onClick={toggleModal}>
            get in touch

              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6" />
              </svg>
            </button>
          </div>
          <div className="our-cases__picture"> <img src={Airlines} width="460" alt="" /></div>
        </div>

        <div className="our-cases__row our-cases__row--banks">
          <div className="our-cases__info">
            <h4 className="our-cases__label">Banks</h4>

            <p className="our-cases__text">
              Beyond its benefits for digital-first banks, our solution is a game-changer for traditional brick-and-mortar banks seeking a foray into the dynamic world of online banking. However, this isn't just any online banking; it's the future, driven by the convenience of smartphones and the need for the perfect digital ecosystem.
              <br/>
              <br/>
              Paypolitan offers a seamless 'plug & play' solution, complete with versatile platforms that include our feature-rich e-wallet functionality. It's a forward-thinking approach for banks that recognizes the inevitable evolution of financial landscapes. Cryptocurrencies are the next frontier, and banks, sooner or later, will want to diversify their product portfolio with this exciting asset class.
            </p>

            <button className="our-cases__btn main-btn"  onClick={toggleModal}>
            get in touch

              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6" />
              </svg>
            </button>
          </div>
          <div className="our-cases__picture"><img src={Banks} width="320" alt="" /></div>
        </div>

        <div className="our-cases__row our-cases__row--social">
          <div className="our-cases__info">
            <h4 className="our-cases__label">Social media networks</h4>

            <p className="our-cases__text">
              It's no secret that social networks have seamlessly merged into the realm of messaging services, with some of the world's most influential messaging platforms finding a home within social networks like Facebook. While many social networks initially set out to connect individuals on personal or professional levels, their ambitions have grown. Today, they aspire to be comprehensive service providers, analyzing user behavior and social categorization.
              <br/>
              <br/>
              In the grand scheme of things, these social networks are evolving to offer an entire spectrum of services to their users, and it's only natural that they eventually expand into the realm of payments. In fact, one of China's most prominent messaging services has already become the nation's pioneering payment solution, marking a profound shift in how we interact with both our social circles and our finances.
            </p>

            <button className="our-cases__btn main-btn"  onClick={toggleModal}>
            get in touch

              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6" />
              </svg>
            </button>
          </div>
          <div className="our-cases__picture"><img src={SocialMedia} width="292" alt="" /></div>
        </div>
      </div>

      <GetInTouchModal open={isModalOpen} onClose={toggleModal} />
    </div>
  );
}

export default OurCases;