import React, { useEffect } from 'react';
import { FirstScreen } from "../../components/FirstScreen";
import { AddYourWallets } from "../../components/AddYourWallets";
import { DeFi } from "../../components/Defi";
import { AIBased } from "../../components/AIBased";
import { SetUp } from "../../components/SetUp";
import { FreeLunch } from "../../components/FreeLunch";
import { ConnectWallet } from "../../components/ConnectWallet";
import { InstantCredit } from "../../components/InstantCredit";
import { Custodial } from "../../components/Custodial";
import tg from "../../assets/img/social/tg.svg";
import tw from "../../assets/img/social/twitter_old.svg";
import x from "../../assets/img/social/twitter.svg";
import instagram from "../../assets/img/social/instagram.svg";
import linked from "../../assets/img/social/linked.svg";
import medium from "../../assets/img/social/medium.svg";
import facebook from "../../assets/img/social/facebook.svg";
import youtube from "../../assets/img/social/youtube.svg";
import tiktok from "../../assets/img/social/tik_tok.svg";
import reddit from "../../assets/img/social/reddit.svg";
import discord from "../../assets/img/social/discord.svg";
import "./Subscribe.scss";
import { SubscribeForm } from '../../components/SubscribeForm';

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонента
  }, []);

    return (
        <>
            <FirstScreen />
            <AddYourWallets />
            <DeFi />
            <AIBased />
            <SetUp />
            <FreeLunch />

            <div className="subscribe">
                <p className="subscribe__text">Subscribe to our newsletter</p>
                <SubscribeForm/>
            </div>

            <ConnectWallet />
            <InstantCredit />
            <Custodial />

            <div className="rectangle-separator">
                <h3 className="rectangle-separator__title">
                    Join our <span>fast-growing</span>
                    <br />
                    community
                </h3>

                <div className="rectangle-separator__icons">
                    <a className="rectangle-separator__link" target='_blank' href="https://t.me/paypolitan_ann">
                        <img
                            src={tg}
                            className="rectangle-separator__icon"
                            alt="link on telegram"
                        />
                    </a>
                    <a className="rectangle-separator__link" target='_blank' href="https://twitter.com/paypolitan">
                        <img src={tw} className="rectangle-separator__icon" alt="link on twitter" />
                    </a>
                    <a className="rectangle-separator__link" target='_blank' href="https://twitter.com/paypolitan">
                        <img src={x} className="rectangle-separator__icon" alt="link on x" />
                    </a>
                    <a className="rectangle-separator__link" href="#/">
                        <img
                            src={instagram}
                            className="rectangle-separator__icon"
                            alt="link on instagram"
                        />
                    </a>
                    <a className="rectangle-separator__link" target='_blank' href="https://paypolitan-official.medium.com/">
                        <img
                            src={medium}
                            className="rectangle-separator__icon"
                            alt="link on medium"
                        />
                    </a>
                    <a className="rectangle-separator__link" target='_blank' href="https://www.linkedin.com/company/paypolitan/mycompany/?viewAsMember=true">
                        <img
                            src={linked}
                            className="rectangle-separator__icon"
                            alt="link on linkedin"
                        />
                    </a>
                    <a className="rectangle-separator__link" href="#/">
                        <img
                            src={facebook}
                            className="rectangle-separator__icon"
                            alt="link on facebook"
                        />
                    </a>
                    <a className="rectangle-separator__link" target='_blank' href="https://www.youtube.com/channel/UCF8pInyMTCMHki5P5HZVPRQ">
                        <img
                            src={youtube}
                            className="rectangle-separator__icon"
                            alt="link on youtube"
                        />
                    </a>
                    <a className="rectangle-separator__link" href="#/">
                        <img
                            src={tiktok}
                            className="rectangle-separator__icon"
                            alt="link on tiktok"
                        />
                    </a>
                    <a className="rectangle-separator__link" href="#/">
                        <img
                            src={discord}
                            className="rectangle-separator__icon"
                            alt="link on discord"
                        />
                    </a>
                    <a className="rectangle-separator__link" href="#/">
                        <img
                            src={reddit}
                            className="rectangle-separator__icon"
                            alt="link on reddit"
                        />
                    </a>
                </div>
            </div>
        </>
    );
};
