import React, { useEffect } from "react";
import "./AIBased.scss";
import phoneFront from "../../assets/newImg/phone-front.png";
import { GoogleApple } from "../GoogleApple";

export const AIBased = () => {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(async (entry) => {
                const phoneElem = entry.target.querySelector(".defi-phone");
                const circleElem = entry.target.querySelector(".defi-circle");
                const iconsElem = entry.target.querySelector(".defi-icons");
                if (entry.isIntersecting) {
                    circleElem?.classList.add("defi-circle-animation");
                    phoneElem?.classList.add("defi-phone-animation");
                    iconsElem?.classList.add("defi-icons-animation");
                    return;
                }

                circleElem?.classList.remove("defi-circle-animation");
                phoneElem?.classList.remove("defi-phone-animation");
                iconsElem?.classList.remove("defi-icons-animation");
            });
        });

        observer.observe(document.querySelector(".defi-img"));
    }, []);

    return (
        <section className="homePageBlock">
            <div className="defi__text defi__text--mr0">
                <div className="addYourWallets-text">
                    <h2 className="addYourWallets-text-title">
                        AI based analysis of&nbsp;your spendings:
                        <span className="addYourWallets-text-title-border"> save</span> <span>money</span> 
                    </h2>
                    <div>
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb28">
                        Don't you get sometimes lost with all your spendings? Electricity, insurances, gym subscription… no worries. Dive into a world of financial clarity with Paypolitan's cutting-edge AI analysis – absolutely free! We're not just about payments; we're your money-saving ally. And that's not all – managing your diverse assets across bank accounts and wallets has never been easier, and yes, it's still free!
                        </p>
                    </div>
                    <div className="defi-download defi-download--desktop">
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                            Get the app:
                        </p>
                        <GoogleApple marginRight="0px" />
                    </div>
                </div>
            </div>
            <div className="defi-img ai-img homePageBlock-img">
                <img className="defi-circle" src={phoneFront} alt="img" />
            </div>

            <div className="defi-download defi-download--mobile">
                <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                    Get the app:
                </p>
                <GoogleApple marginRight="0px" />
            </div>
        </section>
    );
};
