import React, { useMemo, useState, useRef } from 'react';
import { Button } from "../../components/AddYourWallets";
import arrowRightBlack from "../../assets/img/arrow-right-black.svg";
import { TextField } from "@mui/material";
import emailValidator from "email-validator";
import Snackbar from '@mui/material/Snackbar';
import { FORMSPARK_SUBSCRIBE_ID } from "../../constants/config"
import "./SubscribeForm.scss";

const FORMSPARK_ACTION_URL = "https://submit-form.com";

const FIELD_REQUIRED_ERROR = "FIELD_REQUIRED";
const EMAIL_REQUIRED_ERROR = "EMAIL_REQUIRED";

const FIELD_REQURED_MESSAGE = "Field required";
const EMAIL_REQUIRED_MESSAGE = "Valid e-mail required";

const AUTO_HIDE_DURATION = 5000; // 5 sec
const ANCHOR_ORIGIN = { vertical: "top", horizontal: "center" }

export const SubscribeForm = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(null);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [failedSnackbarOpen, setFailedSnackbarOpen] = useState(false);
    const emailInputRef = useRef(null);

    const emailHelperText = useMemo(() => {
        return createEmailHelperText(emailError)
    }, [emailError]);


    const handleEmailInputChange = (ev) => {
        const nextEmail = ev.currentTarget.value;
        setEmail(nextEmail)
    }

    const handleEmailInputBlur = (ev) => {
        const nextEmail = ev.currentTarget.value;
        const nextEmailError = validateEmailInput(nextEmail);
        setEmailError(nextEmailError)
    }

    const handleSubmit = () => {
        const nextEmailError = validateEmailInput(email);

        if (nextEmailError !== null) {
            setEmailError(nextEmailError);
            if (emailInputRef.current) {
                emailInputRef.current.focus();
            }
            return;
        }

        submitSubscribeForm({ email })
            .then(() => {
                setSuccessSnackbarOpen(true);
            })
            .catch((error) => {
                console.error(error);
                setFailedSnackbarOpen(true);
            });
    };

    const handleFormSubmit = (ev) => {
        ev.preventDefault();
        handleSubmit();
    }

    const handleSubmitBtnClick = () => {
        handleSubmit();
    }

    const handleSuccessSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
    }

    const handleFaieldSnackbarClose = () => {
        setFailedSnackbarOpen(false);
    }

    return <form className="subscribe-form" onSubmit={handleFormSubmit}>
        <Snackbar
            message="Subscription completed successfully"
            anchorOrigin={ANCHOR_ORIGIN}
            open={successSnackbarOpen}
            autoHideDuration={AUTO_HIDE_DURATION}
            onClose={handleSuccessSnackbarClose}
        />
        <Snackbar
            message="Failed to submit form"
            anchorOrigin={ANCHOR_ORIGIN}
            open={failedSnackbarOpen}
            autoHideDuration={AUTO_HIDE_DURATION}
            onClose={handleFaieldSnackbarClose} />
        <div className="textfieldw">
            <TextField
                sx={{
                    "& label": { paddingLeft: (theme) => theme.spacing(2) },
                    "& input": { paddingLeft: (theme) => theme.spacing(3.5) },
                    "& fieldset": {
                        paddingLeft: (theme) => theme.spacing(2.5),
                        borderRadius: "30px",
                    },
                }}
                InputProps={{ sx: { borderRadius: 12 } }}
                backgroundColor="secondary"
                placeholder="Enter your e-mail"
                fullWidth
                label="Enter your e-mail"
                variant="filled"
                className="subscribe-form__input"
                value={email}
                error={emailError !== null}
                helperText={emailHelperText}
                onChange={handleEmailInputChange}
                onBlur={handleEmailInputBlur}
                inputRef={emailInputRef}
            />
        </div>
        <Button text="Submit" icon={arrowRightBlack} color="#FFE602" textColor="black" onClick={handleSubmitBtnClick} />
    </form>
};

async function submitSubscribeForm(formData) {
    const response = await fetch(FORMSPARK_ACTION_URL + "/" + FORMSPARK_SUBSCRIBE_ID, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(formData),
    });

    if (!response.ok) {
        throw new Error(`failed to subscribe: ${response.status} ${response.statusText}`);
    }
}

function createEmailHelperText(emailError) {
    switch (emailError) {
        case FIELD_REQUIRED_ERROR: {
            return FIELD_REQURED_MESSAGE;
        }
        case EMAIL_REQUIRED_ERROR: {
            return EMAIL_REQUIRED_MESSAGE;
        }
        default: {
            return undefined;
        }
    }
}

function validateEmailInput(value) {
    if (value.length === 0) {
        return FIELD_REQUIRED_ERROR;
    }

    if (!emailValidator.validate(value)) {
        return EMAIL_REQUIRED_ERROR;
    }

    return null;
}

