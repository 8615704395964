import React, { useEffect } from 'react';
import './Cookies.scss';
const Cookies = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонента
  }, []);

return (
  
<div className="cookies">
  <p>This Cookie Policy describes the different types of cookies that may be applied by Paypolitan OÜ on its website
    (“Site”). We may change this Cookie Policy at any time in order to reflect, for example, changes to the cookies we
    use or for other operational, legal or regulatory reasons.</p>
  <p>If you have questions regarding this Cookie Policy, please contact our Data Protection Officer (DPO) at
    contact@paypolitan.com or write to us at Paypolitan OÜ, Narva mnt. 5, 10117, Tallinn, Estonia</p>
  <h2>What are cookies?</h2>
  <p>Cookies are a standard feature of websites that allow us to store small amounts of data on your computer about your
    visit to a website. Cookies help the website owner learn which areas of the website are useful and which areas need
    improvement. Cookies also improve your experience by, for example, remembering your preferences, such as your
    language preference or login information.</p>
  <p>Some cookies are required for technical reasons in order for a website to operate, also known as "Strictly
    Necessary" cookies. Some cookies allow a website to count visits and traffic sources so the site owner can measure
    and improve the performance of the site, also known as “Analytics” cookies. Some cookies enable a website owner to
    serve targeted advertisements, also known as “Targeting” cookies.</p>
  <h2>What cookies do we use?</h2>
  <p>Our Site uses both first party cookies (which are set by us) and third party cookies (which are set by a server
    located outside the domain of our Site) for several reasons. The specific types of first and third party cookies
    served through our Site and the purposes they perform are described in the tables below.</p>
  <p>Strictly Necessary Cookies. These are cookies which make our Site function when you take certain actions, whether
    you are logging in, managing email preferences, or filling out forms. Since they are essential for our Site to
    function, we cannot turn them off. You can set your browser to block or alert you about these cookies, but some
    parts of the Site will then not work. These cookies do not store any personal data.</p>
  <table>
    <thead>
      <tr>
        <th>Cookie Name</th>
        <th>Purpose</th>
        <th>First/Third Party</th>
        <th>Expiration</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Cloudflare (_cfduid)</td>
        <td>Cloudflare provides content-delivery-network services, DDoS mitigation, Internet security, and distributed
          domain-name-server services. This cookie collects and anonymizes end user IP addresses using a one-way hash of
          certain values so they cannot be personally identified.</td>
        <td>Third</td>
        <td>Session</td>
      </tr>
      <tr>
        <td>Connect_Sid</td>
        <td>This cookie is used to identify or create a valid user session.</td>
        <td>First</td>
        <td>Session</td>
      </tr>
    </tbody>
  </table>
  <p>Analytics Cookies. These cookies allow us to count visits and traffic sources to our Site so we can measure and
    improve the performance of our Site. The information these cookies collect is aggregated and anonymous. If you do
    not allow these cookies, we will not be able to serve you the most relevant content and give you the best
    experience.</p>
  <table>
    <thead>
      <tr>
        <th>Cookie Name</th>
        <th>Purpose</th>
        <th>First/Third Party</th>
        <th>Expiration</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
            target="_blank" rel="noreferrer">Google Analytics</a></td>
        <td>These cookies are used to calculate visitor, session and campaign data for the Sites' analytics reports
          through aggregated, anonymous user data.</td>
        <td>Third</td>
        <td>6 months</td>
      </tr>
    </tbody>
  </table>
  <p>Targeting Cookies. These cookies may be set through our Site by our marketing partners. They may be used by us or
    our marketing partners to understand which parts of our Sites are of interest to you and/or show you relevant
    advertising on our Site or on other Sites. They do not store personal data, but are based on uniquely identifying
    your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.
  </p>
  <table>
    <thead>
      <tr>
        <th>Cookie Name</th>
        <th>Purpose</th>
        <th>First/Third Party</th>
        <th>Expiration</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><a href="https://ru.linkedin.com/legal/ads-policy?" target="_blank" rel="noreferrer">LinkedIn
            Advertising</a></td>
        <td>These cookies are used to provide insight into user behavior within LinkedIn that is responsive to our
          marketing efforts, in order to deliver marketing content to you such as emails, white papers, webinars, etc.
        </td>
        <td>Third</td>
        <td>6 months</td>
      </tr>
      <tr>
        <td><a href="https://support.google.com/adspolicy/answer/6008942?hl=en" target="_blank" rel="noreferrer">Google
            AdWords</a></td>
        <td>These cookies are used to provide insight into behavioral advertising and analytics on the Google AdWords
          platform to enable us to serve you with product-based advertisements on external websites.</td>
        <td>Third</td>
        <td>540 days</td>
      </tr>
      <tr>
        <td><a href="https://www.facebook.com/policies/ads" target="_blank" rel="noreferrer">Facebook Advertising</a>
        </td>
        <td>These cookies allow us to confirm when you have completed a form or action related to Facebook. BitPay
          Paypolitan may send you related communications via email or sponsored newsfeed updates.</td>
        <td>Third</td>
        <td>6 months</td>
      </tr>
      <tr>
        <td><a href="https://developers.marketo.com/javascript-api/lead-tracking/" target="_blank"
            rel="noreferrer">Marketo Munchkin (_mkto_trk)</a></td>
        <td>This cookie allows us to track visitor behavior on sites on which the cookie is installed and to link a
          visitor to the recipient of an email marketing campaign, to measure campaign effectiveness. Tracking is
          performed anonymously until a user identifies himself by submitting a form.</td>
        <td>Third</td>
        <td>2 years</td>
      </tr>
    </tbody>
  </table>
  <h2>Web beacons</h2>
  <p>Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies
    from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics
    files that contain a unique identifier that enable us to recognize when someone has visited our Site or opened an
    email that we sent. This allows us, for example, to monitor the traffic patterns of users from one page within our
    Site to another, to deliver or communicate with cookies, to understand whether a user came to our Site from an
    online advertisement displayed on a third-party website, to improve site performance, and to measure the success of
    email marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so
    declining cookies will impair their functioning.</p>
</div>
);
}

export default Cookies;