import React from "react";
import google from "../../assets/newImg/google.svg";
import apple from "../../assets/newImg/apple.svg";
import "./GoogleApple.scss";

export const GoogleApple = ({ width = "", height = "", marginRight = "15px" }) => {
    return (
        <div className="mobile-app-dowloads">
            <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.paypolitan.app"
                style={{ marginRight }}
            >
                <img
                    style={{ width, height }}
                    src={google}
                    alt="download from google play"
                    className="mobile-app-dowloads__google"
                />
            </a>

            <a
                target="_blank"
                rel="noreferrer"
                href="https://apps.apple.com/us/app/paypolitan/id1562351292"
                style={{ marginRight }}
            >
                <img
                    style={{ width, height }}
                    src={apple}
                    alt="download from apple store"
                    className="mobile-app-dowloads__apple"
                />
            </a>
        </div>
    );
};
