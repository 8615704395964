import React, {useState, useEffect} from 'react';
import Evgeni from '../../assets/img/team-section/Evgeni.png';
import Andrei from '../../assets/img/team-section/Andrei Filip.png';
import Anya from '../../assets/img/team-section/Anya.jpg';
import Chris from '../../assets/img/team-section/Chris.JPG';
import Gleb from '../../assets/img/team-section/Gleb Beketov.jpeg';
import Igor from '../../assets/img/team-section/Igor Reshniov.png';
import Kenan from '../../assets/img/team-section/Kenny.JPG';
import Loay from '../../assets/img/team-section/Loay Alaa.jpg';
import Maria from '../../assets/img/team-section/Maria Krukovich.jpg';
import Olga from '../../assets/img/team-section/Olga.JPG';
import Yacub from '../../assets/img/team-section/Yakub Garcia.jpg';
import Maxim from '../../assets/img/team-section/Maxim.jpeg'

import './Team.scss';

const Team = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Массив с данными о членах команды
  const teamMembers = [
    // { name: 'Evgeni Sobolei', position: 'Paypolitan Guru', imgSrc: Evgeni },
    { name: 'Andrei Filip', position: 'Mobile dev', imgSrc: Andrei },
    { name: 'Loay Alaa', position: 'Mobile dev', imgSrc: Loay },
    { name: 'Igor Reshniov', position: 'Backend dev', imgSrc: Igor },
    { name: 'Gleb Beketov', position: 'Marketing', imgSrc: Gleb },
    { name: 'Kenan Koldzic', position: 'Growth manager', imgSrc: Kenan },
    { name: 'Christian Pilz', position: 'Growth manager', imgSrc: Chris },
    { name: 'Maria Krukovich', position: 'Social media', imgSrc: Maria },
    { name: 'Anya', position: 'Content manager', imgSrc: Anya },
    { name: 'Olga Razryvina', position: 'Social media pm', imgSrc: Olga },
    { name: 'Yakub Garcia', position: 'Web dev', imgSrc: Yacub },
    { name: 'Maxim Zolotarov', position: 'QA engineer', imgSrc: Maxim}
  ];

  // Состояние для хранения текущего случайно выбранного члена команды
  const [randomMember, setRandomMember] = useState(null);

  // Функция для выбора случайного члена команды и обновления состояния
  const selectRandomMember = () => {
    const randomIndex = Math.floor(Math.random() * teamMembers.length);
    setRandomMember(teamMembers[randomIndex]);
  };

  useEffect(() => {
    selectRandomMember();
  }, []); // вызывается только при монтировании компонента

  return (
    <div className="team">
      <div className="team__head">
        <h3 className="team__title">

          <span>Team</span>
        </h3>
      </div>

      <div className="team__label">
        <div className="team__label-text">Meet the team</div>
        <div className="team__label-line">
          <svg width="1005" height="1" viewBox="0 0 1005 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="4.37114e-08" y1="0.5" x2="1005" y2="0.500088" stroke="url(#paint0_linear_675_2811)" stroke-opacity="0.4"/>
            <defs>
            <linearGradient id="paint0_linear_675_2811" x1="502.5" y1="1.00004" x2="1005" y2="1.00009" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BD1D83"/>
            <stop offset="1" stop-color="#BD1D83" stop-opacity="0"/>
            </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div className="team__content">
        <div className="team__info">
          <div className="team__info-text">
            Paypolitan is growing rapidly and in the meet the team section we will feature all our team members.<br/> <span>Because Paypolitan is first of all a great team!</span>
          </div>

          <a href='#careers' className="team__info-btn main-btn main-btn--yellow">
            We are hiring

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
            </svg>
          </a>
        </div>
        {randomMember && (
        <div className="team__picture">
          <div className="team__picture-inner">
            <img className="team__picture-img" width="118" height="118" src={randomMember.imgSrc} alt={randomMember.name} />

            <div className="team__picture-texts">
              <p className="team__picture-name">{randomMember.name}</p>
              <p className="team__picture-position">{randomMember.position}</p>
            </div>
          </div>
        </div>
      )}
      </div>

      {isModalOpen && (
        <div className="modal show" id="modalGetIntouch">
          {/* Modal content */}
          <div className="modal-content modal-content--middle">
            {/* Modal header */}
            <div className="modal__header">
              <h3 className="modal__title modal__title--xl">We are hiring</h3>
              <button type='button' className="close-modal-btn" onClick={toggleModal}>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="22" cy="22" r="22" fill="#E4E4E4"/>
                  <line x1="15.8341" y1="14.8556" x2="29.0174" y2="28.039" stroke="black" strokeWidth="2"/>
                  <line x1="15.4745" y1="28.039" x2="28.6579" y2="14.8556" stroke="black" strokeWidth="2"/>
                </svg>
              </button>
            </div>
            <div className="modal__inner">
              <form action='#' className="modal__form modal__form--row">

                <div className="modal__form-inputs">
                  <input className="modal__form-input" type="email" placeholder='E-mail' />
                </div>

                <button type='submit' className="modal__form-btn main-btn main-btn--yellow">
                   Submit

                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
                    </svg>
                </button>
              </form>
            </div>

            <button type='button' className="close-modal-btn close-modal-btn--mob" onClick={toggleModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Team;