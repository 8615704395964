import React, { useState } from "react";
import { FORMSPARK_CONTACT_ID } from "../../constants/config";
import PropTypes from "prop-types";

const FORMSPARK_ACTION_URL = "https://submit-form.com";

export const ContactModalForm = ({ onSuccess, onFailure }) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const handleEmailInputChange = (ev) => {
        setEmail(ev.currentTarget.value);
    }

    const handlePhoneInputChange = (ev) => {
        setPhone(ev.currentTarget.value)
    }

    const handleMessageInputChange = (ev) => {
        setMessage(ev.currentTarget.value);
    }

    const handleSubmit = (ev) => {
        ev.preventDefault();

        setLoading(true);
        submitContactModalForm({ email, phone, message })
            .then(() => {
                setLoading(false)
                onSuccess()
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                onFailure();
            });
    }


    return <form className="modal__form" method="POST" onSubmit={handleSubmit}>

        <div className="modal__form-inputs modal__form-inputs--grid">
            <input className="modal__form-input" type="email" placeholder='E-mail' required value={email} onChange={handleEmailInputChange} />
            <input className="modal__form-input" type="tel" placeholder='Phone number' value={phone} onChange={handlePhoneInputChange} />
            <textarea className="modal__form-input modal__form-input--textarea" type="email" placeholder='Your Message' value={message} onChange={handleMessageInputChange}></textarea>
        </div>

        <button type='submit' className="modal__form-btn main-btn main-btn--yellow" disabled={loading}>
            Submit

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
            </svg>
        </button>
    </form>
}

ContactModalForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
}

async function submitContactModalForm(formData) {
    const response = await fetch(FORMSPARK_ACTION_URL + "/" + FORMSPARK_CONTACT_ID, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(formData),
    });

    if (!response.ok) {
        throw new Error(`failed to subscribe: ${response.status} ${response.statusText}`);
    }
}