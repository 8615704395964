import React, { useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import { FORMSPARK_SUBSCRIBE_ID } from "../../constants/config";
import "./SIgnUpNewsletter.scss";

const FORMSPARK_ACTION_URL = "https://submit-form.com";

const AUTO_HIDE_DURATION = 5000; // 5 sec
const ANCHOR_ORIGIN = { vertical: "top", horizontal: "center" }

const SIgnUpNewsletter = () => {
  const [email, setEmail] = useState("");
  const [telegram, setTelegram] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [failureSnackbarOpen, setFailureSnackbarOpen] = useState(false);

  const handleEmailChange = (ev) => {
    setEmail(ev.currentTarget.value);
  };

  const handleTelegramChange = (ev) => {
    setTelegram(ev.currentTarget.value);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    submitSignupNewsletterForm({ email, telegram })
      .then(() => {
        setSuccessSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        setFailureSnackbarOpen(true);
      });
  };

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  const handleFailureSnackbarClose = () => {
    setFailureSnackbarOpen(false);
  };

  return (
    <form className="signup-newsletter" method="POST" onSubmit={handleSubmit}>
      <Snackbar
        message="Form submitted successfully"
        anchorOrigin={ANCHOR_ORIGIN}
        open={successSnackbarOpen}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={handleSuccessSnackbarClose}
      />
      <Snackbar
        message="Failed to submit form"
        anchorOrigin={ANCHOR_ORIGIN}
        open={failureSnackbarOpen}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={handleFailureSnackbarClose}
      />
      <div className="signup-newsletter__inner">
        <h3 className="signup-newsletter__title">
          Sign-up for our newsletter
          and stay tuned:
        </h3>

        <div className="signup-newsletter__row">
          <input className="signup-newsletter__input" type="email" placeholder="E-mail" required value={email} onChange={handleEmailChange} />
          <input className="signup-newsletter__input" type="text" placeholder="Telegram" value={telegram} onChange={handleTelegramChange} />

          <button type="submit" className="signup-newsletter__btn main-btn main-btn--yellow">
            Submit

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
            </svg>
          </button>
        </div>
      </div>
    </form>
  );
}

async function submitSignupNewsletterForm(formData) {
  const response = await fetch(FORMSPARK_ACTION_URL + "/" + FORMSPARK_SUBSCRIBE_ID, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    throw new Error(`failed to submit: ${response.status} ${response.statusText}`);
  }
}

export default SIgnUpNewsletter;