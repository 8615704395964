import React, {useState} from 'react';
import { ContactModal } from '../ContactModal';
import Icon1 from '../../assets/img/careers/1.svg';
import Icon2 from '../../assets/img/careers/2.svg';
import Icon3 from '../../assets/img/careers/3.svg';
import './Careers.scss';
import useHashScroll from '../../hooks/useHashScroll';

const Careers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const careersRef = useHashScroll('careers')

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
      setShowMore(!showMore);
  };
  
  return (
    <div className="careers" id='careers' ref={careersRef}>
      <div className="careers__head">
        <h3 className="careers__title">

          <span>Careers</span>
        </h3>

        <p className="careers__text">
        We are a dynamic and fast growing team. Check out our job offers! You didn’t find any match? Send us your application spontaniously.

        </p>
      </div>
      
      <div className="careers__content">
        <div className="careers__manager">
          <h4 className="careers__label">Project manager</h4>

          <div className="careers__rows">
          <div className="careers__row">
              <div className="careers__icon">
              <img src={Icon3} alt="icon" />
              </div>
              <p className="careers__descr">Location: remote</p>
            </div>

            <div className="careers__row">
              <div className="careers__icon">
              <img src={Icon2} alt="icon" />
              </div>
              <p className="careers__descr">Salary: upon request</p>
            </div>

            <div className="careers__row">
              <div className="careers__icon">
                <img src={Icon1} alt="icon" />
              </div>
              <p className="careers__descr">
                ID: 2111-454
              </p>
            </div>



          </div>
        </div>

        <div className="careers__info">
        <p className={`careers__info-descr ${showMore ? 'view-more' : ''}`}>
              We are looking for a Project Manager with experience in Business Analysis and a start-up mindset for a DeFi project in a European company.



           What we expect from you:<br/>
            <ul>
              <li>
              - Experience in Startup or with topics such as Blockchain / DLT / Tokenization / Cryptocurrency or infrastructure connections (understanding of crypto-business)
              </li>
              <li>- 3+ years of commercial experience as a Project Manager at a tech company or start-up</li>
              <li>- 3+ years of commercial experience as a Business Analyst for crypto-business projects</li>
              <li>- Experience in remote management of the team</li>
              <li>- Strong leadership, excellent communication, and troubleshooting skills</li>
              <li>- Understanding of the BI solution (preference Power BI)</li>
              <li>- Experience working with requirement management & documentation tools (mostly Jira and Confluence)</li>
              <li>- Fluency in the creation of documentation (business rules, as-is/to-be flows, requirements, data configuration, use cases, functional specs)</li>
              <li>- Ability to work at both strategic and operational levels with an ability to contextualize the fine details within the bigger picture</li>
              <li>- Excellent skills in building trust and relationships with the teams to enable change and deliver value</li>
              <li>- Detail-oriented, analytical, inquisitive, and solution-oriented personality with a can-do attitude</li>
              <li>- A skilled communicator and negotiator</li>
              <li>- A strong alignment with our principles</li>
              <li>- English – Upper-Intermediate</li>
            </ul>

            <br/>
          Project:<br/>
            Fintech application that combines blockchain, traditional banking and DeFi, payments, DEX, borrowing, and investments. Application in the stores with a growing base of users.
            Technical stack of the project: Node.js, React Native, React.js, AWS, Docker, Solidity
            
            <br/>
            <br/>

           Responsibilities:<br/>
            Managing IT projects and a team of technical specialists (Back End, Front End, Mobile Developers, QA Engineers);
            Collaboration with the CTO on the team members’ assignments and their efficiency and development
            Daily planning, execution, and control of the teamwork
            Create documentation (business rules, as-is/to-be flows, drawing up requirements, data configuration, use cases, user stories, functional specs).
            
            <br/>
            <br/>

          The main task of the position: <br/>
            We’re growing our fully distributed, global team. As a Business Analyst at Status, you’d be responsible for enabling & driving change. Including mapping existing processes & workflows, helping design improved ones; as well as developing an understanding of our strategic direction, challenges, and project goals.
            Analysis of other DeFi projects on the market and managing IT project and a team of technical specialists.
            
            <br/>
            <br/>
          Company offer:<br/>
            <ul>
              <li>- B2B contract + NDA</li>
              <li>- Payment in cryptocurrency (USDT)</li>
              <li>- Full-Time remote work</li>
              <li>- Working schedule: 9 a.m.-6 p.m. (GMT+3)</li>
              <li>- Team management by CTO; Agile</li>
              <li> - Interesting tasks, independence in implementing creative solutions, working in a fast-growing field like fintech and blockchain.</li>
            </ul>
            
            <br/>
           Interview stages:<br/>
            <ul>
              <li>1. Technical interview with CTO</li>
              <li>2. Final interview with CEO</li>
            </ul>

            <br/>
            No test task.
            <br/>
            You can also contact our recruiter directly:<br/>
            Yellow Recruiting <br/>
            <a href='https://yellowrecruiting.com/' target='_blank'>https://yellowrecruiting.com/</a> <br/>
            <a href='mailto:yellow.askme(at)gmail.com' target='_blank'>yellow.askme(at)gmail.com</a> <br/>

            HR Anna Vorobchuk
          </p>

          <button type='button' className="members__slide-btn" onClick={toggleShowMore}>
          {showMore ? 'Hide back' : 'See more'}
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#bd1d83" strokeWidth="1.6" />
            </svg>
          </button>

          <button type='button' className="careers__btn main-btn" onClick={toggleModal}>
            SEND RESUME
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" strokeWidth="1.6" />
            </svg>
          </button>

        </div>
      </div>

      <div className="careers__content">
        <div className="careers__manager">
          <h4 className="careers__label">PR manager</h4>

          <div className="careers__rows">
          <div className="careers__row">
              <div className="careers__icon">
              <img src={Icon3} alt="icon" />
              </div>
              <p className="careers__descr">Location: remote</p>
            </div>

            <div className="careers__row">
              <div className="careers__icon">
              <img src={Icon2} alt="icon" />
              </div>
              <p className="careers__descr">Salary: upon request</p>
            </div>



          </div>
        </div>
        
        <div className="careers__info">
        <div className="careers__row">
          <div className="careers__descr">
             Date:
            </div>
            <p className="careers__descr">
              Early 2024
            </p>
          </div>

          <div className="careers__row">
            <p className="careers__descr">
              Coming up soon, this position will be published <span className="careers__descr-hiring">asap. <span className="careers__descr-hiring-text">soon</span></span>
            </p>
          </div>
        </div>
      </div>

      <ContactModal title="SEND FORM" open={isModalOpen} onClose={toggleModal}/>
    </div>
  );
}

export default Careers;