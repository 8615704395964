import React, { useEffect, useState } from "react";
import { GetInTouchModal } from "../GetInTouchModal";
import "./FreeLunch.scss";
import FreeLunchImg from "../../assets/newImg/free-lunch.png";

import arrowRight from "../../assets/newImg/arrow-right.svg";

export const Button = ({ text, icon, alt }) => {
    return (
        <button className="main-btn" type="button">
            <span className="main-btn__text">{text}</span>
            <img width="12px" height="12px" src={icon} alt={alt}></img>
        </button>
    );
};

export const FreeLunch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(async (entry) => {
                const phoneElem = entry.target.querySelector(".defi-phone");
                const circleElem = entry.target.querySelector(".defi-circle");
                const iconsElem = entry.target.querySelector(".defi-icons");
                if (entry.isIntersecting) {
                    circleElem?.classList.add("defi-circle-animation");
                    phoneElem?.classList.add("defi-phone-animation");
                    iconsElem?.classList.add("defi-icons-animation");
                    return;
                }

                circleElem?.classList.remove("defi-circle-animation");
                phoneElem?.classList.remove("defi-phone-animation");
                iconsElem?.classList.remove("defi-icons-animation");
            });
        });

        observer.observe(document.querySelector(".defi-img"));
    }, []);

    return (
        <section className="homePageBlock">
            <div className="defi__text defi__text--mr0">
                <div className="addYourWallets-text">
                    <h2 className="addYourWallets-text-title">
                        Wanna&nbsp;<span data-label="New">have</span> <br/>a&nbsp;free lunch?
                        <span className="addYourWallets-text-title-border">Invite</span> <span>your friends & earn</span>
                    </h2>
                    <div>
                        <p className="addYourWallets-text-info addYourWallets-text-info">
                        Enjoying Paypolitan? Why don't you simply invite your friends and family then? You can invite them by a simply click when you open the app. We take care of the rest. Plus, as a token of our appreciation, you'll receive a bonus every time you introduce someone new to Paypolitan. Your bonus? It's credited directly to your account, instantly!*
                        </p>
                    </div>
                    <div className="addYourWallets-text-attention addYourWallets-text-attention--desktop">
                    <button type="button" className="main-btn" onClick={toggleModal}>
            Invite & earn

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" strokeWidth="1.6" />
            </svg>
          </button>
                    </div>
                </div>
            </div>
            <div className="defi-img free-lunch-img homePageBlock-img">
                <img className="defi-circle" src={FreeLunchImg} alt="img" />
            </div>

            <div className="addYourWallets-text-attention addYourWallets-text-attention--mobile">
            <button type="button" className="main-btn" onClick={toggleModal}>
            Invite & earn

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" strokeWidth="1.6" />
            </svg>
          </button>
            </div>

          <GetInTouchModal open={isModalOpen} onClose={toggleModal}/>
        </section>
    );
};
