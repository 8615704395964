import React, { useEffect } from 'react';
import './NotFoundPage.scss';

const NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонента
  }, []);

  return (
    <div className="not-found">
      <div className="not-found__sum">404</div>
      <h1 className="not-found__title">Page no found</h1>

      <a className="not-found__btn main-btn main-btn--center" href="/">
        Go to home page
      
       <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6"/>
      </svg>
    </a>
    </div>
  );
}

export default NotFoundPage;