import React, { useState, useEffect } from 'react';
import "./Partners.scss";

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/img/banks/', false, /\.(png|jpe?g|svg|webp)$/));

const Partners = () => {
  const [visibleImages, setVisibleImages] = useState([]);
  const [loadIndex, setLoadIndex] = useState(0);
  const [loadedAll, setLoadedAll] = useState(false);

  useEffect(() => {
    loadImages();
  }, []);

  const loadImages = () => {
    const screenWidth = window.innerWidth;
    let numVisibleImages;
    if (screenWidth < 768) { // Mobile
      numVisibleImages = 9;
    } else if (screenWidth < 1024) { // Tablet
      numVisibleImages = 12;
    } else { // Desktop
      numVisibleImages = 15;
    }

    const nextImages = images.slice(loadIndex, loadIndex + numVisibleImages);
    setVisibleImages([...visibleImages, ...nextImages]);

    // Если загружены все изображения, устанавливаем loadedAll в true
    if (loadIndex + numVisibleImages >= images.length) {
      setLoadedAll(true);
    }

    setLoadIndex(loadIndex + numVisibleImages);
  };

  const loadAllImages = () => {
    setVisibleImages(images);
    setLoadedAll(true);
  };

  return (
    <div className="partners">
      <div className="partners__head">
        <h3 className='partners__title'>Partners</h3>
      </div>

      <p className='partners__text'>Paypolitan has already developed partnerships with powerful and renowned companies:</p>

      <ul className="partners__list">
        {visibleImages.map((image, index) => {
          const imageName = image.split('/').pop().split('.')[0]; // Получаем имя файла без расширения
          return (
            <li key={index} className="partners__item">
              <img src={image} alt={imageName} />
            </li>
          );
        })}
      </ul>

      {!loadedAll && (
        <button type='button' className="partners__more main-btn" onClick={loadAllImages}>
          More
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" strokeWidth="1.6"></path>
          </svg>
        </button>
      )}
    </div>
  );
}

export default Partners;
