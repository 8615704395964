import React, {useState} from 'react';
import instantCredit from "../../assets/newImg/instant-credit2.png";
import arrowRight from "../../assets/newImg/arrow-right.svg";
import { Button } from "../AddYourWallets";
import { GetInTouchModal } from '../GetInTouchModal';
import "./InstantCredit.scss";

export const InstantCredit = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

    return (
        <section className="homePageBlock instant-credit">
            <div className="defi__text defi__text--mr0">
                <div>
                    <h2 className="addYourWallets-text-title">
                        <span className="addYourWallets-text-title-border">Instant </span>credit
                    </h2>
                    <p className="addYourWallets-text-info">
                    Powered by our cutting-edge technology partnerships, we've introduced an exciting new feature: Instant Peer-to-Peer Loans, available in both cryptocurrency and fiat. Your unique credit rating can unlock access to enticing interest rates that last up to 180 days. Welcome to the era of decentralized banking, where financial empowerment is at your fingertips.
                    </p>
                    <div className="addYourWallets-text-attention addYourWallets-text-attention--desktop"  onClick={toggleModal}>
                        <Button text="get in touch" icon={arrowRight} alt="icon-arrow-right" />
                    </div>
                </div>
            </div>
            <div className="defi-img defi-img--not-bg homePageBlock-img ">
                <img className="defi-circle" src={instantCredit} alt="img" />
            </div>

            <div className="addYourWallets-text-attention addYourWallets-text-attention--mobile">
                <Button text="get in touch" icon={arrowRight} alt="icon-arrow-right" />
            </div>

          <GetInTouchModal open={isModalOpen} onClose={toggleModal}/>
        </section>
    );
};
