import React, {useState, useEffect} from 'react';
import { ContactModal } from '../ContactModal';
// import Icon1 from '../../assets/img/product-lounge/1.svg';
// import Icon2 from '../../assets/img/product-lounge/2.svg';
// import Icon3 from '../../assets/img/product-lounge/3.svg';
// import Icon4 from '../../assets/img/product-lounge/4.svg';
// import Icon5 from '../../assets/img/product-lounge/5.svg';
// import Icon6 from '../../assets/img/product-lounge/6.svg';
// import Icon7 from '../../assets/img/product-lounge/7.svg';
// import Icon8 from '../../assets/img/product-lounge/8.svg';
// import Icon9 from '../../assets/img/product-lounge/9.svg';
// import Icon10 from '../../assets/img/product-lounge/10.svg';
// import Icon11 from '../../assets/img/product-lounge/11.svg';
// import Icon12 from '../../assets/img/product-lounge/12.svg';
// import Icon13 from '../../assets/img/product-lounge/13.svg';
// import Icon14 from '../../assets/img/product-lounge/14.svg';


import Pic1 from '../../assets/img/product-lounge/pic1.png';
import Pic2Png from '../../assets/img/product-lounge/pic2.png';
import Pic2Pngx2 from '../../assets/img/product-lounge/pic2@x2.png';
import Pic2Webp from '../../assets/img/product-lounge/pic2.webp';
import Pic2Webpx2 from '../../assets/img/product-lounge/pic2@x2.webp';
import Pic3 from '../../assets/img/product-lounge/pic3.png';
import Pic4Png from '../../assets/img/product-lounge/pic4.png';
import Pic4Pngx2 from '../../assets/img/product-lounge/pic4@2x.png';
import Pic4Webp from '../../assets/img/product-lounge/pic4.webp';
import Pic4Webpx2 from '../../assets/img/product-lounge/pic4@2x.webp';

import "./ProductLounge.scss";

const ProductLounge = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="product-lounge">
      <div className="product-lounge__head">
        <h3 className="product-lounge__title">
          SaaS <span>product</span> lounge
        </h3>
</div>
        <div className="product-lounge__rows">
          <div className="product-lounge__row">
            <div className="product-lounge__info">
              <h4 className="product-lounge__label">Open Banking Platform</h4>

              {/* <ul className="product-lounge__list">
                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                    <img src={Icon1} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Real-time open payment gateway with orchestrator</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon2} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Single point of access to multiple open payment systems</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon3} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Payment tracking from initiation to receipt</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon4} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Over 2,000 banks covered in Europe</div>
                </li>
              </ul> */}

              <p className="product-lounge__text">
              Experience the future of payments with our real-time open payment gateway with orchestrator for maximum efficiency and seamless transactions.
              Simplify your financial landscape and access a world of payment possibilities through our single point of entry into multiple open payment systems.
              Elevate your financial control and visibility across Europe with our comprehensive payment tracking from initiation to receipt. Access over 2,000 banks in Europe.
              </p>

              <button type='button' className="product-lounge__btn main-btn" onClick={toggleModal}>
                Learn more

                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6" />
                </svg>
              </button>
            </div>

            <div className="product-lounge__picture product-lounge__picture--absolute product-lounge__picture--transparent">
            <img src={Pic1} width="696" height="400" alt="icon" />
            </div>
          </div>

          <div className="product-lounge__row">
            <div className="product-lounge__info">
              <h4 className="product-lounge__label">Express Checkout</h4>

              {/* <ul className="product-lounge__list">
                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon5} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Pay by link</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon6} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Instant account-to-account transfers</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon7} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Request payments</div>
                </li>
              </ul> */}

              <p className="product-lounge__text">
              Simplify your payment process and enhance transaction efficiency with our innovative 'Pay by Link' feature, designed to streamline your financial operations. Empower your users financial transactions with a 1 click-pay through our "Instant Account-to-Account Transfers". Handle autmated 'Request Payments' features.
                </p>  

                <button type='button' className="product-lounge__btn main-btn" onClick={toggleModal}>
                Learn more

                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6" />
                </svg>
              </button>
            </div>

            <div className="product-lounge__picture">
              <picture>
                <source srcset={`${Pic2Png} 1x, ${Pic2Pngx2} 2x`} type="image/png" />
                <source srcset={`${Pic2Webp} 1x, ${Pic2Webpx2} 2x`} type="image/webp" />
                <img src={Pic2Png} alt="pic" />
              </picture>
            </div>
          </div>

          <div className="product-lounge__row">
            <div className="product-lounge__info">
              <h4 className="product-lounge__label">P2P Micro lending API</h4>

              {/* <ul className="product-lounge__list">
                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon8} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Automated credit scoring</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon9} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Instant analysis</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon10} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Completely decentralised</div>
                </li>

              </ul> */}

              <p className="product-lounge__text">
              Elevate your credit evaluation process and make data-driven financial decisions with our cutting-edge 'Automated Credit Scoring,' designed to optimize your loan management. Through our captivating "Instant Analysis", we can offer immediate data-driven responses. Our completely decentralized' solution is enhancing highest security standards.
              </p>

              <button type='button' className="product-lounge__btn main-btn" onClick={toggleModal}>
                Learn more

                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6" />
                </svg>
              </button>
            </div>

            <div className="product-lounge__picture product-lounge__picture--transparent">
            <img src={Pic3} alt="icon" />
            </div>
          </div>

          <div className="product-lounge__row">
            <div className="product-lounge__info">
              <h4 className="product-lounge__label">Virtual debit cards</h4>

              {/* <ul className="product-lounge__list">
                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon11} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Multi-currency accounts in EUR, USD & GBP</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon12} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Top-up your card from any bank or crypto wallet</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon13} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Easy integration with Apple Pay & GooglePay</div>
                </li>

                <li className="product-lounge__item">
                  <div className="product-lounge__item-icon">
                  <img src={Icon14} alt="icon" />
                  </div>
                  <div className="product-lounge__item-text">Use everywhere globally in over 170 countries</div>
                </li>
              </ul> */}

              <p className="product-lounge__text">
              Diversify your business's financial capabilities with multi-currency accounts in EUR, USD, and Pound Sterling, with global coverage. Enable your customers to top-up their card from any 
 bank or crypto wallet. Streamline your payment experiences and captivate your customers with effortless integration into Apple Pay and GooglePay, where convenience meets cutting-edge innovation. Expand your business's global footprint with universal usability across 170+ countries, ensuring your services resonate worldwide.
              </p>

              <button type='button' className="product-lounge__btn main-btn" onClick={toggleModal}>
                Learn more

                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6" />
                </svg>
              </button>
            </div>

            <div className="product-lounge__picture">
              <picture>
                <source srcset={`${Pic4Png} 1x, ${Pic4Pngx2} 2x`} type="image/png" />
                <source srcset={`${Pic4Webp} 1x, ${Pic4Webpx2} 2x`} type="image/webp" />
                <img src={Pic4Png} alt="pic" />
              </picture>
            </div>
          </div>
        </div>

        <ContactModal title="Contact us" open={isModalOpen} onClose={toggleModal}/>
      </div>
  );
}

export default ProductLounge;