// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-wrapper {
  margin-right: 15px;
}

.qr {
  border-radius: 5px;
  border: 1px solid rgba(31, 32, 36, 0.09);
}`, "",{"version":3,"sources":["webpack://./src/components/Qr/Qr.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,wCAAA;AACJ","sourcesContent":[".qr-wrapper {\n    margin-right: 15px;\n}\n\n.qr {\n    border-radius: 5px;\n    border: 1px solid rgba(31, 32, 36, 0.09);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
