import React, { useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import "./navigation.scss";

// const scrollToProduct = () => {
//   const productElement = document.getElementById('product');
//   if (productElement) {
//       const yOffset = -100; // смещение на 100 пикселей вверх
//       window.scrollTo({
//           top: productElement.getBoundingClientRect().top + window.pageYOffset + yOffset,
//           behavior: 'smooth'
//       });
//   }
// };




export const Navigation = ({ show, className }) => {
    
    return (
        <ul className={`header-menu-list ${className}`}>
            <li className="header-menu-list__item">
                <NavLink className="header-menu-list__item-link" to={{pathname: "/", hash: "#"}} key="nl-home">
                    Home
                </NavLink>
            </li>
            <li className="header-menu-list__item">
                <NavLink className="header-menu-list__item-link"  key="nl-prod"
                    to={{
                        pathname: "/product",
                        hash: "#product"
                    }}
                >
                    Product
                </NavLink>
            </li>
            <li className="header-menu-list__item">
                <NavLink className="header-menu-list__item-link" to="/SaaSPage">
                    SaaS
                </NavLink>
            </li>
            <li className="header-menu-list__item">
                <NavLink className="header-menu-list__item-link" to="/about">
                    About
                </NavLink>
            </li>
            <li className="header-menu-list__item">
                <NavLink className="header-menu-list__item-link" to="/investors">
                    Investors
                </NavLink>
            </li>
        </ul>
    );
};
