import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import redesignIphone from "../../assets/newImg/redesignIphone.png";
import cardsSlide from "../../assets/newImg/cards-slide.png";
import peoplesSlide from "../../assets/newImg/peoples-slide_new.png";
import yellowspot from "../../assets/img/yellow-spot.svg";
import arrowRight from "../../assets/newImg/arrowRight.svg";
import arrowLeft from "../../assets/newImg/arrowLeft.svg";
import { GoogleApple } from "../GoogleApple";
import { useMediaQuery } from "@mui/material";
import { Qr } from "../Qr";
import "./FirstScreen.scss";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: "flex" }} onClick={onClick}>
            {" "}
            <img src={arrowRight} width="14px" height="15px" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: "flex" }} onClick={onClick}>
            {" "}
            <img src={arrowLeft} width="14px" height="15px" />
        </div>
    );
}

let settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1, // Отображать только один слайд за раз
  slidesToScroll: 1, // Прокручивать по одному слайду
  vertical: true,
  verticalSwiping: true,
  // autoplay: true, // Включить автопрокрутку
  // autoplaySpeed: 2000, // Задать скорость автопрокрутки в миллисекундах (например, каждые 3 секунды)
  responsive: [
      {
          breakpoint: 550,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              vertical: false,
              verticalSwiping: false,
              prevArrow: <SamplePrevArrow />,
              nextArrow: <SampleNextArrow />,
          },
      },
  ],
};

export const FirstScreen = () => {
    const mobileWidth = useMediaQuery("(min-width: 723px) and (max-width: 1200px)");
    const mobileWidth2 = useMediaQuery("(min-width: 0px) and (max-width: 900px)");
    const mobileWidth3 = useMediaQuery("(min-width: 551px) and (max-width: 722px)");
    const mobileWidth4 = useMediaQuery("(min-width: 0px) and (max-width: 550px)");

    // Создаем массив объектов со слайдами, каждый объект содержит путь к изображению и его размеры
    const slides = [
      {
          src: redesignIphone,
          width: mobileWidth ? "200px" : mobileWidth3 ? "150px" : mobileWidth4 ? "210px" : "290px",
          height: mobileWidth ? "484px" : mobileWidth3 ? "363px" : mobileWidth4 ? "508px" : "700px",
      },
      {
          src: cardsSlide,
          width: mobileWidth ? "200px" : mobileWidth3 ? "150px" : mobileWidth4 ? "210px" : "290px",
          height: mobileWidth ? "484px" : mobileWidth3 ? "363px" : mobileWidth4 ? "508px" : "700px",
      },
      {
          src: peoplesSlide,
          width: mobileWidth ? "200px" : mobileWidth3 ? "150px" : mobileWidth4 ? "210px" : "390px",
          height: mobileWidth ? "484px" : mobileWidth3 ? "363px" : mobileWidth4 ? "508px" : "700px",
      },
  ];

  const [randomSlides, setRandomSlides] = useState([]);

  useEffect(() => {
      // Перемешиваем массив со слайдами
      const shuffledSlides = slides.sort(() => Math.random() - 0.5);
      setRandomSlides(shuffledSlides);
  }, []);

    // const renderDiffImages = () => {
    //     if (mobileWidth) {
    //         return (
    //             <img
    //                 style={{ width: "200px", height: "484px" }}
    //                 className="hero-image"
    //                 src={redesignIphone}
    //                 alt=""
    //             />
    //         );
    //     } else if (mobileWidth3) {
    //         return (
    //             <img
    //                 style={{ width: "150px", height: "363px" }}
    //                 className="hero-image"
    //                 src={redesignIphone}
    //                 alt=""
    //             />
    //         );
    //     } else if (mobileWidth4) {
    //         return (
    //             <img
    //                 style={{ width: "210px", height: "508px" }}
    //                 className="hero-image"
    //                 src={redesignIphone}
    //                 alt=""
    //             />
    //         );
    //     }

    //     return (
    //         <img
    //             style={{ width: "290px", height: "700px" }}
    //             className="hero-image"
    //             src={redesignIphone}
    //             alt=""
    //         />
    //     );
    // };

    return (
        <div className="hero" id="hero">
            <div className="hero-mainContent">
                <div className="hero-mainContent-title">
                    <h1 class="title">
                        <span className="title__first-part">All-in-one</span>
                        <br />

                        <span className="title__second-part">
                            payment app
                            <img src={yellowspot} alt="yellow-pattern" className="yellow-pattern" />
                        </span>
                    </h1>
                </div>
                <div className="hero-mainContent-box">
                    <div className="hero-mainContent-secondary-title">
                        <h2 className="secondary-title">
                            Pay with cash & crypto.
                            <br />
                            Simply add your existing bank
                            <br />
                            accounts and wallets.
                        </h2>
                    </div>

                    <div className="hero-mainContent-tertiary-title">
                        <h3 className="tertiary-title">Non custodial & decentralized.</h3>
                    </div>
                    <div className="hero-mainContent-box-item hero-mainContent-box-item--desktop">
                        {mobileWidth2 ? (
                            <Qr
                                wrapperMargin="0px 15px 0px 0px"
                                marginRight="15px"
                                width="82px"
                                height="82px"
                            />
                        ) : (
                            <Qr wrapperMargin="15px" />
                        )}
                        <div>
                            <div>
                                <div>
                                    <button className="small-button">Download app</button>
                                </div>

                                <div>
                                    <h4 className="h4">Scan the code to get the Paypolitan app.</h4>
                                </div>

                                {mobileWidth2 ? (
                                    <GoogleApple width="100px" height="29px" marginRight="4px" />
                                ) : (
                                    <GoogleApple />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="slider-wrapper">
            <Slider {...settings}>
                    {randomSlides.map((slide, index) => (
                        <div key={index}>
                            <img
                                style={{ width: slide.width, height: slide.height, paddingTop: '-100px'}}
                                className="hero-image"
                                src={slide.src}
                                alt=""
                            />
                        </div>
                    ))}
                </Slider>

            </div>

            <div className="hero-mainContent-box-item hero-mainContent-box-item--mobile">
                {mobileWidth2 ? (
                    <Qr
                        wrapperMargin="0px 10px 0px 0px"
                        marginRight="15px"
                        width="80px"
                        height="80px"
                    />
                ) : (
                    <Qr wrapperMargin="15px" />
                )}
                <div>
                    <div>
                        <div>
                            <button className="small-button">Download app</button>
                        </div>

                        <div>
                            <h4 className="h4">Scan the code to get the Paypolitan app.</h4>
                        </div>

                        {mobileWidth2 ? (
                            <GoogleApple width="100px" height="29px" marginRight="4px" />
                        ) : (
                            <GoogleApple />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
