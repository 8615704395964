import React, { useCallback, useEffect, useState } from "react";
import "./Header.scss";
import { Collapse } from "react-burgers";
import LogoLarge from "../../assets/images/logo.svg";
// import LogoSmall from "../../assets/newImg/logo-small.png";
import LogoSmall from "../../assets/images/logo.svg";
import { Popover, useMediaQuery } from "@mui/material";
import arrowGray from "../../assets/img/buttonIcons/VectorGray.svg";
import en from "../../assets/img/language/united-kingdom.png";
import ru from "../../assets/img/language/russia.png";
import de from "../../assets/img/language/germany.png";
import fr from "../../assets/img/language/france.png";
import esp from "../../assets/img/language/spain.png";
import china from "../../assets/img/language/china.svg";
import ae from "../../assets/img/language/Arabemirates.png";
import nl from "../../assets/img/language/netherlands.png";
import ua from "../../assets/img/language/ukraine.svg";
import ro from "../../assets/img/language/romania.svg";
import { Statistics } from "../Statistics";
import { Navigation } from "../Navigation";
import SideDriver from "./SideDriver/SideDriver";

export const Header = () => {
    const { body } = document;
    const [active, setActive] = useState(false);
    const width1132 = useMediaQuery("(max-width:1132px)");
    const [anchorElLang, setAnchorElLang] = React.useState(null);
    const [isOpenLang, setIsOpenLang] = useState(false);

    useEffect(() => {
        if (width1132) {
            setActive(false);
        }
        return undefined;
    }, [width1132]);

    const menuHandler = useCallback(() => {
        setActive((prevState) => !prevState);
    }, [body]);

    const handleClickLang = useCallback((event) => {
        setAnchorElLang(event.currentTarget);
        setIsOpenLang(true);
    }, []);

    const handleCloseLang = useCallback(() => {
        setIsOpenLang(false);
        setAnchorElLang(null);
    }, []);

    const renderMobileMenu = useCallback(() => {
        if (!width1132) {
            return <Navigation className="navigation-desktop" />;
        } else {
            return (
                <>
                    <Collapse
                        width={22}
                        lineHeight={2}
                        lineSpacing={4}
                        color="#830676"
                        active={active}
                        onClick={menuHandler}
                    />

                    <SideDriver
                        show={active}
                        className={`navigation-mobile ${active ? "active" : ""}`}
                    />
                </>
            );
        }
    }, [active, width1132, menuHandler]);

    return (
        <>
            <header className="header">
                <div className={"root header-container"}>
                    <div className="header-logo-box">
                        <a className="header-logo" href="/">
                            <img
                                src={LogoLarge}
                                alt="logo"
                                style={{width: "83%", height: "auto"}}
                                // srcSet={`${LogoSmall} 300w, ${LogoLarge} 1200w`}
                                // sizes="(max-width: 600px) 300px, 1200px"
                            />
                        </a>
                    </div>
                    <div className="header-container-additional">
                        <div className="header-menu">{renderMobileMenu()}</div>

                        <div className="header-container-additional-lang">
                            <button onClick={handleClickLang}>
                                <img
                                    className="header-container-additional-buyEpan-payp"
                                    src={en}
                                    alt="icon"
                                />
                                <span className="header-container-additional-lang__text">Eng</span>
                                <img
                                    alt="icon"
                                    src={arrowGray}
                                    className={`header-container-additional-buyEpan-arrow header-container-additional-lang__icon ${
                                        isOpenLang ? "rotate0" : "rotate180"
                                    }`}
                                />
                            </button>
                            <Popover
                                open={isOpenLang}
                                disableScrollLock
                                onClose={handleCloseLang}
                                anchorEl={anchorElLang}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                            >
                                <div className="header-container-additional-buyEpan-modalLang">
                                    <button>
                                        <img src={en} alt="lang icon" />
                                        <span>ENG</span>
                                    </button>
                                    <button className="langDisabled">
                                        <img src={de} alt="lang icon" />
                                        <span>DE</span>
                                    </button>
                                    <button className="langDisabled">
                                        <img src={ru} alt="lang icon" />
                                        <span>Рус</span>
                                    </button>
                                    <button className="langDisabled">
                                        <img src={fr} alt="lang icon" />
                                        <span>FR</span>
                                    </button>
                                    <button className="langDisabled">
                                        <img src={esp} alt="lang icon" />
                                        <span>ESP</span>
                                    </button>
                                    <button className="langDisabled">
                                        <img src={nl} alt="lang icon" />
                                        <span>NL</span>
                                    </button>
                                    <button className="langDisabled">
                                        <img src={ua} alt="lang icon" />
                                        <span>UA</span>
                                    </button>
                                    <button className="langDisabled">
                                        <img src={ro} alt="lang icon" />
                                        <span>RO</span>
                                    </button>
                                    <button className="langDisabled">
                                        <img src={china} alt="lang icon" />
                                        <span>中国人</span>
                                    </button>
                                    <button className="langDisabled">
                                        <img src={ae} alt="lang icon" />
                                        <span>عرب</span>
                                    </button>
                                </div>
                            </Popover>
                        </div>

                        <div className="header-container-additional-login">
                            <a href="https://app.paypolitan.io" target="_blank" rel="noreferrer">
                                <span className="header-container-additional-login__text--primary">
                                    L
                                </span>
                                <span className="header-container-additional-login__text--secondary">
                                    OGIN
                                </span>
                            </a>
                        </div>

                        <Statistics className="header-container-additional-statistics" />
                    </div>
                </div>
            </header>
        </>
    );
};
