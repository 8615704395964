import React, { useEffect, useState } from 'react';
import './Investors.scss';
import handlesPic from '../../assets/img/investors-handles.png'
import LogosSlider from '../../components/LogosSlider/indes';
import SIgnUpNewsletter from '../../components/SIgnUpNewsletter';
import { ContactModal } from '../../components/ContactModal';

const Investors = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонента
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="investors">
      <div className="investors__inner">
        <div className="investors__main">
          <div className="investors__info">
            <div className="investors__head">
              <h1 className="investors__title">investors</h1>
            </div>

            <p className="investors__text">
            <span>
              Get the latest investor news
                from Paypolitan.
            </span> Join our journey to success and sign up for our newsletter or follow us on our different social media channels.
            </p>

            <div className="investors__block">
              <p className="investors__block-label">Investor inquiries:</p>
              <button type="button" className="investors__block-link" onClick={toggleModal}>
                investor (at) paypolitan.com
              </button>
            </div>
          </div>
          
          <div className="investors__picture">
            <img src={handlesPic} alt="hands" />
          </div>
        </div>

        {/* <LogosSlider/> */}

      <div className="signup-newsletter__wrapper">
        <SIgnUpNewsletter/>
      </div>
      </div>

      <ContactModal open={isModalOpen} onClose={toggleModal}/>
    </div>
  );
}

export default Investors;