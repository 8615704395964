import React, { useState } from 'react';
import { GetInTouchModal } from '../GetInTouchModal';

import SaasMainPic from '../../assets/img/saas-main-pic.png';
import SaasMainPic2x from '../../assets/img/saas-main-pic@2x.png';
import SaasMainPicWebp from '../../assets/img/saas-main-pic.webp';
import SaasMainPicWebp2x from '../../assets/img/saas-main-pic@2x.webp';
import "./HeroSectionSaas.scss";

const HeroSectionSaas = () => {
  const [isTextVisible, setIsTextVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const toggleTextVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="hero-section hero-section--saas">
      <div className="hero-section__inner">
        <div className="hero-section__info">
          <div className="hero-section__head">
            <h1 className="hero-section__title">
              Our <span>SaaS</span> white label solutions
            </h1>
          </div>

          <p className={`hero-section__text ${isTextVisible ? '' : 'more'}`}>
            Our scalable and top notch SaaS Lounge payment platform is offering a whole range of plug & play solutions. Take your services to the next level integrating an innovative white-label SaaS payment platform and start delivering a personalized experience to your customers. Take advantage of the whole product suite’s best-in-class capabilities, backed by the highest security standards and dedicated project support. We are delivering a full range of Open Banking API integration, virtual asset payments, p2p loans, fraud detection and white label debit card solutions.
          </p>

          <button type="button" className="hero-section__more main-btn" onClick={toggleTextVisibility}>
            {isTextVisible ? 'Read more' : 'Close'}

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
            </svg>
          </button>

          <button type="button" className="hero-section__btn main-btn main-btn--yellow" onClick={toggleModal}>
            get in touch

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
            </svg>
          </button>

        </div>
        <div className="hero-section__picture hero-section__picture--saas">
          <picture>
            <source srcset={`${SaasMainPic} 1x, ${SaasMainPic2x} 2x`} type="image/png" />
            <source srcset={`${SaasMainPicWebp} 1x, ${SaasMainPicWebp2x} 2x`} type="image/webp" />
            <img src={SaasMainPic} alt="pic" />
          </picture>
        </div>
      </div>

      <GetInTouchModal open={isModalOpen} onClose={toggleModal} />
    </div>
  );
}

export default HeroSectionSaas;