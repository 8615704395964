import React, { useEffect } from 'react';
import './TermsAndConditions.scss';


const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонента
  }, []);

return (
<div className="terms">
  <h1>TERMS &amp; CONDITIONS</h1>
  <p>Please read carefully these Terms and Conditions ("Terms") before using the Website and/or the App
    https://paypolitan.io ("Website and/or the App") or buy the Paypolitan Tokens (EPAN), as they affect your
    obligations and legal rights, including, but not limited to, waivers of rights and limitation of liability. If you
    do not agree with these Terms, you must not access or use the Website and/or the App or buy the Paypolitan Tokens
    (EPAN).</p>
  <p>Please note that this document is not intended to and should not be relied upon or construed as a legal opinion or
    legal advice regarding any specific issue or factual circumstance, including but not limited to a basis for any
    investment decision. No data presented hereinafter constitutes an offer or solicitation to buy or subscribe for
    debentures, stocks or shares issued by any person or entity; units in a collective investment scheme; or any other
    security or class of securities, nor shall be interpreted in that way.</p>
  <h2>1 DEFINITIONS</h2>
  <p>"Accompanying Documents" shall mean other Paypolitan Token Generation Event regulatory documents accompanying and
    detailing these Terms, being inalienable part hereof and published on the Website and/or the App (including, but not
    limited to, Tokens Purchase Agreement, Privacy Policy) effective at the moment of the Website and/or the App use. In
    case of any discrepancies between Accompanying Documents and the Terms, the latter shall apply. "Account" shall mean
    users digital account on the Website and/or the App, which is created by user to use the Website and/or the App and
    check users Paypolitan Tokens (EPAN). "Affiliates" shall mean any persons or entities that have any relation to the
    Company, including, but not limited to partners, employees, agents and contractors of the Company. "Blockchain"
    shall mean a type of distributed ledger, comprised of unchangeable, digitally recorded, data in packages called
    blocks. "Company" (also referred to as "We", "Us") shall mean a company (including its Affiliates) to be
    incorporated in the jurisdiction of Republic of Estonia called Paypolitan OÜ, being the initiator of the Paypolitan
    TGE, not being neither a financial or investment entity, nor a partner, employer, agent or adviser for any User.
    "Cryptocurrency" shall mean digital currency, represented by Ethereum (ETH) and Bitcoin (BTC), used by Users to
    purchase Paypolitan Tokens (EPAN). "Ethereum Smart Contract" shall mean a digital computer protocol intended to
    facilitate, verify and enforce the negotiation and purchase of Paypolitan Tokens (EPAN) by a User. "Platform" – a
    decentralized network, called "Paypolitan" which allows to collect 100% of field data from any part of the world,
    based on a crowdsourcing collaborative model. "User" (also referred to as "You") shall mean any person, who uses the
    Website and/or the App, Platform or purchases Paypolitan Tokens (EPAN). "Paypolitan Tokens (EPAN)" shall mean
    cryptographic ERC20 tokens, issued by the Company and can only be used through the platform for the services
    concerned and offered by the Company. The mentioned Paypolitan Tokens (EPAN) are not securities, are not registered
    with any government entity as a security, and shall not in any case be considered as such. The Paypolitan Tokens
    (EPAN) are not intended to be commodity or any other kind of financial instrument, do not represent any share, stake
    or security or equivalent rights, including, but not limited to, any right to receive future revenue shares and
    intellectual property rights, and do not represent any ownership right. "Token Generation Event (TGE)" shall mean a
    restricted offering of Paypolitan Tokens (EPAN) (which consists of Private Sale, Public Pre-Sale, and Public Token
    Sale) to eligible Users, when a User is able to purchase Paypolitan Tokens (EPAN). Website and/or the App – the
    Website and/or the App maintained and owned by the Company at https://paypolitan.io. Whitepaper – one of the
    Accompanying Documents published by the Website and/or the App owner on the Website and/or the App, describing
    technical details of Paypolitan TGE, as well as respective Paypolitan Tokens (EPAN) pricing and sale period.</p>
  <h2>2 GENERAL PROVISIONS</h2>
  <p>2.1 These Terms and all Accompanying Documents constitute a legally binding agreement between the Company and any
    and all Users as defined herein in Section 1.</p>
  <p>2.2 These Terms are effective at the time the User begins using the Website and/or the App. It is hereby understood
    and presumed, that by the fact of the Website and/or the App use and Paypolitan Tokens (EPAN) purchase during the
    TGE the respective User fully read, understood and irrevocably accepted these Terms. If any User does not agree with
    these Terms in general or any part of them, such User's only recourse is to withhold from using the Website and/or
    the App and/or purchase of Paypolitan Tokens (EPAN). The Users may withdraw from their obligation under the Terms at
    any time by discontinuing the use of the Website and/or the App.</p>
  <p>2.3 The User acknowledges and accepts that these Terms are subject to change, modifications, amendments,
    alterations or supplements at any time without prior written notice, at Company's sole discretion. The User's
    continued use of the Website and/or the App after any amendments or alterations of these Terms and/or the Website
    and/or the App shall constitute the User's consent hereto and acceptance hereof. If at any point the User does not
    agree to any portion of the then current version of the Terms, he/she shall not use the Website and/or the App and
    purchase Paypolitan Tokens (EPAN). In any case, the date of the most recent amendments and alterations will be
    indicated at the top of these Terms in the "Last updated" Section.</p>
  <p>2.4 By using this Website and/or the App each User covenants, represents, and warrants that (under the applicable
    law and law of the country of User's residence):</p>
  <p>2.4.1 he/she is of an age of majority to enter into these Terms and all Accompanying Documents (at least 18 years
    of age), meets all other eligibility and residency requirements, and is fully able and legally competent to use the
    Website and/or the App;</p>
  <p>2.4.2 he/she has the full capacity to contract, under applicable law and law of the country of User's residence,
    with the Company and in doing so will not violate any other agreement to which he / she is a party;</p>
  <p>2.4.3 he/she has significant experience with, and understanding of, the usage and intricacies of cryptographic
    tokens, cryptocurrency and Blockchain-based systems, functional understanding of storage and transmission mechanisms
    associated with other cryptographic tokens, as well as full understanding of their framework;</p>
  <p>2.4.4 he/she is aware of all the merits, risks and any restrictions associated with cryptographic tokens (their
    purchase and use), cryptocurrency and Blockchain-based systems, and is solely responsible for any evaluations based
    on such knowledge;</p>
  <p>2.4.5 if he/she is a corporation, governmental organization or other legal entity, he/ she has the right, power and
    authority to enter into this agreement on behalf of the corporation, governmental organization or other legal entity
    and bind them to these terms;</p>
  <p>2.4.6 he/she will not be using the Website and/or the App for any illegal activity, including but not limited to
    money laundering and the financing of terrorism.</p>
  <p>2.5 A User shall not use the Website and/or the App if under the applicable law and/or law of the country of User's
    residence he/ she is prohibited from using it. Any User that is in any manner limited or prohibited from the
    purchase, possession, transfer, use or other transaction involving any amount of Paypolitan Tokens (EPAN) under the
    mentioned law should not access this Website and/or the App and is prohibited accessing, referencing, engaging, or
    otherwise using this Website and/or the App.</p>
  <p>2.6 Before using the Website and/or the App and/or purchasing any Paypolitan Tokens (EPAN) a User is obliged to
    read carefully any other Accompanying Documents that follow these Terms and regulate the Website and/or the App
    usage and the TGE procedures. The mentioned Accompanying Documents shall be regarded as an inalienable part hereof
    and their terms of use shall be the same as of these Terms. By using the Website and/or the App each and any User
    confirms that he/ she has fully read and understood not only these Terms, but any Accompanying Documents and fully
    accepts their terms.</p>
  <h2>3 USER'S ACCOUNT AND Website and/or the App REGISTRATION</h2>
  <p>3.1 For the purpose of proper use of the Website and/or the App and Paypolitan Tokens (EPAN) balance check, User
    should register on the Website and/or the App and create an individual Account with Your respective login and
    password. You are given access to an Account following Your provision of all information required by the Company,
    authorization by the Company and upon Your successful creation of an Account. You agree that You will not create an
    account for anyone other than yourself. We use your Personal Information only for providing and improving the
    Website and/or the App. By using the Website and/or the App, you agree to the collection and use of information in
    accordance with these Terms and Conditions.</p>
  <p>3.1.1. You can provide access to photo gallery on your device and upload your profile picture which will be
    uploaded to our servers hosted at AWS Europe in full GDPR compliance. Uploading a profile picture is optional. This
    picture is displayed to contacts accepted by the user only ("my contacts"), and can be edited in the account
    settings. You can also provide access to the camera in order to make a profile picture.</p>
  <p>3.1.2. For payment purposes, You can provide access to the camera and/or photo gallery on your device, and scan
    QR-code, containing recipient's wallet address.</p>
  <p>3.1.3. You can provide access to your phone contacts in order to choose the transfer recipient or make transfer
    request. Application will use name, surname, phone, picture and wallet address (if available) of the contact from
    user's phonebook. Alternatively, user may add contact manually without providing access to phonebook. Contacts, as
    well as other personal information, will not be the subject for unauthorized publishing or disclosure.</p>
  <p>3.2 You warrant that any and all information provided for the purpose of Your Account creation and/or any other
    Website and/or the App use is valid, current, complete and accurate. Registration data and other information about
    You is subject to the accompanying Privacy Policies available on the Website and/or the App.</p>
  <p>3.3 You hereby expressly consent that You are solely responsible for the use of Your login and password for the
    Account, for any registration data provided for Account creation, and for any actions done during any use of Your
    Account. You agree to keep Your login information and password private and to immediately notify the Company of any
    unauthorized Account activity. You may be aware of and modify Your login information respectively. You are solely
    responsible for any loss or damage You or the Company may suffer as a result of Your failure to do so.</p>
  <p>3.4 You may deactivate Your registration with the Website and/or the App at any time by sending respective request
    in the contact form on the Website and/or the App. We may terminate Your use of and registration with the Website
    and/or the App or freeze any transactions on Platform at any time if You violate these Terms or any other
    Accompanying Documents of the Company, at our sole discretion and without prior notice and without any liability or
    further obligation of any kind whatsoever to You or any other party, when we find such measures reasonable and/or
    necessary in a particular situation, without any refunds.</p>
  <p>3.5 You are solely responsible for your conduct and any data, text, files, information, usernames, images,
    graphics, photos, profiles, audio and video clips, sounds, musical works, works of authorship, applications, links
    and other content or materials (collectively, " Content") that you submit, post or display on or via the Website
    and/or the App.</p>
  <p>3.6 The Company reserves its right to implement verification or token purchase limitation services on the Website
    and/or the App at any time to verify certain eligibility requirements set forth by the Company or to limit certain
    residents from purchase of Paypolitan Tokens (EPAN). Such measures might include, but not limited to: IP address
    verification or restriction, application of online verification systems and checkboxes, etc. For this purpose, the
    Company reserves its right to engage any third parties at its own discretion. Any of the verification or token
    purchase restriction measures might be modified by the Company at any time.</p>
  <p>3.7 By creating an Account, You also consent to receive electronic communications from Company (e.g., via email or
    by posting notices to the Website and/or the App). These communications may include notices about Your Account
    (e.g., password changes and other transactional information) and are part of Your relationship with Us. You agree
    that any notices, agreements, disclosures or other communications that We send to You electronically will satisfy
    any legal communication requirements, including, but not limited to, that such communications be in writing. You
    should maintain copies of electronic communications from us by printing a paper copy or saving an electronic copy.
    We may also send you promotional communications via email, including, but not limited to, newsletters, special
    offers, surveys and other news and information We think will be of interest to You. You may opt out of receiving
    these promotional emails at any time by following the unsubscribe instructions provided therein.</p>
  <h1>4 TAXES</h1>
  <p>4.1 The Company makes no representations concerning the tax implications of the sale of Paypolitan Tokens (EPAN) or
    the possession or use of them. The User bears the sole responsibility to determine if the purchase of Paypolitan
    Tokens (EPAN) with cryptocurrency or the potential appreciation or depreciation in the value of Paypolitan Tokens
    (EPAN) over time has tax implications for the User in the User's home jurisdiction. By purchasing Paypolitan Tokens
    (EPAN), and to the extent permitted by law, the User agrees not to hold the Company liable for any tax liability
    associated with or arising from the purchase of Paypolitan Tokens (EPAN).</p>
  <p>4.2 All fees and charges payable by You are exclusive of any taxes, and shall certain taxes be applicable, they
    shall be added on top of the payable amounts. Upon our request, You will provide Us any information we reasonably
    request to determine whether we are obligated to collect VAT from You, including your VAT identification number. If
    any deduction or withholding is required by law, You will notify Us and will pay Us any additional amounts necessary
    to ensure that the net amount that we receive, after any deduction and withholding, equals the amount we would have
    received if no deduction or withholding had been required. Additionally, you will provide us with documentation
    showing that the withheld and deducted amounts have been paid to the relevant taxing authority.</p>
  <h2>5 INTELLECTUAL PROPERTY RIGHTS</h2>
  <p>5.1 The Company has valid, unrestricted and exclusive ownership of rights to use the patents, trademarks, trademark
    registrations, trade names, copyrights, know-how, technology and other intellectual property necessary to the
    conduct of selling of the Paypolitan Tokens (EPAN) and his activities generally.</p>
  <p>5.2 Unless otherwise indicated by the Company, all copyright and any other intellectual property of the Company,
    all content and other materials contained on the Website and/or the App or provided in connection with the Platform,
    including, without limitation, the intellectual property rights for the Platform and all text, graphics, interface,
    visual interfaces, photographs, trademarks, logos, artwork, and computer code, design, structure, selection, methods
    and algorithms, coordination, expression and other content connected to the Platform (hereinafter – the " Platform
    Materials") are the proprietary property of the Platform or our licensors, clients or suppliers and are protected by
    international copyright laws, trademark, patent, trade secret, and other intellectual property or proprietary rights
    laws.</p>
  <p>5.3 These Terms permit you to use Platform Materials for your personal, non-commercial use only. You must not
    reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download,
    store, or transmit any of the Platform Materials. There are no implied licenses under these Terms and all
    Accompanying Documents, and any rights not expressly granted to the User hereunder are reserved by the Company.</p>
  <h2>6 THIRD-PARTY CONTENT</h2>
  <p>The pages of the Website and/or the App may contain links to third-party Website and services. Such links are
    provided for User's convenience. However, their presence does not mean that they are recommended by the Company and
    the Company does not guarantee their safety and conformity with any User expectations. Furthermore, the Company is
    not responsible for maintaining any materials referenced from another site, and makes no warranties for that site or
    respective service. The Company assumes no obligations in the event of any damage or loss, or any other impact,
    directly or indirectly resulting from the use of any content, goods or services available on or through any such
    third-party Website and resources.</p>
  <h2>7 PURCHASE LIMITATIONS</h2>
  <p>7.1 The marketing and sale of the Paypolitan Tokens (EPAN) are being made in the permitted jurisdictions on the
    basis that the Paypolitan Tokens (EPAN) do not constitute a security, financial instrument or otherwise regulated
    investment in those jurisdictions, such that the prospectus or other disclosure requirements and other investor
    safeguards that would apply to a securities offering will not apply to the issuance and sale of the Paypolitan
    Tokens (EPAN) in the permitted jurisdictions.</p>
  <p>7.2 The Paypolitan Tokens (EPAN) are not regulated in the permitted jurisdictions and is not required to be
    registered with, or licensed or authorized by, relevant authorities in the permitted jurisdictions.</p>
  <p>7.3 If applicable law does not allow all or any part of the above limitation of liability to apply to you, the
    limitations will apply to the User only to the extent permitted by applicable law. The User understands and agrees
    that it is the User's obligation to ensure compliance with any legislation relevant to your country of domicile
    concerning use of the Website and/or the App, and that the User should not accept any liability for any illegal or
    unauthorized use of the Website and/or the App.</p>
  <p>7.4 Paypolitan does not offer to buy or sell the Paypolitan Tokens (EPAN), and refuses all transaction from the
    User from the following jurisdictions: The People's Republic of China (excluding the special administrative regions
    of Hong Kong and Macau, and Taiwan), South Korea, Japan, the United States, including the State of New York, Puerto
    Rico, the U.S. Virgin Islands, and any other possessions of the United States.</p>
  <h2>8 DISCLAIMERS</h2>
  <p>8.1 You understand and acknowledge that Paypolitan Tokens (EPAN), Blockchain-based technologies, Ethereum, and
    other associated and related technologies are not exclusively controlled by the Company and adverse changes in
    market forces or the technology, broadly construed, may prevent or compromise the Company's performance under these
    Terms and/or the Accompanying Documents. As such, the purchase of Paypolitan Tokens (EPAN) carries with it a number
    of risks. Prior to purchasing Paypolitan Tokens (EPAN), You should carefully consider the risks listed herein. If
    any of the following risks are unacceptable to You, You should not purchase Paypolitan Tokens (EPAN). By purchasing
    Paypolitan Tokens (EPAN), and to the extent permitted by applicable law, You are agreeing not to hold the Company or
    any Affiliates liable for any losses or any special, incidental, or consequential damages arising from, or in any
    way connected, to the sale of Paypolitan Tokens (EPAN), including losses associated with the risks set forth herein.
  </p>
  <p>8.2 By using the Platform, the User represents/warrants and accepts that:</p>
  <p>8.2.1 It is possible that due to a number of reasons outside of the Company's control, including but not limited
    to, changes in regulatory or intellectual property law, technological advancements, decreases in token or
    Cryptocurrency utility, social or economic reforms, the failure of commercial relationships, or the malfunction,
    breakdown or abandonment of the Ethereum Protocol, Paypolitan Tokens (EPAN), Blockchain-based technology, Ethereum
    and other related technologies may dissolve, disappear, be abandoned or otherwise no longer operate, or operate with
    material impairments.</p>
  <p>8.2.2 The regulatory landscape with respect to Cryptocurrencies and cryptographic tokens is evolving. There may be
    uncertainty in the regulatory treatment of Paypolitan Tokens (EPAN) in jurisdictions where the TGE is conducted.
    There may be restrictions on the sale and purchase of Cryptocurrencies in certain jurisdictions, including outright
    prohibition or a requirement that the sale or purchase must take place on a regulated exchange or trading venue.
    There is, therefore, a risk that purchasers of the Paypolitan Tokens (EPAN) cannot access a regulated exchange or
    trading venue in their jurisdiction, or any other jurisdiction, and may, therefore, find it difficult to sell the
    Paypolitan Tokens (EPAN).</p>
  <p>8.2.3 Any cryptocurrency that possess value in public markets, such as ETH, have demonstrated extreme fluctuations
    in price over short periods of time on a regular basis. A purchaser of Paypolitan Tokens (PAN) should be prepared to
    expect similar fluctuations, both down and up, in the price of Paypolitan Tokens (EPAN) denominated in respective
    cryptocurrency (ETH). Such fluctuations are due to market forces and represent changes in the balance of supply and
    demand. The Company cannot and does not guarantee market liquidity for Paypolitan Tokens (EPAN). By purchasing
    Paypolitan Tokens (PAN), You expressly acknowledge and represent that You fully understand that they may experience
    volatility in pricing and will not seek to hold the Company liable for any losses or any special, incidental, or
    consequential damages arising from, or in any way connected to, the sale of Paypolitan Tokens (EPAN).</p>
  <p>8.2.4 Any and all purchases of Paypolitan Tokens (EPAN) are final and non-refundable. By purchasing the Paypolitan
    Tokens (EPAN), the User acknowledges that neither Company nor any other of its affiliates are required to provide a
    refund for any reason, and that the User will not receive money or other compensation for any Paypolitan Tokens
    (EPAN) that are not used or remains unused for any reason.</p>
  <p>8.2.5 Should You proceed to purchase any Paypolitan Tokens (EPAN) and the product fails to be suitable for the
    special or particular purpose as intended by You, Company or its Affiliates will not be liable to You for such
    unsuitability (including but not limited to accepting the return of, or refunding to You the purchase price of the
    respective Paypolitan Tokens (EPAN).</p>
  <p>8.2.6 It is possible that the Platform will not be used by a large number of individuals, and other entities and
    that there will be limited public interest in the mentioned project and dissemination of equity. Such a lack of
    interest could impact further development of the Platform and potential use of it. Therefore, the success of the
    mentioned project cannot be predicted.</p>
  <p>8.2.7 The User recognizes that the Platform is currently under development and may undergo particular changes in
    the future. The User acknowledges that any expectations regarding the form and functionality of the Platform held by
    the User may not be met upon release of the mentioned project, for any number of reasons including a change in the
    design and implementation plans and execution of the implementation of the Platform.</p>
  <p>8.2.8 It is possible that even if the TGE threshold is met, the insufficient funds will not feasibly develop
    Platform, possibly causing the effect that the Users may not be able to participate in any intended or implied
    projects. By holding Paypolitan Tokens (EPAN), the User acknowledges that he/ she understands that while every
    effort will be made to develop and launch the Platform, it is possible that it will never be realized due to the
    aforementioned reasons.</p>
  <p>8.2.9 There may be additional risks that cannot be anticipated or foreseen due to the incipience of cryptographic
    token technology, Blockchain-based technology, Ethereum and related technologies.</p>
  <p>8.3 The Company and the respective Platform development team do not support any unfair or fraudulent practices and
    confirm their intention to further develop and elaborate the Company's project. However, there are some risks,
    associated with cryptocurrency and digital tokens market, the User understands and accepts that while the Platform
    development team will make reasonable efforts to maintain the mentioned project.</p>
  <p>8.4 Company or its Affiliates make no representations or warranties, whether express or implied, and assume no
    liability or responsibility for the proper performance of any services, online cryptocurrency services, assets or
    platforms and/or the information, images or audio contained or related to the Website and/or the App. You use all of
    the mentioned at your own risk.</p>
  <p>8.5 We will take reasonable steps to exclude any viruses or defects from the Website and/or the App, but cannot
    guarantee or warrant that any material available for downloading from the Website and/or the App will be free from
    infection, viruses and/or other code that has contaminating or destructive properties, defects will be corrected and
    accordingly no liability is accepted for defects and viruses. We are not responsible for the proper and/or complete
    transmission of the information contained in any electronic communication or of the electronic communication itself,
    nor for any delay in its delivery or receipt. Security measures have been implemented to ensure the safety and
    integrity of any of the services related to the TGE. However, despite this, information that is transmitted over the
    internet or Blockchain may be susceptible to unlawful access and monitoring.</p>
  <p>8.6 The Company and/or Platform development team or any of its Affiliates is not a financial institution and is
    currently not under supervision of any financial supervisory authority. We do not provide any licensed financial
    services, such as investment services, fund management or investment advice. The TGE is not a public offering of
    equity or debt and consequently does not fall under the securities or any prospectus regulation. None of the
    information or analyses presented are intended to form the basis for any investment decision, and no specific
    recommendations are intended, and the Website and/or the App is not, does not offer and shall not be construed as
    investment or financial product.</p>
  <p>8.7 The marketing and sale of the Paypolitan Tokens (EPAN) are being made in the permitted jurisdictions on the
    basis that the Paypolitan Tokens (EPAN) do not constitute a security, financial instrument or otherwise regulated
    investment in those jurisdictions such that the prospectus or other disclosure requirements and other investor
    safeguards that would apply to a securities offering will not apply to the issuance and sale of the Paypolitan
    Tokens (EPAN) in the permitted jurisdictions. in addition, Paypolitan Tokens (EPAN) are not regulated in the
    permitted jurisdictions and is not required to be registered with, or licensed or authorized by, relevant
    authorities in the permitted jurisdictions.</p>
  <p>8.8 The Company and/or Platform development team or any of its Affiliates is not a stock or any other investment
    instruments exchange. Paypolitan Tokens (EPAN) are not securities and shall not in any case be considered as such,
    and the offer of Paypolitan Tokens (EPAN) have not been registered with any government entity. Paypolitan Tokens
    (EPAN) do not represent any share, stake, debt or security or equivalent rights, including, but not limited to, any
    right to receive future revenue or profit shares or intellectual property rights of Company, or any voting or
    governance rights or any other right to influence the development or operation of Company, and do not represent any
    ownership right of Company.</p>
  <h2>9 LIMITATIONS OF LIABILITY</h2>
  <p>9.1 This Website and/or the App and the Paypolitan Tokens (EPAN) are provided on an "as is" basis and without any
    warranties of any kind, either expressed or implied. You assume all responsibility and risk with respect to Your use
    of the Website and/or the App and buying of any amount of Paypolitan Tokens (EPAN) and their use.</p>
  <p>9.2 The User hereby expressly understands and agrees, that:</p>
  <p>9.2.1 to the maximum extent permitted by the applicable Law, the Company or its Affiliates do not accept any
    liability for any damage or loss, including loss of business, revenue, or profits, or loss of or damage to data,
    equipment, or software (direct, indirect, punitive, actual, consequential, incidental, special, exemplary or
    otherwise), resulting from any use of, or inability to use, this Website and/or the App or the material,
    information, software, facilities, services or content on this Website and/or the App, from buying of the Paypolitan
    Tokens (EPAN) or their use by the User, regardless of the basis, upon which the liability is claimed;</p>
  <p>9.2.2 the Company and any of its Affiliates shall not be held liable to and shall not accept any liability,
    obligation or responsibility whatsoever for any change of the value of the Paypolitan Tokens (EPAN) or
    cryptocurrency;</p>
  <p>9.2.3 the Company shall not provide to the User any refund possibility (payout liquidity) for the purchased
    Paypolitan Tokens (EPAN);</p>
  <p>9.2.4 the Company shall not guaranty in any way that the Paypolitan Tokens (EPAN) might be sold or transferred
    during or after the TGE;</p>
  <p>9.2.5 it is User's obligation to ensure compliance with any legislation relevant to his/her country of residency
    concerning use of this Website and/or the App and use and buying of the Paypolitan Tokens (EPAN);</p>
  <p>9.2.6 the Company should not accept any liability for any illegal or unauthorized use of this Website and/or the
    App and use and buying of the Paypolitan Tokens (EPAN);</p>
  <p>9.2.7 the User is solely responsible for any applicable taxes imposed on tokens purchased hereunder;</p>
  <p>9.2.8 the Company does not warrant or represent that any information on the Website and/or the App is accurate or
    reliable or that the Website and/or the App will be free of errors or viruses, that defects will be corrected, or
    that the service or the server that makes it available is free of viruses or other harmful components;</p>
  <p>9.2.9 the Company shall not be liable for uninterrupted availability of the Website and/or the App at all times, in
    all countries and/or all geographic locations, or at any given time.</p>
  <p>9.3 If applicable law does not allow all or any part of the above limitation of liability to apply to you, the
    limitations will apply to you only to the extent permitted by applicable law. You understand and agree that it is
    your obligation to ensure compliance with any legislation relevant to your country of domicile concerning use of the
    Website and/or the App, and that you should not accept any liability for any illegal or unauthorised use of the
    Website and/or the App. You agree to be solely responsible for any applicable taxes imposed on Paypolitan Tokens
    (EPAN) purchased hereunder.</p>
  <p>9.4 Force Majeure Events. We shall not be liable for (1) any inaccuracy, error, delay in, or omission of (a) any
    information, or (b) the transmission or delivery of information; (2) any loss or damage arising from any event
    beyond our reasonable control, including but not limited to flood, extraordinary weather conditions, earthquake, or
    other act of God, fire, war, insurrection, riot, labour dispute, accident, action of government, communications,
    power failure, or equipment or software malfunction or any other cause beyond our reasonable control (each, a "Force
    Majeure Event").</p>
  <h2>10 INDEMNITY</h2>
  <p>10.1 To the extent allowable pursuant to applicable law, the User shall indemnify, defend, and hold the Company
    and/or its subsidiaries, affiliates, directors, officers, employees, agents, successors, and permitted assignees
    harmless from and against any and all claims, damages, losses, suits, actions, demands, proceedings, expenses,
    and/or liabilities (including but not limited to reasonable attorneys' fees incurred and/or those necessary to
    successfully establish the right to indemnification) filed/incurred by any third party against the Company arising
    out of a breach of any warranty, representation, or obligation hereunder.</p>
  <p>10.2 User shall not have any claim of any nature whatsoever against Company for any failure by Company to carry out
    any of his obligations under these Terms as a result of causes beyond his control, including but not limited to any
    strike, lockout, shortage of labour or materials, delays in transport, accidents of any kind, any default or delay
    by any sub-contractor or supplier of ours, riot, political or civil disturbances, the elements, by an act of state
    or government including regulatory action imposed, any delay in securing any permit, consent or approval required by
    Company, for the supply of products under these Terms or any other authority or any other cause whatsoever beyond
    our absolute and direct control.</p>
  <h2>11 APPLICABLE LAW AND ARBITRATION</h2>
  <p>Please read the following paragraph carefully because it requires you to arbitrate disputes with us and it limits
    the manner in which you can seek relief. All questions concerning the construction, validity, enforcement and
    interpretation of these Terms and all Accompanying Documents shall be governed by and construed and enforced in
    accordance with the laws of Estonia (" Applicable Law"). To resolve any dispute, controversy or claim arising out of
    or relating to these Terms, or the breach thereof, the Parties agree first to negotiate in good faith for a period
    of not less than thirty (30) calendar days following written notification of such controversy or claim to the other
    Party. If the negotiations do not resolve the dispute, controversy or claim to the reasonable satisfaction of all
    Parties during such period, it shall be settled by the International Arbitration and Cryptography Centre Limited
    (hereinafter – "IACC"). The number of arbitrators shall be one. The seat, or legal place, of arbitration shall be
    London, England. The language of the arbitration shall be English. The hearings will be held online in accordance
    with IACC Rules.</p>
  <h2>12 WAIVER</h2>
  <p>Except for any disputes, claims, suits, actions, causes of action, demands or proceedings in which either Party
    seeks injunctive or other equitable relief for the alleged unlawful use of intellectual property, including, without
    limitation, copyrights, trademarks, trade names, logos, trade secrets or patents, You and the Company (a) waive your
    and Company's respective rights to have any and all disputes arising from or related to these Terms resolved in a
    court, and (b) waive your and Company's respective rights to a jury trial.</p>
  <h2>13 TERMINATION AND SUSPENSION</h2>
  <p>13.1 Notwithstanding anything contained herein, We reserve the right, without notice and at our sole discretion, to
    terminate these Terms or suspend your right to access the Website and/or the App, including (but not limited to) in
    case of your breach of these Terms or if the Company believes You have committed fraud, negligence or other
    misconduct.</p>
  <p>13.2 You may terminate these Terms without notice by discontinuing use of the Website and/or the App. All rights
    granted to You under these Terms will immediately be revoked upon our termination of these Terms or our suspension
    of your access to the Website and/or the App.</p>
  <p>13.3 In the event of any Force Majeure Event, breach of these Terms and all Accompanying Documents, or any other
    event that would make provision of given by Us commercially unreasonable for the Website and/or the App, We may, in
    our discretion and without liability to You, with or without prior notice, suspend your access to all or a portion
    of our services/Website and/or the App. We may terminate your access to the Website and/or the App in our sole
    discretion, immediately and without prior notice, and delete all related information and files without liability to
    You, including, for instance, in the event that you breach any term of these Terms.</p>
  <h2>14 MISCELLANEOUS</h2>
  <p>14.1 Entire Agreement. These Terms are intended to fully reflect the terms of the original agreement between the
    parties. No provision of these Terms shall be considered waived unless such waiver is in writing and signed by the
    party that benefits from the enforcement of such provision. No waiver of any provision in these Terms, however, will
    be deemed a waiver of a subsequent breach of such provision or a waiver of a similar provision. In addition, a
    waiver of any breach or a failure to enforce any term or condition of these Terms will not in any way affect, limit,
    or waive a Party's rights hereunder at any time to enforce strict compliance thereafter with every term and
    condition hereof.</p>
  <p>14.2 Assignment. The Company may, at its sole discretion, assign its rights and/or delegate its duties under these
    Terms and all Accompanying Documents. You may not assign your rights or delegate your duties, and any assignment or
    delegation without the written consent of the Company, which the latter may withhold at its sole discretion, shall
    be void.</p>
  <p>14.3 Severability. If any term, provision, covenant or restriction of these Terms and all Accompanying Documents is
    held by a court of competent jurisdiction to be invalid, illegal, void or unenforceable, the remainder of the terms,
    provisions, covenants and restrictions set forth herein shall remain in full force and effect and shall in no way be
    affected, impaired or invalidated, and the parties hereto shall use their commercially reasonable efforts to find
    and employ an alternative means to achieve the same or substantially the same result as that contemplated by such
    term, provision, covenant or restriction. It is hereby stipulated and declared to be the intention of the parties
    that they would have executed the remaining terms, provisions, covenants and restrictions without including any of
    such that may be hereafter declared invalid, illegal, void or unenforceable.</p>
  <p>14.4 Electronic Notices. The User agrees and consents to receive electronically all communications, agreements,
    documents, receipts, notices and disclosures, that the Company provides in connection with use of the Website and/or
    the App. The User agrees that the Company may provide these communications by posting them via the Website and/or
    the App, by emailing them to User at the email address User provides.</p>
  <p>14.5 State Policies. The Company and its Affiliates strictly follow AML (Anti-Money Laundering), KYC (Know Your
    Customer) and other banking or government policies and regulations in respective jurisdictions. Each and any User
    fully agrees to assist the Company in fulfillment of the mentioned regulations and provide any necessary information
    if such is required from the User by the authorized authority. Please see our Privacy Policy and for the information
    regarding how We collect and use information. These documents are part of these Terms, so please make sure that You
    read it.</p>
  <p>14.6 Headings. Headings of sections are for convenience only and shall not be used to limit or construe such
    sections. All the sections in the agreement shall survive any termination or expiration of these Terms.</p>
  <h2>15 CONTACT</h2>
  <p>If you have any questions regarding the use of the Website and/or the App or regarding these Terms, you should send
    us an e-mail at contact@paypolitan.com.</p>
  <p>Last updated: August 20th, 2021 Tallinn, Estonia</p>
</div>
);
}

export default TermsAndConditions;