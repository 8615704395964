import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { numberWithThousands } from '../../helpers/common';
import './Statistics.scss';
import { TOKEN_HOLDERS } from '../../constants/config';
import { APP_USERS_AMOUNT } from '../../constants/common';

const formatPrice = (str = 0) => str?.toFixed(2);

export const Statistics = ({ className }) => {
  const [epanPrice, setEpanPrice] = useState('');
  const [percentage, setPercentage] = useState(0);
  const [tokenHolders, setTokenHolders] = useState(0);

  const getTokenHolders = useCallback(async () => {
    try {
      const url = new URL(TOKEN_HOLDERS);
      const response = await fetch(url);
      if (response.ok) {
        const json = await response.json();
        if (json > 2045) {
          setTokenHolders(json);
        }
        if (json < 2045) {
          setTokenHolders(2045);
        }
      }
    } catch {
      setTokenHolders(2045);
    }
  }, []);

  const getEpanPrice = useCallback(async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=paypolitan-token&vs_currencies=USD');
      if (response.ok) {
        const json = await response.json();
        const amount = Object.values(json);
        setEpanPrice(formatPrice(amount[0].usd ?? 0));
      }
    } catch {
      setEpanPrice(formatPrice(0));
    }
  }, []);

  const getEpanPersentagePrice = useCallback(async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/coins/paypolitan-token?localization=false&community_data=false&sparkline=false');
      if (response.ok) {
        const json = await response.json();
        setPercentage(Number(formatPrice(json.market_data.price_change_percentage_24h)));
      }
    } catch {
      setPercentage(0);
    }
  }, []);

  useEffect(() => {
    if (tokenHolders === 0) {
      getTokenHolders();
    }
    if (epanPrice === '') {
      getEpanPrice();
    }
    if (percentage === 0) {
      getEpanPersentagePrice();
    }
  }, [
    epanPrice,
    percentage,
    getEpanPrice,
    tokenHolders,
    getTokenHolders,
    getEpanPersentagePrice
  ]);

  const renderPercentage = useCallback(() => {
    if (percentage > 0) {
      return (
        <span className="positive">
          (
          {`+${percentage}%`}
          )
        </span>
      );
    }
    if (percentage < 0) {
      return (
        <span className="negative">
          (
          {`${percentage}%`}
          )
        </span>
      );
    }
    if (percentage === 0) {
      return (
        <span>
          (
          {`${percentage}`}
          )
        </span>
      );
    }
  }, [percentage]);

  return (
    <div className={`statistics ${className}`}>
      {/* <div className="statistics-item">
        <div className="statistics-item-title">
          <span>Share price</span>
        </div>
          <div className="statistics-item-price">
          <span>
            {`$ ${epanPrice}`}
          </span>
        </div>
      </div> */}
      <div className="statistics-item">
        <div className="statistics-item-title">
          <span>App users</span>
        </div>

        <div className="statistics-item-price">
          <span>
          { numberWithThousands(APP_USERS_AMOUNT)}
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};
