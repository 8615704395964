import React, { useEffect, useState } from 'react';
import './AccountData.scss';
import './Modal.scss';
import { ContactModal } from '../../components/ContactModal';
import arrowRight from "../../assets/newImg/arrow-right.svg";

export const Button = ({ text, icon, alt, color, textColor, onClick  }) => {
  return (
      <button
          className="main-btn open-modal-btn"
          type="button"
          style={{ backgroundColor: color, color: textColor }}
          onClick={onClick}
      >
          <span className="main-btn__text">{text}</span>
          <img width="12px" height="12px" src={icon} alt={alt}></img>
      </button>
  );
};

const AccountData = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонентаa
  }, []);

return (
<div className='account-data'>
    <h1>Account information and deletion</h1>
    <h2>Steps to delete the your account</h2>
    <p>If you choose to delete your Paypolitan account and all data associated with it you can do so by following these steps: </p>
    <ol className='legal-alpha-list'>
      <li>1. Log into your Paypolitan application</li>
      <li>2. Navigate to the Settings page by clicking on the wheel icon located on the top right of you application screen</li>
      <li>3. From the list Settings page click on the "Security" item to navigate to the Security settings page</li>
      <li>4. In the Security settings page, choose the option "Delete my account"</li>
    </ol>
    <p>After you requested the deletion of your account, you will be logged out of the application and your account and all data associated with it from our servers will be deleted immediately!</p>

    <h2>Request full or partial deletion of your account data</h2>
    <p>If you wish to contact us for the full or partial deletion of your account data, without doing it yourself from the application settings, please use the contact form bellow, and we will try to comply with your request in a reasonable time.</p>
    <p>It is important that you contact us using the email address and phone number you have associated with your account so that we can verify the authenticity of your request, and please make sure you have access to both your email and phone number for the verification process.</p>
    <Button text="contact us" icon={arrowRight} alt="icon-arrow-right" onClick={toggleModal} />

    <ContactModal title="Contact us" open={isModalOpen} onClose={toggleModal}/>

</div>
);
}

export default AccountData;