import React from "react";
import qr from "../../assets/newImg/qr_update.jpeg";
import "./Qr.scss";

export const Qr = ({
    width = "140px",
    height = "140px",
    wrapperMargin = "15px 15px 15px 15px",
}) => {
    return (
        <div className="qr-wrapper" style={{ margin: wrapperMargin }}>
            <img
                src={qr}
                width={width}
                height={height}
                className="qr"
                alt="qr code for download mobile app"
            />
        </div>
    );
};
