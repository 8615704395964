import React, {useState, useEffect} from 'react';
import { Link, NavLink, useLocation  } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import angle from "../../assets/img/angle.svg";
import qr from "../../assets/newImg/qr.jpeg";
import tw from "../../assets/img/social/twitter.svg";
import tg from "../../assets/img/social/tg.svg";
import youtube from "../../assets/img/social/youtube-icon.svg";
import medium from "../../assets/img/social/medium.svg";
import linked from "../../assets/img/social/linked.svg";
import { useMediaQuery } from "@mui/material";
import { GoogleApple } from "../GoogleApple";
import sponsor1 from "../../assets/newImg/sponsor-fca.png";
import sponsor2 from "../../assets/newImg/sponsor-letuvos.png";
import sponsor3 from "../../assets/newImg/sponsor-iban.png";
import sponsor4 from "../../assets/newImg/sponsor-open-bank.png";
import sponsor5 from "../../assets/newImg/sponsor-psd2.png";
import sponsor6 from "../../assets/newImg/sponsor-visa.png";
import { Qr } from "../Qr";
import { ContactModal } from "../ContactModal"

import "./Footer.scss";


export const Footer = () => {
  const scrollToElement = (id, offset = 100) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop - offset;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth"
      });
    }
  };


  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
    //   const loc = useLocation();
    const mobileWidth = useMediaQuery("(min-width: 0px) and (max-width: 550px)");

    //   const { pathname } = loc;
    return (
        <footer className="footer">
            <div className="footer-container">
                <img className="footer-container-angle" src={angle} alt="" />
                <div className="footer-container-content">
                    <div className="footer-container-content-box">
                        <div className="footer-container-content-box-info">
                            <div className="footer-container-content-box-info-address">
                                <button type='button' className="footer__title"  onClick={toggleModal}>
                                    <span>Contact us</span>
                                </button>

                                <div className="footer-container-content-box-info-address__content">
                                    <div className="first">
                                        <p>
                                            <span className="company-brand">Paypolitan Inc.</span>
                                            <br />
                                            108 Lakeland Avenue
                                            <br />
                                            Dover, DE 19901
                                            <br />
                                            USA
                                            <br />
                                        </p>
                                    </div>
                                    <div className="second">
                                        <p>
                                            <span className="company-brand">Paypolitan OÜ</span>
                                            <br />
                                            Narva mnt. 5
                                            <br />
                                            10117 Tallinn
                                            <br />
                                            Estonia
                                        </p>
                                    </div>
                                </div>

                                <p>
                                    <a
                                        href="mailto:info@paypolitan.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        info (at) paypolitan.com
                                    </a>
                                </p>
                            </div>
                            <div className="footer-container-content-box-info-links footer-container-content-box-info-links--faq">
                                <nav>
                                    <div className="footer__title">
                                        <span>FAQ</span>
                                    </div>
                                    <NavLink className="footer__list-item" to="/">Fees</NavLink>
                                    <NavLink className="footer__list-item" to="/terms">
                                        Terms & conditions
                                    </NavLink>
                                    <NavLink className="footer__list-item" to="/policy">
                                        Privacy policy
                                    </NavLink>
                                    <NavLink className="footer__list-item" to="/disclaimer">
                                        Disclaimer
                                    </NavLink>
                                    <NavLink className="footer__list-item" to="/cookies">
                                        Cookies
                                    </NavLink>
                                    <NavLink className="footer__list-item" to="/account-data">
                                        Account data
                                    </NavLink>
                                </nav>
                            </div>
                            <div className="footer-container-content-box-info-links footer-container-content-box-info-links--about">
                                <nav>
                                    <div className="footer__title">
                                        <span>About</span>
                                    </div>
                                    <NavLink
                                        className="footer__list-item"
                                        to="/about"
                                      >
                                        Corporate responsibility
                                      </NavLink>
                                      <NavLink
                                        className="footer__list-item"
                                        to="/investors"
                                      >
                                        Investor relations
                                      </NavLink>


                                      <Link className="footer__list-item"
                                        to={{
                                            pathname: "/about",
                                            hash: "#media"
                                        }}
                                      >
                                        Media
                                      </Link>
                                      <Link className="footer__list-item"
                                        to={{
                                            pathname: "/about",
                                            hash: "#careers"
                                        }}
                                      >
                                        <span className="footer__parent-hiring">
                                            Careers
                                            <span className="footer__hiring">we are hiring</span>
                                        </span>
                                      </Link>

                                      {/* <NavLink
                                        className="footer__list-item"
                                        to="/about"
                                        onClick={(e) => scrollToElement('media', e)}
                                      >
                                        Media
                                      </NavLink> */}
                                      {/* <NavLink
                                        className="footer__list-item"
                                        to="/about"
                                        onClick={(e) => scrollToElement('careers', e)}
                                      >
                                        <span className="footer__parent-hiring">
                                          Careers
                                          <span className="footer__hiring">we are hiring</span>
                                        </span>
                                      </NavLink> */}
                                </nav>
                            </div>
                            <div className="footer-container-content-box-info-social">
                                <div className="footer__title">
                                    <span>Social media</span>
                                </div>

                                <div className="footer-container-content-box-info-social__first-columns">
                                    <div className="footer-container-content-box-info-social__first-column">

                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://t.me/paypolitan_chat"
                                            className="footer-container-content-box-info-social-item"
                                        >
                                            <img src={tg} alt="" />
                                            <span>official chat</span>
                                        </a>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://t.me/paypolitan_ann"
                                            className="footer-container-content-box-info-social-item"
                                        >
                                            <img src={tg} alt="" />
                                            <span>announcements</span>
                                        </a>
                                        {/* <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://t.me/paypolitan_ru_chat"
                                            className="footer-container-content-box-info-social-item"
                                        >
                                            <img src={tg} alt="" />
                                            <span>RU</span>
                                        </a>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://t.me/paypolitan_hispano_chat"
                                            className="footer-container-content-box-info-social-item"
                                        >
                                            <img src={tg} alt="" />
                                            <span>Hispano</span>
                                        </a> */}
                                    </div>

                                    <div className="footer-container-content-box-info-social__second-column">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.linkedin.com/company/paypolitan/mycompany/?viewAsMember=true"
                                            className="footer-container-content-box-info-social-item"
                                        >
                                            <img src={linked} alt="" />
                                            <span>LinkedIn</span>
                                        </a>

                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://twitter.com/paypolitan"
                                            className="footer-container-content-box-info-social-item"
                                        >
                                            <img src={tw} alt="" />
                                            <span>X (Twitter)</span>
                                        </a>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.youtube.com/channel/UCF8pInyMTCMHki5P5HZVPRQ"
                                            className="footer-container-content-box-info-social-item"
                                        >
                                            <img src={youtube} alt="youtube" />
                                            <span>YouTube</span>
                                        </a>

                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="footer-container-content-box-info-social-item"
                                            href="https://paypolitan-official.medium.com/"
                                        >
                                            <img src={medium} alt="" />
                                            <span>Medium</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-container-content-downloads">
                        <div>
                            <p>Scan the code to get the Paypolitan app.</p>
                            {mobileWidth ? (
                                <GoogleApple width="130px" height="40px" marginRight="4px" />
                            ) : (
                                <GoogleApple />
                            )}
                        </div>

                        {mobileWidth ? (
                            <Qr width="66px" height="66px" wrapperMargin="0px" />
                        ) : (
                            <Qr width="80px" height="80px" wrapperMargin="0px" />
                        )}
                    </div>
                    <div className="footer-container-content-sponsors">
                    <div className="footer-container-content-sponsors__item">
                            <img src={sponsor1} width="88" alt="sponsor fca" />
                        </div>
                        <div className="footer-container-content-sponsors__item">
                            <img src={sponsor2} alt="sponsor fca" />
                        </div>
                        <div className="footer-container-content-sponsors__item footer-container-content-sponsors__item--third">
                            <img src={sponsor3} alt="sponsor iban" />
                        </div>
                        <div className="footer-container-content-sponsors__item ">
                            <img src={sponsor4} width="80" alt="sponsor open banking" />
                        </div>
                        <div className="footer-container-content-sponsors__item ">
                            <img src={sponsor5} alt="sponsor psd 2" />
                        </div>
                        <div className="footer-container-content-sponsors__item footer-container-content-sponsors__item--third">
                            <img src={sponsor6} alt="sponsor visa" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copy">
                <p>
                    © Paypolitan Inc. 2018 – 2024, all rights reserved. Paypolitan™ is a registered
                    trademark. Paypolitan OÜ is a subsidiary of Paypolitan Inc.
                </p>
            </div>
            <div className="footer-copy-bx">
                <p>
                    Paypolitan OÜ is operating a Payment Initiation Service Provider (PISP) sub
                    license under the regulation of the provision of licensed financial services
                    supervised by the Bank of Lithuania for all European Union countries and the
                    Financial Conduct Authority (FCA) for the UK. Paypolitan OÜ is an authorised
                    sub-processor and sub licensee of SafeConnect UAB and SafeConnect Ltd. an
                    Account Information (AIS) & Payment Initiation Service (PIS) Provider regulated
                    by the UK Financial Conduct Authority (ref number 827001) and an Account
                    Information (AIS) & Payment Initiation Service (PIS) Provider regulated in
                    Lithuania providing account information services and/or payment initiation
                    services to the Customer and/or End Users on behalf of the Customer.
                </p>
            </div>

            <ContactModal title="Contact us" open={isModalOpen} onClose={toggleModal}/>
        </footer>
    );
};
