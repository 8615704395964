import React from "react";
import PropTypes from "prop-types";
import "../Header.scss";
import { Navigation } from "../../Navigation";

const SideDriver = ({ show, className }) => <Navigation show={show} className={className} />;

SideDriver.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
};

export default SideDriver;
