import React, { useState } from "react"
import PropTypes from "prop-types"
import { FORMSPARK_PROMO_ID } from "../../constants/config";

const FORMSPARK_ACTION_URL = "https://submit-form.com";


export const GetInTouchModalForm = ({ onSuccess, onFailure }) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    const handleEmailInputChange = (ev) => {
        setEmail(ev.currentTarget.value);
    }

    const handleSubmit = (ev) => {
        ev.preventDefault();

        setLoading(true);
        submitGetInTouchForm({ email })
            .then(() => {
                setLoading(false)
                onSuccess()
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                onFailure();
            });
    }

    return <form className="modal__form modal__form--row" method="POST" onSubmit={handleSubmit}>
        <div className="modal__form-inputs">
            <input
                className="modal__form-input"
                type="email"
                placeholder='E-mail'
                value={email}
                required
                onChange={handleEmailInputChange}
            />
        </div>

        <button type='submit' className="modal__form-btn main-btn main-btn--yellow" disabled={loading}>
            Submit

            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
            </svg>
        </button>
    </form>
}

GetInTouchModalForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
}

async function submitGetInTouchForm(formData) {
    const response = await fetch(FORMSPARK_ACTION_URL + "/" + FORMSPARK_PROMO_ID, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(formData),
    });

    if (!response.ok) {
        throw new Error(`failed to subscribe: ${response.status} ${response.statusText}`);
    }
}