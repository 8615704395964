// export const FORMSPARK_SUBSCRIBE_ID = process.env.REACT_APP_FORMSPARK_SUBSCRIBE_ID;
// export const FORMSPARK_CONTACT_ID = process.env.REACT_APP_FORMSPARK_CONTACT_ID;
// export const FORMSPARK_MEDIA_INQUERIES_ID = process.env.REACT_APP_FORMSPARK_MEDIA_INQUERIES_ID;
// export const FORMSPARK_PROMO_ID = process.env.REACT_APP_FORMSPARK_PROMO_ID;
export const FORMSPARK_SUBSCRIBE_ID = 'vcB2fDT4';
export const FORMSPARK_CONTACT_ID = '9EcyxRF5';
export const FORMSPARK_MEDIA_INQUERIES_ID = 'kO96mktM';
export const FORMSPARK_PROMO_ID = 'UbKRMuPD';

export const WHITEPAPER_URL = process.env.REACT_APP_WHITEPAPER_URL;
export const LOGO_PNG_URL = process.env.REACT_APP_LOGO_PNG_URL;
export const LOGO_SVG_URL = process.env.REACT_APP_LOGO_SVG_URL;
export const LOGO_IN_WHITE_SVG_URL = process.env.REACT_APP_LOGO_IN_WHITE_SVG_URL;
export const CEO_PAYPOLITAN = process.env.REACT_APP_CEO_PAYPOLITAN;
export const CEO_PAYPOLITAN_APP = process.env.REACT_APP_CEO_PAYPOLITAN_APP;
export const PRESS_RELEASE_PAYPOLITAN_VS_LUCA_APP_DE = process.env.REACT_APP_PRESS_RELEASE_PAYPOLITAN_VS_LUCA_APP_DE;
export const PRESS_RELEASE_PAYPOLITAN_VS_LUCA_APP_EN = process.env.REACT_APP_PRESS_RELEASE_PAYPOLITAN_VS_LUCA_APP_EN;
export const PRESS_REVIEW_DIE_PRESSE = process.env.REACT_APP_PRESS_REVIEW_DIE_PRESSE;
export const PRESS_REVIEW_KISS_FM = process.env.REACT_APP_PRESS_REVIEW_KISS_FM;
export const PRESS_REVIEW_STADTFERNSEHEN_BRANDENBURG = process.env.REACT_APP_PRESS_REVIEW_STADTFERNSEHEN_BRANDENBURG;
export const PRESS_REVIEW_HOTELIER_TV = process.env.REACT_APP_PRESS_REVIEW_HOTELIER_TV;
export const PRODUCT_VIDEO_URL = process.env.REACT_APP_PRODUCT_VIDEO_URL;
export const TOKEN_HOLDERS = process.env.REACT_APP_TOKEN_HOLDERS;
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
