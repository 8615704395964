import React from "react";
import { GoogleApple } from "../GoogleApple";
import custodial from "../../assets/newImg/custodial.png";

export const Custodial = () => {
    return (
        <section className="homePageBlock connect-wallet">
            <div className="defi__text">
                <div className="addYourWallets-text">
                    <h2 className="addYourWallets-text-title">
                        <span>Non&nbsp;custodial </span>
                        <b className="addYourWallets-text-title-border addYourWallets-text-title-border--fiol">staking</b>
                    </h2>
                    <div>
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb28">
                        Experience the Power of Paypolitan's DeFi Toolbox:
                        <br />
                        <br />
                        Unlock the potential of your assets by staking EPAN, allowing your money to work for you passively. Think of it as receiving interest payments from a traditional bank, but with a thrilling twist. Our DeFi platform utilizes Compound interest rates, turbocharging your wealth by calculating interest not only on your original principal but also on the accrued interest over time. Step into flexible and long-term staking options right within the Paypolitan app, putting you in control of your financial destiny.
                        </p>
                    </div>

                    <div className="defi-download defi-download--desktop">
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                            Get the app:
                        </p>
                        <GoogleApple marginRight="0px" />
                    </div>
                </div>
            </div>
            <div className="homePageBlock-img homePageBlock-img--right">
                <img
                    className="defi-circle"
                    src={custodial}
                    alt="smartpone-with-crypto-wallets-icons"
                />
            </div>
            <div className="defi-download defi-download--mobile">
                <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                    Get the app:
                </p>
                <GoogleApple marginRight="0px" />
            </div>
        </section>
    );
};
