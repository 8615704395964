import React, { useEffect } from "react";
import "./SetUp.scss";
import { GoogleApple } from "../GoogleApple";
import GoogleReviews from "../../assets/newImg/google-reviews.png";
import FamilyCircle from "../../assets/newImg/family-circle.png";

export const SetUp = () => {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(async (entry) => {
                const phoneElem = entry.target.querySelector(".defi-phone");
                const circleElem = entry.target.querySelector(".defi-circle");
                const iconsElem = entry.target.querySelector(".defi-icons");
                if (entry.isIntersecting) {
                    circleElem?.classList.add("defi-circle-animation");
                    phoneElem?.classList.add("defi-phone-animation");
                    iconsElem?.classList.add("defi-icons-animation");
                    return; // if we added the class, exit the function
                }

                // We're not intersecting, so remove the class!
                circleElem?.classList.remove("defi-circle-animation");
                phoneElem?.classList.remove("defi-phone-animation");
                iconsElem?.classList.remove("defi-icons-animation");
            });
        });

        observer.observe(document.querySelector(".defi-img"));
    }, []);

    return (
        <section className="homePageBlock">
            <div className="defi__text defi__text--mr55">
                <div className="addYourWallets-text">
                    <h2 className="addYourWallets-text-title">
                        Set-up
                        <br></br>
                        in <span> 2</span> <span className="addYourWallets-text-title-border addYourWallets-text-title-border--fiol">minutes</span>
                    </h2>
                    <div>
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb28">
                        Embarking on your Paypolitan journey is as easy as 1-2-3, and it won't cost you a penny! Here's your roadmap to financial freedom: Simply grab the Paypolitan app from the App Store (iOS) or Google Play ( Android) – completely free of charge. Sign up, effortlessly link your existing bank accounts and external wallets, and you're all set. Remember, Paypolitan never holds your funds; we're just initiating payments.
                        </p>

                        <p className="addYourWallets-text-info addYourWallets-text-info--mb28"> 
                        Easy, isn’t it? Let’s get started and download the app now:
                        </p>

                        <img className="google-reviews" src={GoogleReviews} alt="google-reviews" />
                    </div>
                    <div className="defi-download defi-download--desktop">
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                            Get the app:
                        </p>
                        <GoogleApple marginRight="0px" />
                    </div>
                </div>
            </div>
            <div className="setup-img homePageBlock-img homePageBlock-img--right">
                <img className="defi-circle" src={FamilyCircle} alt="img" />
            </div>

            <div className="defi-download defi-download--mobile">
                <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                    Get the app:
                </p>
                <GoogleApple marginRight="0px" />
            </div>
        </section>
    );
};
