import React, { useEffect } from 'react';
import './PrivacyPolicy.scss';


const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонента
  }, []);

return (
<div className='privacy-policy'>
    <h1>PRIVACY POLICY</h1>
    <h2>INTRODUCTION</h2>
    <p>Thank you for showing interest in our site at https://paypolitan.io (collectively: "the Site") provided by
      Paypolitan OÜ, Narva mnt. 5, 10117, Tallinn, Estonia, ("we," "us" or "our"). In order to provide you with our
      Services we are required to collect and process certain personal data about you and your activity. By entrusting
      us with your information, we would like to assure you of our commitment to keep such information private. We have
      taken measurable steps to protect the confidentiality, security and integrity of this information. We encourage
      you to review the following information carefully.</p>
    <h2>REASONS FOR DATA COLLECTION</h2>
    <p>Processing of your personal information (meaning, any information which may potentially allow your identification
      with reasonable means; hereinafter "Personal Information") is necessary for the performance of our contractual
      obligations towards you and providing you with our Services, to protect our legitimate interests and for
      compliance with legal and financial regulatory obligations to which we might be subject. When you use our
      Services, you consent to the collection, storage, use, disclosure and other uses of your Personal Information as
      described in this Privacy Policy.</p>
    <h2>HOW DO WE RECEIVE INFORMATION ABOUT YOU?</h2>
    <p>We receive your Personal Information when you voluntarily provide us your personal details in order to create an
      user account on our site or subscribe for ongoing campaigns on our site (for example, your name and email
      address).</p>
    <h2>WHAT TYPE OF INFORMATION WE COLLECT?</h2>
    <p>In order to provide you with our Services we may collect the following Personal Information:</p>
    <ul>
      <li>Your name</li>
      <li>Your email address</li>
      <li>Your phone number</li>
      <li>Your profile photo</li>
      <li>Your phonebook, contacts</li>
      <li>Camera</li>
      <li>Certain technical information that is automatically recorded when you use access our Site or use our Services,
        such as your IP address, MAC address, device approximate location and other technical data.</li>
    </ul>
    <h2>COOKIES</h2>
    <p>When you use our Site, we may store cookies on your computer (as well as other tracking technologies like web
      beacons, pixels etc.) in order to facilitate and customize your use of our Site. A cookie is a small data text
      file, which a website stores on your computer's hard drive (if your web browser permits) that can later be
      retrieved to identify you to us. Our cookies may store information regarding your registration to our newsletter
      of demo services. You are always free to decline our cookies if your browser permits, but such disabling may cause
      some of the features of our Site to not operate properly and your online experience may be limited. We also use a
      tool called "Google Analytics" to collect information about your use of the Site. Google Analytics collects
      information such as how often users access the Site, what pages they visit when they do so, etc. We use the
      information we get from Google Analytics only to improve our Site and services. Google Analytics collects the IP
      address assigned to you on the date you visit sites, rather than your name or other identifying information. We do
      not combine the information collected through the use of Google Analytics with personally identifiable
      information. Google's ability to use and share information collected by Google Analytics about your visits to this
      Site is restricted by the Google Analytics Terms of Use and the Google Privacy Policy.</p>
    <h2>HOW DO WE USE THE INFORMATION WE COLLECT?</h2>
    <ul>
      <li>Provision of Services – we will use the Personal Information you provide us for the provision and improvement
        of our Services.</li>
      <li>Marketing purposes – we may use your Personal Information (such as your email address or phone number) for
        marketing purposes. For example, we may send you promotional material concerning our Services or our partners'
        services (which we believe may interest you). You may choose not to receive our promotional or marketing emails
        (all or any part thereof) by clicking on the "unsubscribe" link in the emails that you receive from us. Please
        note that even if you unsubscribe from our marketing materials, we may continue to send you service-related
        updates and notifications, or reply to your queries and feedback you provide us.</li>
      <li>Analytics, surveys and research - we are always trying to improve our Services and think of new and exciting
        features for our users. From time to time, we may conduct surveys or test features, and analyze the information
        we have to develop, evaluate and improve these features.</li>
      <li>Protecting our interests - we use your Personal Information when we believe it's necessary in order to take
        precautions against liabilities, investigate and defend ourselves against any third party claims or allegations,
        investigate and protect ourselves from fraud, protect the security or integrity of our services and protect our
        rights and property, including our users and/or partners.</li>
      <li>Enforcing of policies – we may use your Personal Information in order to enforce our policies.</li>
      <li>Compliance with legal and regulatory requirements - we also might use your Personal Information to investigate
        violations and prevent money laundering and perform due-diligence checks, and as required by law, regulation or
        other governmental authority, or to comply with a subpoena or similar legal process.</li>
    </ul>
    <h2>WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
    <p>We do not rent, sell, or share your Personal information with third parties except as described in this Privacy
      Policy. We may share Personal Information with the following:</p>
    <ul>
      <li>Companies in our group - we share your information with companies in our group, as well as our employees, in
        order to provide you with our services.</li>
      <li>Service providers - we share your information with business partners, such as storage providers and analytics
        providers who help us provide you with our service.</li>
      <li>Legal and regulatory entities - we may disclose any information in case we believe, in good faith, that such
        disclosure is necessary in order to enforce our Terms and Conditions, take precautions against liabilities,
        investigate and defend ourselves against any third party claims or allegations, protect the security or
        integrity of the site and our servers and protect our rights and property, including our/its users and/or
        partners. In any case we will conclude that disclosure of Personal Information is necessary, we will only
        disclose that portion of the Personal Information that is required in order to satisfy the basis for disclosure.
        We will share the Personal Information under appropriate safeguards.</li>
      <li>Merger and acquisitions – we may share your information if we enter into a business transaction such as a
        merger, acquisition, reorganization, bankruptcy, or sale of some or all of our assets. Any party that acquires
        our assets as part of such a transaction may continue to use your information in accordance with the terms of
        this Privacy Policy.</li>
    </ul>
    <h2>HOW WE PROTECT YOUR PERSONAL INFORMATION</h2>
    <p>We have implemented administrative, technical, and physical safeguards to help prevent unauthorized access, use,
      or disclosure of your Personal Information. Your Personal Information is stored on secure servers and is not
      publicly available. We limit access of your Personal Information only to those employees or partners that need to
      know the Personal Information in order to achieve the purposes of processing, as described above. You need to help
      us prevent unauthorized access to your account by protecting your password appropriately and limiting access to
      your account (for example, by signing off after you have finished accessing your account). You will be solely
      responsible for keeping your password confidential and for all use of your password and your account, including
      any unauthorized use. While we seek to protect your information to ensure that it is kept confidential, we cannot
      absolutely guarantee its security. You should be aware that there is always some risk involved in transmitting
      information over the internet. While we strive to protect your Personal Information, we cannot ensure or warrant
      the security and privacy of your personal Information or other content you transmit using the service, and you do
      so at your own risk. Please note that we cannot control, nor will we be responsible for the Personal Information
      collected and processed by third parties, its safekeeping or a breach thereof, or any other act or omission
      pertaining to it and their compliance with applicable privacy laws or regulations. We advise you to read the
      respective privacy policy of any such third party and use your best discretion.</p>
    <h2>RETENTION AND DELETION</h2>
    <p>We will retain your Personal Information for as long as necessary to provide our services, and as necessary to
      comply with our legal obligations (including those specific to financial transactions), resolve disputes, and
      enforce our policies. Retention periods will be determined taking into account the type of information that is
      collected and the purpose for which it is collected, bearing in mind the requirements applicable to the situation
      and the need to destroy outdated, unused information at the earliest reasonable time.</p>
    <p>Users' contacts and pictures are stored on back-end. Users' private keys are stored on the mobile phone in
      encrypted storage, they are not sent anywhere, they are deleted along with the application</p>
    <h2>SECURE DATA HANDLING PROCEDURES FOR PERSONAL AND SENSITIVE USER DATA</h2>
    <p>All User's information described above is sent in encrypted form to the back-end, over HTTPS.</p>
    <h2>YOUR RIGHTS</h2>
    <p>If you reside in the EU, you may request to:</p>
    <ol className="privacy-alpha-list">
      <li>Receive confirmation as to whether or not personal information concerning you is being processed, and access
        your stored personal information, together with supplementary information.</li>
      <li>Receive a copy of personal information you directly volunteer to us in a structured, commonly used and
        machine-readable format.</li>
      <li> Request rectification of your personal information that is in our control.</li>
      <li> Request erasure of your personal information.</li>
      <li> Object to the processing of personal information by us.</li>
      <li>Request to restrict processing of your personal information by us. g. Lodge a complaint with a supervisory
        authority. However, please note that these rights are not absolute, and may be subject to our own legitimate
        interests and regulatory requirements.</li>
    </ol>
    <h2>UPDATES TO THIS POLICY</h2>
    <p>This Privacy Policy is subject to changes from time to time, in our sole discretion. The most current version
      will always be posted on our website (as reflected in the "Last Updated" information). You are advised to check
      for updates regularly. By continuing to access or use our services after any revisions become effective, you agree
      to be bound by the updated Privacy Policy.</p>
    <h2>HOW TO CONTACT US?</h2>
    <p>In case of any queries or questions with this policy, please contact Paypolitan at contact@paypolitan.com.</p>
    <p>Last updated: August 20th, 2021 Tallin, Estonia</p>
</div>
);
}

export default PrivacyPolicy;