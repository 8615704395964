import React, { useRef, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import SelectedCard from "../../assets/img/selected-card.png";
import Card1 from "../../assets/img/card-1.svg";
import Card2 from "../../assets/img/card-2.svg";
import Card3 from "../../assets/img/card-3.svg";
import './Modals.scss';

const Modals = () => {
  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);

  const openModal = (modalId) => {
    if (modalId === 'modal1') {
      setModal1Visible(true);
    } else if (modalId === 'modal2') {
      setModal2Visible(true);
    }
  };

  const closeModal = () => {
    setModal1Visible(false);
    setModal2Visible(false);
  };

  return (
    <div className="modals">
      <button className="open-modal-btn" onClick={() => openModal('modal1')} data-modal-id="modal1">Open Modal 1</button>
      {modal1Visible && (
        <div className="modal show" id="modal1">
          {/* Modal content */}
          <div className="modal-content modal-content--large">
            {/* Modal header */}
            <div className="modal__header">
              <h3 className="modal__title">Compare our virtual debit cards</h3>
              <button type='button' className="close-modal-btn" onClick={closeModal}>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="22" cy="22" r="22" fill="#E4E4E4"/>
                  <line x1="15.8341" y1="14.8556" x2="29.0174" y2="28.039" stroke="black" strokeWidth="2"/>
                  <line x1="15.4745" y1="28.039" x2="28.6579" y2="14.8556" stroke="black" strokeWidth="2"/>
                </svg>
              </button>
            </div>
            <div className="modal__inner">
              <div className="modal__cards">
              <div className="modal__texts">
                <p className="modal__card-text">KYC</p>
                <p className="modal__card-text">Max top up</p>
                <p className="modal__card-text">Crypto</p>
                <p className="modal__card-text">Cash</p>
                <p className="modal__card-text">Apple Pay</p>
                <p className="modal__card-text">Google Pay</p>
                <p className="modal__card-text">Samsung Pay</p>
                <p className="modal__card-text">Monthly cost</p>
                <p className="modal__card-text">Set-up fee</p>
              </div>

              <Swiper        
                spaceBetween={16}
                slidesPerView={'auto'}
                className="mySwiper modal__card-slider"
                // breakpoints={{
                //   320: {
                //     centeredSlides: true,
                //   },
                //   768: {
                //     centeredSlides: false
                //   },
                // }}
                >

                <SwiperSlide>
                  <div className="modal__card">
                  <div className="modal__card-top">
                    <div className="modal__card-label modal__card-label--have">Users favorite</div>
                    <div className="modal__card-pic">
                      <img src={Card1} alt="card pic" />
                    </div>
                      </div>
    
                    <div className="modal__card-infos">
                    <div className="modal__card-rows">
                      <div className="modal__card-row"><p className="modal__card-text">KYC</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Max top up</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Crypto</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Cash</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Apple Pay</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Google Pay</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Samsung Pay</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Monthly cost</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Set-up fee</p></div>
                    </div>

                    <div className="modal__card-rows">
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row"><span>$10,000</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row"><span>$0.10/day</span></div>
                      <div className="modal__card-row"><span>$5.99</span></div>
                    </div>
                    </div>

                    <a className="modal__card-btn main-btn main-btn--yellow" href="#">
                      Select

                      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6"/>
                      </svg>
                    </a>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="modal__card">
                    <div className="modal__card-top">
                      <div className="modal__card-label"></div>
                    <div className="modal__card-pic">
                      <img src={Card2} alt="card pic" />
                    </div>
                    </div>

                    <div className="modal__card-infos">
                    <div className="modal__card-rows">
                      <div className="modal__card-row"><p className="modal__card-text">KYC</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Max top up</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Crypto</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Cash</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Apple Pay</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Google Pay</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Samsung Pay</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Monthly cost</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Set-up fee</p></div>
                    </div>
                    <div className="modal__card-rows">
                      <div className="modal__card-row modal__card-row--minus"><span>-</span></div>
                      <div className="modal__card-row"><span>$1,000</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--minus"><span>-</span></div>
                      <div className="modal__card-row"><span>$4.99</span></div>
                    </div>
                    </div>

                    <a className="modal__card-btn main-btn" href="#">
                      Select

                      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6"/>
                      </svg>

                    </a>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="modal__card">
                  <div className="modal__card-top">
                                       <div className="modal__card-label"></div>
                    <div className="modal__card-pic">
                      <img src={Card3} alt="card pic" />
                    </div>
                      </div>

                    <div className="modal__card-infos">
                      <div className="modal__card-rows">
                      <div className="modal__card-row"><p className="modal__card-text">KYC</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Max top up</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Crypto</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Cash</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Apple Pay</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Google Pay</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Samsung Pay</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Monthly cost</p></div>
                      <div className="modal__card-row"><p className="modal__card-text">Set-up fee</p></div>
                    </div>
                    <div className="modal__card-rows">
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row"><span>$10,000</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row modal__card-row--plus"><span>+</span></div>
                      <div className="modal__card-row"><span>Free</span>*</div>
                      <div className="modal__card-row"><span>Free</span>*</div>
                    </div>
                    </div>

                    <a className="modal__card-btn main-btn" href="#">
                     Select

                      <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="white" stroke-width="1.6"/>
                      </svg>

                    </a>
                  </div>
                </SwiperSlide>

              </Swiper>
              </div>

            </div>

            <button type='button' class="close-modal-btn close-modal-btn--mob"  onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
      {/* Add modal 2 button and content similarly */}
      <button className="open-modal-btn" onClick={() => openModal('modal2')} data-modal-id="modal2">Open Modal 2</button>
      {modal2Visible && (
        <div className="modal show" id="modal2">
          {/* Modal content */}
          <div className="modal-content modal-content--small">
            {/* Modal header */}
            <div className="modal__header">
              <h3 className="modal__title">Select virtual debit card</h3>
              <button type='button' className="close-modal-btn" onClick={closeModal}>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="22" cy="22" r="22" fill="#E4E4E4"/>
                  <line x1="15.8341" y1="14.8556" x2="29.0174" y2="28.039" stroke="black" strokeWidth="2"/>
                  <line x1="15.4745" y1="28.039" x2="28.6579" y2="14.8556" stroke="black" strokeWidth="2"/>
                </svg>
              </button>
            </div>
            <div className="modal__inner">
            <form action='#' className="modal__form">
              <div className="modal__form-card">
                <img src={SelectedCard} alt="card pic" />
              </div>
              <div className="modal__form-inputs">
                <input className="modal__form-input" type="email" placeholder='E-mail' />
                <input className="modal__form-input" type="text" placeholder='Telegram' />
              </div>

              <button type='submit' className="modal__form-btn main-btn main-btn--yellow">
                 Submit

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
              </button>
            </form>
          </div>

          <button type='button' class="close-modal-btn close-modal-btn--mob">
            Close
          </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modals;
