import React from "react";
import phone from "../../assets/newImg/phone-side.png";
import { GoogleApple } from "../GoogleApple";
import wallet1 from "../../assets/newImg/wallet-meta.png";
import connectWallet from "../../assets/newImg/connect-wallet.png";
import wallet2 from "../../assets/newImg/wallet-blue.png";
import wallet3 from "../../assets/newImg/wallet-binance.svg";
import wallet4 from "../../assets/newImg/wallet-green.svg";
import plus from "../../assets/newImg/plus.svg";
import "./ConnectWallet.scss";

const WalletsBar = () => {
    return (
        <div className="wallets-bar">
            <p className="wallets-bar__text">184 wallets connected:</p>
            <div className="wallets-bar__images">
                <img className="wallets-bar__img wallets-bar__img--1" src={wallet1} alt="" />
                <img className="wallets-bar__img" src={wallet2} alt="" />
                <img className="wallets-bar__img" src={wallet3} alt="" />
                <img className="wallets-bar__img" src={wallet4} alt="" />
                <img className="wallets-bar__img" src={plus} alt="" />
            </div>
        </div>
    );
};

export const ConnectWallet = () => {
    return (
        <section className="homePageBlock connect-wallet">
            <div className="defi__text">
                <div className="addYourWallets-text">
                    <h2 className="addYourWallets-text-title">
                        <span className="addYourWallets-text-title-border addYourWallets-text-title-border--fiol">Connect </span>
                        your&nbsp;wallet
                    </h2>
                    <div>
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb28">
                        Connect your wallet with Paypolitan's lightning-fast transaction power! Our app seamlessly syncs with over 190 top-tier wallets, including the likes of MetaMask and Coinbase Wallet. The question isn't "if", but "when" will you connect your wallet and experience financial ease like never before?
                        </p>
                    </div>

                    <div className="wallets-bar-wrapper wallets-bar-wrapper--desktop">
                        <WalletsBar />
                    </div>

                    <div className="defi-download defi-download--desktop">
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                            Get the app:
                        </p>
                        <GoogleApple marginRight="0px" />
                    </div>
                </div>
            </div>
            <div className="defi-img defi-img--not-bg homePageBlock-img homePageBlock-img--right">
                <img
                    className="defi-circle"
                    src={connectWallet}
                    alt="smartpone-with-crypto-wallets-icons"
                />
            </div>
            <div className="defi-download defi-download--mobile">
                <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                    Get the app:
                </p>
                <GoogleApple marginRight="0px" />
            </div>
        </section>
    );
};
