import React, { useEffect } from 'react';
import './Disclaimer.scss';


const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы вверх при монтировании компонента
  }, []);

return (
<div className='disclaimer'>
    <p>IMPORTANT NOTICE: PLEASE READ THE ENTIRETY OF THIS "Legal Considerations, Risks and Disclaimer" EXHIBIT
      CAREFULLY. WE RECOMMEND YOU CONSULT A LEGAL, FINANCIAL, TAX OR OTHER PROFESSIONAL ADVISOR(S) OR EXPERTS FOR
      FURTHER GUIDANCE PRIOR TO PARTICIPATING IN THE PAYPOLITAN OÜ TOKEN SALE OUTLINED IN THIS WHITE PAPER.</p>
    <h2>1. General</h2>
    <p>You must read the following "Legal Considerations, Risks and Disclaimer" exhibit before: (i) making use of this
      White Paper and any and all information available on the website(s) of Paypolitan OÜ (the "Company") located at
      https://paypolitan.io (the "Website"); and/or (ii) participating in the Company's token sale outlined in this
      White Paper (the "Token Sale"). This "Legal Considerations, Risks and Disclaimer" exhibit applies to this White
      Paper and any and all information available on the Website. The contents of this "Legal Considerations, Risks and
      Disclaimer" exhibit outlines the terms and conditions applicable to you in connection with (i) your use of</p>
    <p>this White Paper and of any and all information available on the Website; and/or (ii) your participation in the
      Token Sale, in each case in addition to any other terms and conditions that we may publish from time to time
      relating to this White Paper, the Website and the Token Sale (such terms hereinafter referred to as the "Terms").
      This "Legal Considerations, Risks and Disclaimer" exhibit may be updated from time to time and will be published
      as part of the latest version of the White Paper which shall be available on the Website. You shall be obliged to
      check the latest available version of the White Paper prior to participating in the Token Sale.</p>
    <p>The information set forth in this "Legal Considerations, Risks and Disclaimer" exhibit may not be exhaustive and
      does not imply any elements of a contractual relationship. While we make every reasonable effort to ensure that
      all information: (i) in this White Paper; and (ii) available on the Website (collectively referred to as the
      "Available Information") is accurate and up to date, such material in no way constitutes professional advice. The
      Company neither guarantees nor accepts responsibility for the accuracy, reliability, current state (as of this
      White Paper) or completeness of the Available Information. Individuals intending to participate in the Token Sale
      should seek independent professional advice prior to acting on any of the Available Information.</p>
    <h2>2. Legal Considerations</h2>
    <p>The Company has approached the Token Sale in a responsible and sensible manner. Given the uncertain and largely
      unregulated status of distributed ledger technologies, businesses and activities as well as cryptocurrencies and
      cryptocurrency-related businesses and activities, the Company has spent a significant amount of time and resources
      to consider its business approach and where it proposes to operate now and in the future. The Company will look to
      comply with all Gibraltar regulations and regulations of other jurisdictions that it is required to. However, due
      to the current uncertain state of regulation across the world, the Company cannot guarantee the legality of the
      Company's business platform and/or its ability to develop, structure and license any future Token functionality in
      every jurisdiction but the Company will strive to be responsive and compliant in the face of any regulatory
      inquiry.</p>
    <p>The Tokens are intended to serve as functional utility tokens designed for the Company's business platform. The
      Tokens are not viewed by us as securities. In the event that you purchase Tokens, your purchase cannot be refunded
      or exchanged, unless otherwise stated in the White Paper. The Company does not recommend purchasing Tokens for
      speculative investment purposes. Tokens do not entitle you to any equity, corporate governance, dividends, voting
      or similar right or entitlement in the Company or in any of its affiliated companies. Tokens are sold as digital
      assets, similar to downloadable software, digital music and the like. The Company does not recommend that you
      purchase Tokens unless you have prior experience with cryptographic tokens, blockchain-based software and
      distributed ledger technology and unless you have taken independent professional advice.</p>
    <h2>3. Regional Restrictions</h2>
    <p>Citizens, nationals, residents (tax or otherwise) and/or green card holders of each of: (i) South Korea; (ii) the
      People's Republic of China; or (iii) any other jurisdiction which prohibits the possession, dissemination or
      communication of the Available Information and/or prohibits participation in the Token Sale or the purchase of
      Tokens or any such similar activity (collectively the "Restricted Jurisdictions") or any other Restricted Persons
      are not permitted to participate in the Token Sale. The term "Restricted Persons" refers to any firm, company,
      partnership, trust, corporation, entity, government, state or agency of a state or any other incorporated or
      unincorporated body or association, association or partnership (whether or not having separate legal personality)
      that is established and/or lawfully existing under the laws of a Restricted Jurisdiction. The Company's PMA tokens
      described in this White Paper (the "Tokens") are</p>
    <p>not intended to constitute securities in any jurisdiction, nor any other form of investment. This White Paper
      does not constitute a prospectus or offer document of any sort and the Available Information is not intended to
      constitute an offer of securities or a solicitation for investment in any jurisdiction. The Company does not
      provide any opinion or any advice to purchase, sell, or otherwise transact with Tokens and the presentation,
      publication or communication of all or any part of the Available Information shall not form the basis of, or be
      relied upon in connection with, any contract or investment decision.</p>
    <h2>4. No Advice</h2>
    <p>No part of the Available Information should be considered to be business, legal, financial or tax advice
      regarding the Company, the Tokens, the Token Sale or any of the matters to which all or any part of the Available
      Information relates. You should consult your own legal, financial, tax or other professional advisor regarding the
      Available Information. You should be aware that you may be required to bear the financial risk of any purchase of
      Tokens for an indefinite period of time.</p>
    <h2>5. Limitation of Liability</h2>
    <p>In no event shall the Company or any current or former employees, officers, directors, partners, trustees,
      representative, agents, advisors, contractors, or volunteers of the Company (hereinafter the "Company
      Representatives") be liable for:</p>
    <ol className="legal-roman-list">
      <li>any loss of profits, lost savings or incidental, indirect, special or consequential damages, arising out of
        your use or inability to use the services or products or Tokens offered by the Company or the breach of any of
        these Terms by you or by any third party;</li>
      <li>any security risk such as hacker attacks, loss of password, loss of private key, or similar;</li>
      <li>mistakes or errors in code, text, or images involved in the Token Sale or in any of the Available Information
        or any information contained in the Available Information or any expectation promise representation or warranty
        arising (or purportedly arising) therefrom;</li>
      <li>any losses resulting from the volatility in pricing of Tokens in any countries and on any exchange or market
        (regulated, unregulated, primary, secondary or otherwise);</li>
      <li>any losses or damages arising out of or in connection with the purchase, use, sale or otherwise of the Tokens,
        or arising out of or in any way connected to your failure to properly secure any private key to a wallet
        containing Tokens, (collectively, the "Excluded Liability Matters").</li>
    </ol>
    <p>The Available Information (including the Website and the White Paper) and the Tokens are provided on an "as is"
      basis and without any warranties of any kind, either expressed or implied. You assume all responsibility and risk
      with respect to your use of the Available Information and purchasing of any amount of Tokens and their use. If
      applicable law does not allow all or any part of the above limitation of liability to apply to you, the
      limitations will apply to you only to the maximum extent permitted by applicable law.</p>
    <p>To the maximum extent permitted by applicable law, you hereby irrevocably and unconditionally waive: (i) all and
      any claims (whether actual or contingent and whether as an employee, office holder, trustee or in any other
      capacity whatsoever or howsoever arising) including, without limitation, claims for or relating to the Excluded
      Liability Matters, any payment or repayment of monies, indemnity or otherwise that you may have against the
      Company or against any of the Company Representatives; and (ii) release and discharge the Company and all of the
      Company Representatives from any and all liability (of whatsoever nature or howsoever arising) it or they may have
      to you. If for any reason you hereafter bring or commence any action or legal proceeding in respect of any claim
      purported to be released and discharged pursuant to this paragraph or these Terms, or otherwise attempt to pursue
      any such claim against the Company or any Company Representative then you hereby irrevocably and unconditionally
      undertake to indemnify, and keep indemnified the Company and all Company Representatives fully on demand from and
      against:</p>
    <ol className="legal-alpha-list">
      <li>all liabilities or losses suffered by the Company or any Company Representative; and</li>
      <li>all reasonable costs, charges and reasonable expenses (including without limitation reasonable legal costs and
        expenses)</li>
    </ol>
    <p>reasonably and properly incurred by the Company or any Company Representative, in each case by reason of or in
      connection with the bringing or commencement of such action or pursuit of such claim by you.</p>
    <p>If any provision or part-provision of this "Legal Considerations, Risks and Disclaimer" exhibit is or becomes
      invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid,
      legal and enforceable. If such modification is not possible, the relevant provision or part- provision shall be
      deemed deleted. Any modification to or deletion of a provision or part-provision under this "Legal Considerations,
      Risks and Disclaimer" exhibit shall not affect the validity and enforceability of the rest of this "Legal
      Considerations, Risks and Disclaimer" exhibit.</p>
    <h2>6. No Representation &amp; Warranties</h2>
    <p>The Company does not make or purport to make, and hereby disclaims, any representation, warranty or undertaking
      in any form whatsoever to any entity or person, including any representation, warranty or undertaking in relation
      to the truth, accuracy and completeness of any of the information set out in the Available Information.</p>
    <h2>7. Representation &amp; Warranties by You</h2>
    <p>By howsoever accessing and/or accepting possession or communication of all or any part of the Available
      Information, you represent and warrant (and shall be deemed to represent and warrant) to the Company on the date
      of such access or on the latest date on which you retain possession of all or any part of the Available
      Information as follows:</p>
    <ol className="legal-alpha-list">
      <li>you are over 18 (eighteen) years of age;</li>
      <li>you agree and acknowledge that the Tokens were not designated as securities and do not constitute an
        investment of any form in any jurisdiction;</li>
      <li>you agree and acknowledge that the Available Information (including the White Paper and the Website) does not
        constitute a prospectus or offer document of any sort and is not intended to constitute an offer of securities
        in any jurisdiction or a solicitation for investment in securities and you are not bound to enter into any
        contract or binding legal commitment and no cryptocurrency or other form of payment is to be accepted on the
        basis of the Available Information;</li>
      <li>you agree and acknowledge that no regulatory authority has examined or approved of the Available Information,
        no action has been or will be taken under the laws, regulatory requirements or rules of any jurisdiction and the
        publication, distribution or dissemination of all or any part of the Available Information to you does not imply
        that the applicable laws, regulatory requirements or rules have been complied with;</li>
      <li>you agree and acknowledge that the Available Information, the undertaking and/or the completion of the Token
        Sale, or future trading of the Tokens on any exchange or market (regulated, unregulated, primary, secondary or
        otherwise), shall not be construed, interpreted or deemed by you as an indication of the merits of the Company,
        the Tokens, the Token Sale or the Available Information;</li>
      <li>the distribution or dissemination of the Available Information any part thereof or any copy thereof, or
        acceptance of the same by you, is not prohibited or restricted by the applicable laws, regulations or rules in
        your jurisdiction, and where any restrictions in relation to possession are applicable, you have observed and
        complied with all such restrictions at your own expense and without liability to the Company;</li>
      <li><span>you agree and acknowledge that in the case where you wish to purchase any Tokens, the Tokens should not
          be construed, interpreted, classNameified or treated as:</span><br/>
        <ol className="legal-roman-list">
          <li>any kind of currency or commodity;</li>
          <li>debentures, stocks or shares issued by any person or entity (whether the Company or otherwise);</li>
          <li>rights, options or derivatives in respect of such debentures, stocks or shares;</li>
          <li>rights under a contract for differences or under any other contract the purpose or pretended purpose of
            which is to secure a profit or avoid a loss;</li>
          <li>units in a collective investment scheme; (vi) units in a business trust;</li>
          <li>derivatives of units in a business trust;</li>
          <li>any other security or className of securities; or</li>
          <li>any type of investment (as such term is defined by the laws of Estonia (as amended or re-enacted from time
            to time) or as such term might be construed under similar legislation in any other part of the world);</li>
        </ol>
      </li>
      <li>you are fully aware of and understand that you are not eligible to purchase any Tokens or access the Available
        Information if you are a citizen, national, resident (tax or otherwise) and/or green card holder of a Restricted
        Jurisdiction or if you are a Restricted Person;</li>
      <li>you have a basic degree of understanding of the operation, functionality, usage, storage, transmission
        mechanisms and other material characteristics of cryptocurrencies, blockchain-based software systems,
        cryptocurrency wallets or other related token storage mechanisms, blockchain technology and smart contract
        technology;</li>
      <li>you are fully aware and understand that in the case where you wish to purchase any Tokens, there are risks
        associated with: (A) the Company and its business and operations; (B) the Tokens; (C) the Token Sale; and (D)
        relying or acting on all or any part of the Available Information;</li>
      <li>you agree and acknowledge that the Company is not liable for any indirect, special, incidental, consequential
        or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue,
        income or profits, or loss of use or data, or loss of reputation, or loss of any economic or other opportunity
        of whatsoever nature or howsoever arising), arising out of or in connection with any acceptance of or reliance
        on the Available Information or any part thereof by you; and</li>
      <li>all of the above representations and warranties are true, complete, accurate and not misleading from the time
        of your last access to and/or possession of (as the case may be) the Available Information.</li>
    </ol>
    <h2>8. Cautionary Note on Forward-Looking Statements</h2>
    <p>All statements contained in the Available Information, statements made in any press releases or in any place
      accessible by the public and oral statements that may be made by the Company or the Company Representatives (as
      the case may be), that are not statements of historical fact, constitute "forward looking statements". Some of
      these statements can be identified by forward-looking terms such as "aim", "target", "anticipate", "believe",
      "could", "estimate", "expect", "if", "intend", "may", "plan", "possible", "probable", "project", "should",
      "would", "will" or other similar terms. However, these terms are not the exclusive means of identifying forward-
      looking statements.</p>
    <p>All statements regarding the Company's financial position, business strategies, plans and prospects and the
      future prospects of the industry which the Company is in are forward-looking statements. These forward-looking
      statements, including but not limited to statements as to the Company's revenue profitability and growth, expected
      revenue profitability and growth, prospects, future plans, other expected industry trends and other matters
      discussed in the Available Information regarding the Company are matters that are not historic facts, but only
      estimations and predictions. The Company makes no representation or warranty on having made any predictions or
      estimates or expectations on the basis of any formula, any mathematical or scientific modelling or forecast, or
      having made any due and proper enquiries or having undertaken any independent research or studies or otherwise.
      These forward-looking statements involve known and unknown risks, uncertainties and other factors that may cause
      the actual future results, performance or achievements of the Company to be materially different from any future
      results, performance or achievements expected, expressed or implied by such forward- looking statements. These
      factors include, amongst others:</p>
    <ol className="legal-alpha-list">
      <li>changes in political, social, economic and stock or cryptocurrency market conditions, and the regulatory
        environment in the countries in which the Company</li>
      <li>conducts its business and operations;</li>
      <li>the risk that the Company may be unable or execute or implement its business strategies and future plans;</li>
      <li>changes in interest rates and exchange rates of fiat currencies and cryptocurrencies;</li>
      <li>changes in the anticipated growth strategies and expected internal growth of the Company;</li>
      <li>changes in the availability and fees payable to the Company in connection with its business and operations;
      </li>
      <li>changes in the availability and salaries of employees who are required by the Company to operate its business
        and operations;</li>
      <li>changes in preferences of customers of the Company;</li>
      <li>changes in competitive conditions under which the Company operates, and the ability of the Company to compete
        under such conditions;</li>
      <li>changes in the future capital needs of the Company and the availability of financing and capital to fund such
        needs; (k) war or acts of international or domestic terrorism;</li>
      <li>occurrences of catastrophic events, natural disasters and acts of God that affect the businesses and/or
        operations of the Company;</li>
      <li>other factors beyond the exclusive control of the Company; and</li>
      <li>any risk and uncertainties associated with the Company and its business and operations, the Tokens, the Token
        Sale and reliance on all or any part of the Available Information.</li>
    </ol>
    <p>All forward-looking statements made by or attributable to the Company or Company Representatives are expressly
      qualified in their entirety by such factors. Given that risks and uncertainties that may cause the actual future
      results, performance or achievements of the Company to be materially different from that expected, expressed or
      implied by the forward-looking statements in the Available Information, undue reliance must not be placed on these
      statements.</p>
    <p>These forward-looking statements are applicable only as of the later of the date of publication of the White
      Paper and the latest date that the Website has been updated. Neither the Company nor the Company Representatives
      nor any other person represents, warrants and/or undertakes that the actual future results, performance or
      achievements of the Company will be as discussed in those forward- looking statements. The actual results,
      performance or achievements of the Company may differ materially from those anticipated in these forward- looking
      statements.</p>
    <p>Nothing contained in the Available Information is or may be relied upon as a promise, representation or
      undertaking as to the future performance or policies of the Company. Further, the Company disclaims any
      responsibility to update any of those forward-looking statements or publicly announce any revisions to those
      forward- looking statements to reflect future developments, events or circumstances, even if new information
      becomes available or other events occur in the future.</p>
    <h2>9. Risk Factors</h2>
    <p>You should carefully consider and evaluate each of the following risk factors and all other information contained
      in these Terms before deciding to participate in the Token Sale. To the best of the Company's knowledge and
      belief, all risk factors which are material to you in making an informed judgment to participate in the Token Sale
      have been set out below. If any of the following considerations, uncertainties or material risks develops into
      actual events, the business, financial position and/or results of operations of the Company and the maintenance
      and level of usage of the Tokens could be materially and adversely affected. In such cases, the trading price of
      Tokens (in the case where they are listed on an exchange or market (regulated, unregulated, primary, secondary or
      otherwise) could decline due to any of these considerations, uncertainties or material risks, and you may lose all
      or part of your Tokens or the economic value thereof.</p>
    <h3>9.1. Risks Relating to Participation in the Token Sale</h3>
    <p>There is no prior market for Tokens and the Token Sale may not result in an active or liquid market for the
      Tokens.</p>
    <p>Prior to the Token Sale, there has been no public market for the Tokens. Although the Company may use reasonable
      endeavors to seek the approval for availability of the Tokens for trading on a cryptocurrency exchange or market,
      there is no assurance that such approval will be obtained. Furthermore, even if such approval is granted by a
      cryptocurrency exchange, there is no assurance that an active or liquid trading market for the Tokens will
      develop, or if developed, will be sustained after the Tokens have been made available for trading on such market.
      There is also no assurance that the market price of the Tokens will not decline below the original or issue
      purchase price (the "Purchase Price"). The Purchase Price may not be indicative of the market price of the Tokens
      after they have been made available for trading on a market.</p>
    <p>A Token is not a currency issued by any central bank or national, supra- national or quasi-national organization,
      nor is it backed by any hard assets or other credit nor is it a commodity in the traditional sense of that word.
      The Company is not responsible for, nor does it pursue, the circulation and trading of Tokens on any market.
      Trading of Tokens will merely depend on the consensus on its value between the relevant market participants. No
      one is obliged to purchase any Token from any holder of the Token, including the Contributors, nor does anyone
      guarantee the liquidity or market price of Tokens to any extent at any time. Furthermore, Tokens may not be resold
      to Contributors who are citizens, nationals, residents (tax or otherwise) and/or green card holders of Restricted
      Jurisdictions or to Restricted Persons or to Contributors in any other jurisdiction where the purchase of Tokens
      may be in violation of applicable laws. Accordingly, the Company cannot ensure that there will be any demand or
      market for Tokens, or that the Purchase Price is indicative of the market price of Tokens after they have been
      made available for trading on any cryptocurrency exchange or market.</p>
    <p>Future sales or issuance of the Tokens could materially and adversely affect the market price of Tokens.</p>
    <p>Any future sale or issuance of the Tokens would increase the supply of Tokens in the market and this may result
      in a downward price pressure on the Token. The sale or distribution of a significant number of Tokens outside of
      the Token Sale (including but not limited to the sales of Tokens undertaken after the completion of the initial
      crowdsale, issuance of Tokens to persons other than Contributors for purposes of community initiatives, business
      development, academic research, education and market expansion and issuance of Tokens as a reward to users of the
      Company's business platform or otherwise), or the perception that such further sales or issuance may occur, could
      adversely affect the trading price of the Tokens.</p>
    <p>Negative publicity may materially and adversely affect the price of the Tokens.</p>
    <p>Negative publicity involving the Company, the Company's business platform, the Tokens or any of the key personnel
      of the Company and/or regulation of distributed ledger technologies, cryptocurrencies and/or crowdsales of tokens
      in any jurisdiction and/or launch partners or the adopters of the Company's technology, product(s) and/or
      service(s), as these described in the White Paper, may materially and adversely affect the market perception or
      market price of the Tokens, whether or not it is justified.</p>
    <p>The Company may not be able to pay any anticipated rewards in the future.</p>
    <p>There is no assurance that there will be sufficient engagement in the Company's business platform such that you
      will receive any rewards anticipated to be distributed to active users of the Company's business platform.
      Further, even in the event there is substantial engagement and interactions among the users of the Company's
      business platform, there is no assurance you personally will receive any part of the rewards.</p>
    <p>This is because the ability of the Company to pay any reward to you will depend on the future results of
      operations and the future business and financial condition of the Company, and there is no assurance of the future
      results of operations and the future business and financial condition of the Company.</p>
    <p>There is no assurance of any success of the Company's business platform or any future Token functionality.</p>
    <p>The utility of the Tokens hinges heavily on the performance of the Company's business platform and the continuous
      active engagement of its users and success of its contemplated business lines. There is no assurance that the
      Company's business platform will gain or continue to gain traction. Furthermore, there is no assurance that any
      future Token functionality will be realized. While the Company has made every effort to provide a realistic
      estimate, there is also no assurance that the cryptocurrencies raised in the Token Sale will be sufficient for the
      development of the Company's business platform and/or for the proper development, structuring and licensing of the
      anticipated future Token functionality. For the foregoing or any other reason, the development of the Company's
      business platform and launch of the anticipated Token functionality may not be completed and there is no assurance
      that it will be launched at all. As such, distributed Tokens may have small or no utility, and may have little
      worth or value and this would impact its use and liquidity.</p>
    <p>The trading price of the Tokens may fluctuate following the Token Sale.</p>
    <p>The utility or prices of cryptographic tokens in general tend to be relatively volatile, and can fluctuate
      significantly over short periods of time. The demand for, and the corresponding utility or market price of, the
      Tokens may fluctuate significantly and rapidly in response to, among others, the following factors, some of which
      are beyond the control of the Company:</p>
    <ol className="legal-alpha-list">
      <li>new technical innovations;</li>
      <li>analysts' speculations, recommendations, perceptions or estimates of the Token's utility or the Company's
        financial and business performance;</li>
      <li>changes in market valuations and token prices of entities with businesses similar to that of the Company that
        may be listed on the same cryptocurrency exchanges or markets as the Tokens;</li>
      <li>announcements by the Company of significant events, for example partnerships, sponsorships or new product
        developments; (e) fluctuations in market prices and trading volume of cryptocurrencies on cryptocurrency
        exchanges or markets;</li>
      <li>additions or departures of key personnel of the Company;</li>
      <li>success or failure of the Company's management in implementing business and growth strategies; and/or</li>
      <li>changes in conditions affecting the blockchain or financial technology industry, the general economic
        conditions or market sentiments, or other events or factors.</li>
    </ol>
    <p>The funds raised in the Token Sale are exposed to risks of theft.</p>
    <p>The Company will make every effort to ensure that the funds received from the Token Sale will be securely held in
      an escrow wallet, which is a multi- signature address with access thereto by private keys held by reputable and
      trusted parties. Further, the Company may make every effort to ensure that the funds received by it from Token
      Sale will be securely held through the implementation of security measures. Notwithstanding such security
      measures, there is no assurance that there will be no theft of the cryptocurrencies as a result of hacks,
      sophisticated cyber-attacks, distributed denials of service or errors, vulnerabilities or defects on the Token
      Sale website, in the smart contract(s) on which the escrow wallet and the Token Sale relies, on the Ethereum
      blockchain or any other blockchain, or otherwise. Such events may include, for example, flaws in programming or
      source code leading to exploitation or abuse thereof. In such event, even if the Token Sale is completed, the
      Company may not be able to receive the cryptocurrencies raised and the Company may not be able to use such funds
      for the development of the Company's business platform and/or for launching any future Token functionality. In
      such case, the launch of the Company's business platform and the structuring and licensing of any future Token
      functionality might be temporarily or permanently curtailed. As such, distributed Tokens may hold little worth or
      value and this would impact their trading price.</p>
    <h3>9.2. Risks Relating to the Escrow Wallet</h3>
    <p>The private keys to the escrow wallet may be compromised and the cryptocurrencies may not be able to be
      disbursed. The escrow wallet is designed to be secure. However, any one or more keys to the escrow wallet may, for
      any reason whatsoever, be lost, destroyed or otherwise compromised, and the funds held by the escrow wallet may
      not be able to be retrieved and disbursed, and may be permanently unrecoverable. In such event, even if the Token
      Sale is successful, the Company will not be able to receive the funds raised and the Company will not be able to
      use such funds for the development of the Company's business platform or the development of any future Token
      functionality. As such, distributed Tokens may hold little worth or value and this would impact their trading
      price.</p>
    <h3>9.3. Risks Relating to the Company</h3>
    <p>The Company's business platform.</p>
    <p>Any events or circumstances which adversely affect the Company or any of its successors or affiliated operating
      entities may have a corresponding adverse effect on the Company's business platform and/or on any future Token
      functionality, including but not limited to the development, structuring and launch of the Company's business
      platform and of any future Token functionality. Such adverse effects would correspondingly have an impact on the
      utility, liquidity supply and demand of the Tokens.</p>
    <p>The Company may be materially and adversely affected if it fails to effectively manage its operations as its
      business develops and evolves, which would have a direct impact on its ability to maintain or operate the
      Company's business platform and/or develop, structure and/or license any future Token functionality.</p>
    <p>The financial technology and cryptocurrency industries in which the Company competes have grown rapidly over the
      past few years and continue to evolve in response to new technological advances, changing business models,
      shifting regulations and other factors.</p>
    <p>As a result of this constantly changing environment, the Company may face operational difficulties in adjusting
      to the changes, and the sustainability of the Company will depend on its ability to manage its operations, ensure
      that it hires qualified and competent employees, and provides proper training for its personnel. As its business
      evolves, the Company must also expand and adapt its operational infrastructure. The Company's business will in
      part rely on its blockchain-based software systems, cryptocurrency wallets or other related token storage
      mechanisms, blockchain technology and smart contract technology. All of these systems, tools, and skillsets
      represent complex, costly, and rapidly changing technical infrastructure. In order to demonstrate continued
      ability to effectively manage technical support infrastructure for the Company's business platform, the Company
      will need to continue to upgrade and improve its data systems and other operational systems, procedures, and
      controls. These upgrades and improvements will require a dedication</p>
    <p>of resources and are likely to be complex and increasingly rely on hosted computer services from third parties
      that the Company does not or will not control. If the Company is unable to adapt its systems and organization in a
      timely, efficient, and cost-effective manner to accommodate changing circumstances, its business, financial
      condition and/ or results of operations may be adversely affected. If the third parties whom the Company relies on
      are subject to a security breach or otherwise suffer disruptions that impact the services the Company uses, the
      integrity and availability of its internal information could be compromised, which may consequently cause the loss
      of confidential or proprietary information and/or economic loss. The loss of financial, labour or other resources,
      and any other adverse effect on the Company's business, financial condition and/or operations, would have a direct
      adverse effect on the Company's ability to maintain or operate the Company's business platform and/or to develop,
      structure and/or license the anticipated future Token functionality. Any adverse effects affecting the Company
      business or technology are likely to also adversely impact the utility, liquidity, and trading price of the
      Tokens.</p>
    <p>The Company may experience system failures, unplanned interruptions in its network or services, hardware or
      software defects, security breaches or other causes that could adversely affect the Company's infrastructure
      network, and/or the Company's business platform.</p>
    <p>The Company is not able to anticipate when there would be occurrences of hacks, cyber-attacks, distributed
      denials of service or errors, vulnerabilities or defects in: the Company's business platform, in the smart
      contracts on which the Company or the Company's business platform relies, or on the Ethereum or any other
      blockchain. Such events may include, for example, flaws in programming or source code leading to exploitation or
      abuse thereof. The Company may not be able to detect such hacks, cyber-attacks, distributed denials</p>
    <p>of service errors vulnerabilities or defects in a timely manner, and may not have sufficient resources to
      efficiently cope with multiple service incidents happening simultaneously or in rapid succession.</p>
    <p>The Company's network or services, which would include the Company's business platform and, if successfully
      structured, developed, licensed and launched, the future Token functionality, could be disrupted by numerous
      events, including natural disasters, equipment breakdown, network connectivity downtime, power losses, or even
      intentional disruptions of its services, such as disruptions caused by software viruses or attacks by unauthorized
      users, some of which are beyond the Company's control. There can be no assurance that cyber-attacks, such as
      distributed denials of service, will not be attempted in the future or that the Company's security measures will
      be effective. The Company may be prone to attacks on its infrastructure intended to steal information about its
      technology, financial data or user information or take other actions that would be damaging to the Company and/or
      holders of the Tokens. Any significant breach of the Company's security measures or other disruptions resulting in
      a compromise of the usability, stability, and security of the Company's business platform may adversely affect the
      utility, liquidity and/or trading price of the Tokens.</p>
    <p>The Company may in the future be dependent in part on the location and data center facilities of third parties.
    </p>
    <p>The Company's future infrastructure network may be established in whole or in part through servers which it owns
      and/or houses at the location facilities of third parties, and/or servers that it rents at data center facilities
      of third parties. If the Company is unable to renew its data facility leases on commercially reasonable terms or
      at all, the Company may be required to transfer its servers to a new data center facility, and may incur
      significant costs and possible service interruption in connection with the relocation. These facilities are also
      vulnerable to damage or interruption from, among others, natural disasters, arson, terrorist attacks, power
      losses, and telecommunication failures.</p>
    <p>Additionally, the third-party providers of such facilities may suffer a breach of security as a result of
      third-party action, employee error, malfeasance or otherwise, and a third party may obtain unauthorized access to
      the data in such servers. The Company and the providers of such facilities may be unable to anticipate these
      techniques or to implement adequate preventive measures.</p>
    <p>General global market and economic conditions may have an adverse impact on the Company's operating performance,
      results of operations and/or cash flows.</p>
    <p>The Company could continue to be affected by general global economic and market conditions. Challenging economic
      conditions worldwide have from time to time, contributed, and may continue to contribute, to slowdowns in the
      information technology industry at large. Weakness in the economy could have a negative effect on the Company's
      business, operations and financial condition, including decreases in revenue and operating cash flows, and
      inability to attract future equity and/or debt financing on commercially reasonable terms. Additionally, in a
      down-cycle economic environment, the Company may experience the negative effects of a slowdown in trading and
      usage of the Company's business platform and may delay or cancel the development, structuring, licensing and/or
      launch of the anticipated Token functionality.</p>
    <p>Suppliers on which the Company relies for servers, bandwidth, location and other services could also be
      negatively impacted by economic conditions that, in turn, could have a negative impact on the Company's operations
      or expenses. There can be no assurance, therefore, that current economic conditions or worsening economic
      conditions or a prolonged or recurring recession will not have a significant, adverse impact on the Company's
      business, financial condition and results of operations, and hence, the Company's business platform and/or the
      ability to develop, structure, license and/or launch any future Token functionality. Any such circumstances would
      then correspondingly negatively impact the utility, liquidity, and/or trading price of the Tokens.</p>
    <p>The Company or the Tokens may be affected by newly implemented regulations.</p>
    <p>Distributed ledger technologies, businesses and activities as well as cryptocurrencies and cryptocurrency-related
      businesses and activities are generally unregulated worldwide, but numerous regulatory authorities across
      jurisdictions have been outspoken about considering the implementation of regulatory regimes which govern
      distributed ledger technologies, businesses and activities as well as cryptocurrencies and cryptocurrency-related
      businesses and activities. The Company or the Tokens may be affected by newly implemented regulations relating to
      distributed ledger technologies, businesses and activities as well as cryptocurrencies and cryptocurrency-related
      businesses and activities, including having to take measures to comply with such regulations, or having to deal
      with queries, notices, requests or enforcement actions by regulatory authorities, which may come at a substantial
      cost and may also require substantial modifications to the Company's business platform and/or the anticipated
      Token functionality. This may impact the appeal or practicality or functionality of the Company's business
      platform and/or the anticipated Token functionality for users and result in decreased usage of and demand for the
      Company's business platform and the Tokens. Further, should the costs (financial or otherwise) of complying with
      such newly implemented regulations exceed a certain threshold, maintaining the Company's business platform and/or
      developing, structuring, licensing and/ or launching the future Token functionality may no longer be commercially
      viable, and the Company may opt to discontinue the Company's business platform, the anticipated future Token
      functionality, and/or the Tokens. Further, it is difficult to predict how or whether governments or regulatory
      authorities may implement any changes to laws and regulations affecting distributed ledger technology and its
      applications, including the Company's business platform, the anticipated future Token functionality, and/or the
      Tokens.</p>
    <p>The Company may also have to cease operations in a jurisdiction that makes it illegal to operate in such
      jurisdiction, or make it commercially unviable or undesirable to obtain the necessary regulatory approval(s) to
      operate in such jurisdiction. In scenarios such as the foregoing, the utility, liquidity, and/or trading price of
      Tokens will be adversely affected and/or Tokens may cease to be traded.</p>
    <p>There may be unanticipated risks arising from the Tokens.</p>
    <p>Cryptographic tokens such as the Tokens are a relatively new and dynamic technology. In addition to the risks
      included in the above discussion of risk factors, there are other risks associated with your purchase, holding,
      and use of the Tokens, including those that the Company cannot anticipate. Such risks may further appear as
      unanticipated variations or combinations of the risks discussed above.</p>
    <h2>10. Privacy Policy</h2>
    <p>By purchasing Tokens, you agree to your personal data, (i.e., your e-mail address, name, address and other
      details personal to you) being processed by the Company for its business purposes or the purposes of building,
      promoting, and communicating (about) the Company's business platform and the Tokens. The Company agrees to keep
      your email address and other personal data private and not share it with the public (e.g., by including it on any
      external lists or selling to any third parties).</p>
    <h2>11. Disclaimer</h2>
    <p>The presentation of the Available Information is solely for informational purposes. Anyone interested in
      purchasing Tokens and participating in the Token Sale should consider the various risks prior to making any kind
      of decision in respect of the Token Sale. The Available Information does not comprise any advice by the Company or
      by the Company Representatives, or any recommendation to any recipient of the Available Information, by the virtue
      of any participation in the Token Sale or otherwise. The Available Information does not necessarily identify, or
      claim to identify, all the risk factors connected with the Company, the Company's business platform, the Tokens,
      the Token Sale, any future Token functionality or the Available Information. All the participants must make their
      own independent evaluation, after making such investigations as they consider essential, of the merits of
      participating in the Token Sale and after taking their own independent professional advice. Any participant in the
      Token Sale should check with and rely upon their own investment, accounting, legal and tax representatives and
      consultants in respect of such matters concerning the Company, the Company's business platform, the Tokens, the
      Token Sale, any future Token functionality and the Available Information and to assess separately the financial
      risks, consequences and appropriateness of the purchase of Tokens, or if in any doubt about the facts set out in
      the Available Information. A purchase of Tokens comprises considerable risk and might involve extraordinary risks
      that may lead to a loss of all or a significant portion of monies or monetary value utilized to acquire Tokens.
      Participants in the Token Sale are urged to completely understand, be aware of and accept the characteristics of
      the Company, the Company's business platform, the Tokens, the Token Sale, any future Token functionality and the
      Available Information. If you are not prepared to accept any or all of these Terms or the risks set out in these
      Terms then you are urged not to participate in the Token Sale. No guarantee or assurance is given by the Company
      or by the Company Representatives that the Company's proposals, objectives and/or outcomes set out in the
      Available</p>
    <p>Information will be achieved in whole or in part. You are urged to consider whether participation in the Token
      Sale is suitable for you having regard to your personal and financial circumstances and your financial resources.
    </p>
    <h2>12. Restrictions on Distribution and Dissemination of the Available Information</h2>
    <p>The distribution or dissemination howsoever of all or any part of the Available Information may be prohibited or
      restricted by the laws, regulatory requirements and rules of certain jurisdictions. In the case where any such
      restriction applies, you are responsible for informing yourself in respect of the same and for observing any such
      restrictions which are applicable to your possession and/or dissemination of all or any part of the Available
      Information at your own expense and without liability to the Company.</p>
    <p>Persons to whom a copy of all or any part of the Available Information has been distributed or disseminated,
      provided access to or who otherwise have all or any part of the Available Information in their possession shall
      not circulate it to any other persons, reproduce or otherwise distribute any information contained herein for any
      purpose whatsoever nor permit or cause the same to occur.</p>
    <h2>13. No Offer of Securities or Registration</h2>
    <p>This White Paper does not constitute a prospectus or offer document of any sort and is not intended to constitute
      an offer of securities or a solicitation for investment in securities in any jurisdiction. No person is bound to
      enter into any contract or binding legal commitment and no cryptocurrency or other form of payment is to be
      accepted on the basis of all or any part of the Available Information. Any agreement in relation to any sale and
      purchase of Tokens is to be governed by the terms and conditions of such agreement and no other document. In the
      event of any inconsistencies between the terms and conditions of that agreement and the Available Information,
      those terms and conditions shall prevail.</p>
    <p>You are not eligible to purchase any Tokens in the Token Sale if you are a citizen, resident (tax or otherwise)
      or green card holder of a Restricted Jurisdiction or you are a Restricted Person. No regulatory authority has
      examined or approved of any of the Available Information. No such action has been or will be taken under the laws,
      regulatory requirements or rules of any jurisdiction. The publication, distribution or dissemination of the
      Available Information does not imply that the applicable laws, regulatory requirements or rules have been complied
      with.</p>

</div>
);
}

export default Disclaimer;