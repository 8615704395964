import React from 'react';
import GlobalMap from '../../assets/img/global-map.png';
import './GlobalPresence.scss';

const GlobalPresence = () => {
  return (
    <div className="global-presence">
      <div className="global-presence__head">
        <h3 className="global-presence__title">

          <span>Global</span> presence
        </h3>

        <p className="global-presence__text">
        At Paypolitan, we are not only building a decentralized solution, but our team is also truely decentralized in different regions of the world.
        </p>
      </div>
      
      <div className="global-presence__pic">
        <img  src={GlobalMap} width="1200" height="530" alt="map img" />
      </div>
    </div>
  );
}

export default GlobalPresence;