import React, { useState } from 'react';
import { MediaInquiresForm } from "../MediaInquiresForm"
import Partner1 from '../../assets/img/media/partner1.png';
import Partner2 from '../../assets/img/media/partner2.png';
import Partner3 from '../../assets/img/media/partner3.png';
import Partner4 from '../../assets/img/media/partner4.png';
import Partner5 from '../../assets/img/media/partner5.png';
import Partner6 from '../../assets/img/media/partner6.png';
import './Media.scss';
import useHashScroll from '../../hooks/useHashScroll';

const Media = () => {
  const [showAll, setShowAll] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const mediaRef = useHashScroll('media')

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };


  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const downloads = [
    {
      name: 'Logo HD JPEG',
      url: 'https://media-landing-prod.s3.eu-central-1.amazonaws.com/logo-web.png'
    },
    {
      name: 'OnePager',
      url: 'https://media-landing-prod.s3.eu-central-1.amazonaws.com/logo.svg'
    },
    {
      name: 'White paper',
      url: 'https://media-landing-prod.s3.eu-central-1.amazonaws.com/logo-white-web.png'
    },
    {
      name: 'CEO Paypolitan Nils Tharandt Ortiz',
      url: 'https://media-landing-prod.s3.eu-central-1.amazonaws.com/CEO+Paypolitan+Nils+Tharandt+Ortiz.JPG'
    },
    {
      name: 'CEO Paypolitan app Nils Tharandt Ortiz',
      url: 'https://media-landing-prod.s3.eu-central-1.amazonaws.com/CEO+Paypolitan+Nils+Tharandt+Ortiz+app.jpg'
    }
  ];

  return (
    <div className="media" id='media' ref={mediaRef}>
      <div className="media__head">
        <h3 className="media__title">
          <span>Media</span>
        </h3>
      </div>

      <div className="media__content">
        <MediaInquiresForm downloads={downloads} showAll={showAll} onShowMore={handleShowMore} />
        <div className="media__releases">
          <div className={`media__releases-columns ${showMore ? 'view-more' : ''}`}>
            <div className="media__releases-column">
              <div className="media__releases-label">Press releases</div>

              <div className="media__releases-row">
                <p className="media__releases-data">16/02/2022</p>

                <a className="media__releases-link" target="_blank" href="https://media-landing-prod.s3.eu-central-1.amazonaws.com/Press+release+Paypolitan+vs+Luca-App+English+160222.pdf">
                  <span>Paypolitan vs Luca-App English</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">16/02/2022</p>

                <a className="media__releases-link" target="_blank" href="https://media-landing-prod.s3.eu-central-1.amazonaws.com/Press+release+Paypolitan+vs+Luca-App+German+160222.pdf">
                  <span>Paypolitan vs Luca-App German</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="media__releases-column">
              <div className="media__releases-label">Articles</div>
              <div className="media__releases-row">
                <p className="media__releases-data">29/05/2022</p>

                <a className="media__releases-link" target="_blank" href="https://www.kissfm.ro/articol/22516/vino-sa-inveti-despre-blockchain-si-crypto-monede-la-bitcoin-bucharest-2022">
                  <span>
                    Kiss FM "Vino sa înveți despre blockchain și crypto monede la Bitcoin Bucharest 2022!"</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">17/02/2022</p>

                <a className="media__releases-link" target="_blank" download href="https://media-landing-prod.s3.eu-central-1.amazonaws.com/Die+Presse+Abmahnung+gegen+Bezahldienstfunktion+der+Luca-App.png">
                  <span>Die Presse “Abmahnung gegen Bezahldienstfunktion der Luca-App”</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">17/02/2022</p>

                <a className="media__releases-link" target="_blank" download href="https://media-landing-prod.s3.eu-central-1.amazonaws.com/Stadtfernsehen+Brandenburg+SKB+Nachrichten+aus+Brandenburg.jpg">
                  <span>
                    Stadtfernsehen Brandenburg “SKB Nachrichten aus Brandenburg”</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">17/02/2022</p>

                <a className="media__releases-link" target="_blank" download href="https://media-landing-prod.s3.eu-central-1.amazonaws.com/Hotelier+TV+Neuer+%C3%84rger+um+die+Luca+App.png">
                  <span>
                    Hotelier TV “Neuer Ärger um die Luca App”</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">16/02/2022</p>

                <a className="media__releases-link" target="_blank" download href="https://rp-online.de/panorama/luca-app-will-bezahlfunktion-einfuehren-unternehmen-geht-juristische-schritte_vid-66317581">
                  <span>
                    Rheinische Post “Luca App will Bezahlfunktion einführen: Unternehmen geht...“</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">16/02/2022</p>

                <a className="media__releases-link" target="_blank" download href="https://www.msn.com/de-de/sport/fussball/abmahnung-gegen-bezahldienstfunktion-der-luca-app/vp-AATVjSP">
                  <span>
                    MSN “Abmahnung gegen Bezahldienstfunktion der Luca-App“</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">22/01/2021</p>

                <a className="media__releases-link" target="_blank" download href="https://www.coinspeaker.com/paypolitan-take-over-tenx/">
                  <span>
                    Coinspeaker “Will Paypolitan Take Over TenX?“</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">21/01/2021</p>

                <a className="media__releases-link" target="_blank" download href="https://www.dailyadvent.com/news/eb3358e3c83cb227262b5270ac65109c-Will-Paypolitan-Take-Over-TenX">
                  <span>
                    Opera News “Will Paypolitan Take Over TenX?“</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">19/01/2021</p>

                <a className="media__releases-link" target="_blank" download href="https://www.btc-echo.de/schlagzeilen/paypolitan-moechte-tenx-kunden-uebernehmen-109417/">
                  <span>
                    BTC-ECHO “Paypolitan möchte TenX-Kunden übernehmen“</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>

              <div className="media__releases-row">
                <p className="media__releases-data">19/01/2021</p>

                <a className="media__releases-link" target="_blank" download href="https://www.minchens.de/exclusive-paypolitan-wants-to-take-over-tenx-customers/">
                  <span>
                    Minchens “Paypolitan wants to take over TenX customers“</span>

                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <button type='button' className="media__releases-btn main-btn main-btn--transparent" onClick={toggleShowMore}>
            {showMore ? 'Hide back' : 'Show more'}
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
            </svg>
          </button>
        </div>

        <div className="media__partners">
          <div className="media__partner media__partner--yellow">
            <img src={Partner1} alt="partner img" />
          </div>

          <div className="media__partner media__partner--black">
            <img src={Partner2} alt="partner img" />
          </div>

          <div className="media__partner media__partner--light-blue">
            <img src={Partner3} alt="partner img" />
          </div>

          <div className="media__partner media__partner--blue">
            <img src={Partner4} alt="partner img" />
          </div>

          <div className="media__partner media__partner--purple">
            <img src={Partner5} alt="partner img" />
          </div>

          <div className="media__partner media__partner--dark-green">
            <img src={Partner6} alt="partner img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Media;