import React from "react";
import "./Defi.scss";
import phone from "../../assets/newImg/phone-side.png";
import { GoogleApple } from "../GoogleApple";

export const DeFi = () => {
    return (
        <section className="homePageBlock">
            <div className="defi__text">
                <div className="addYourWallets-text">
                    <h2 className="addYourWallets-text-title">
                        Send money to friends & family with&nbsp;
                        <span className="addYourWallets-text-title-border addYourWallets-text-title-border--purple">1 click</span>
                    </h2>
                    <div>
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb28">
                        Embrace Effortless Banking with Paypolitan: Seamlessly link your current bank accounts and experience a new way to pay via our intuitive app. Your money stays where it is. Not only can you centralize all your accounts in one convenient hub, but you also have the power to handpick your preferred bank account for each transaction, every single time.
                        </p>
                    </div>
                    <div className="defi-download defi-download--desktop">
                        <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                            Get the app:
                        </p>
                        <GoogleApple marginRight="0px" />
                    </div>
                </div>
            </div>
            <div className="defi-img homePageBlock-img homePageBlock-img--right">
                <img className="defi-circle" src={phone} alt="img" />
            </div>
            <div className="defi-download defi-download--mobile">
                <p className="addYourWallets-text-info addYourWallets-text-info--mb10">
                    Get the app:
                </p>
                <GoogleApple marginRight="0px" />
            </div>
        </section>
    );
};
