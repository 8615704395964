import React from 'react';
import GraphikDesktop from '../../assets/img/app-info-graphik-desktop.svg';
import EthPic from '../../assets/img/eth-pic.png';
import BinancePic from '../../assets/img/binance-pic.png';
import VisaPic from '../../assets/img/visa-pic.png';
import MartercardPic from '../../assets/img/mastercard-pic.png';
import PayPic from '../../assets/img/pay-pic.png';
import ApplePayLogo from '../../assets/img/Apple_Pay_logo.png';
import GooglePayLogo from '../../assets/img/Google_Pay_Logo.png';
import './Appinfo.scss';

const Appinfo = () => {
  return (
    <div className="app-info">
      <div className="app-info__head">
        <h3 className="app-info__title">
        <b>What we do? </b>A <span>hybrid</span> payment app
          
        </h3>
      </div>

    <div className="app-info__content">
      <div className="app-info__arhived">
        <div className="app-info__arhived-label">Achieved</div>
        <div className="app-info__row">
          <div className="app-info__icon">
            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.13433 20.1475C0.831221 19.9934 0.679688 19.6079 0.679688 19.2225C0.679688 19.1454 0.679688 18.9912 0.679688 18.9141C0.679688 13.2095 0.679688 7.5049 0.679688 1.87738C0.679688 1.56902 0.755454 1.33778 0.831221 1.02942C0.907032 0.875213 1.05857 0.721053 1.21014 0.566895C4.46851 3.88171 7.65111 7.19653 10.8337 10.5114C7.57535 13.5949 4.39274 16.8327 1.13433 20.1475Z" fill="#4385F6" />
            <path d="M14.0161 7.11936C13.1068 8.04445 12.1217 8.96949 11.2124 9.97166C8.18136 6.811 5.07452 3.65034 2.04346 0.489677C2.04346 0.489677 2.04346 0.412598 2.11922 0.412598C6.05962 2.64817 9.99998 4.88379 14.0161 7.11936Z" fill="#34A855" />
            <path d="M2.04346 20.1474C5.07452 17.0638 8.18136 13.9803 11.2124 10.8967C12.0459 11.7447 12.9553 12.5927 13.9404 13.5177C9.99998 15.7534 6.05962 17.9889 2.11922 20.1474H2.04346Z" fill="#EA4235" />
            <path d="M14.471 13.2094C13.4859 12.2844 12.5767 11.3593 11.5916 10.4342C12.5767 9.43207 13.5618 8.4299 14.5468 7.42773C14.8499 7.58189 15.153 7.7361 15.4561 7.96734C16.2139 8.4299 17.0475 8.81534 17.8052 9.27787C18.0326 9.35495 18.1841 9.50915 18.3356 9.74039C18.6387 10.1258 18.6387 10.5113 18.3356 10.8968C18.1841 11.0509 18.0326 11.2051 17.8052 11.2822C16.7444 11.976 15.6077 12.5927 14.471 13.2094Z" fill="#F9BB04" />
          </svg>
          </div>
          <div className="app-info__icon">
            <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.8991 10.9548C13.8788 8.47623 15.8947 7.26812 15.9886 7.21414C14.8459 5.52027 13.0748 5.28778 12.4504 5.27117C10.9609 5.11343 9.52033 6.17625 8.7613 6.17625C7.98999 6.17625 6.82282 5.28778 5.56185 5.31271C3.9417 5.3376 2.42769 6.29249 1.59927 7.77048C-0.114722 10.7887 1.16262 15.2226 2.80723 17.6638C3.62746 18.8594 4.58651 20.1921 5.84344 20.1464C7.07177 20.0966 7.53293 19.3493 9.01429 19.3493C10.4834 19.3493 10.916 20.1464 12.1974 20.1174C13.5156 20.0966 14.3481 18.9175 15.1397 17.7136C16.0906 16.3436 16.4701 14.9943 16.4865 14.9279C16.4538 14.9071 13.9236 13.9232 13.8991 10.9548ZM11.4792 3.6645C12.1403 2.82173 12.5932 1.68004 12.4667 0.517578C11.5118 0.559072 10.312 1.19012 9.62641 2.01217C9.01838 2.73869 8.47152 3.92604 8.61438 5.04284C9.68357 5.12171 10.7895 4.49066 11.4792 3.6645Z" fill="black" />
            </svg>
          </div>
          <p className="app-info__text">Hybrid payment app</p>

          <div className="app-info__border"></div>
        </div>

        <div className="app-info__row">
          <p className="app-info__text">PISP Licence Lithuania (EU) & UK</p>

          <div className="app-info__border"></div>
        </div>

        <div className="app-info__row">
          <p className="app-info__text">Crypto transfers</p>

          <div className="app-info__border"></div>
        </div>

        <div className="app-info__row">
          <p className="app-info__text--yellow">first mover</p>
          <p className="app-info__text">Cash payments (IBAN)</p>

          <div className="app-info__border"></div>
        </div>

        <div className="app-info__row">
          <p className="app-info__text">Contactless payment (QR code)</p>

          <div className="app-info__border"></div>
        </div>

        <div className="app-info__row">
          <p className="app-info__text">Non custodial</p>

          <div className="app-info__border"></div>
        </div>

        <div className="app-info__row">
          <div className="app-info__icon">
            <img src={EthPic} alt="eth" />
          </div>

          <div className="app-info__icon">
            <img src={BinancePic} alt="binance pic" />
          </div>

          <p className="app-info__text">Ethereum Mainnet & Binance Smart Chain</p>

          <div className="app-info__border"></div>
        </div>
        <div className="app-info__row">
          <p className="app-info__text">Connecting wallets & accounts</p>

          <div className="app-info__border"></div>
        </div>

        <div className="app-info__row">
          <p className="app-info__text">p2p loans</p>

          <div className="app-info__border"></div>
        </div>

        <div className="app-info__row">
          <p className="app-info__text">Staking</p>

          <div className="app-info__border"></div>
        </div>

        <div className="app-info__row">
          <p className="app-info__text">Swap</p>

          <div className="app-info__border"></div>
        </div>
      </div>

      <div className="app-info__graphik">
        <img className="app-info__graphik-desktop" src={GraphikDesktop} alt="graphik img" />
        <p className="app-info__graphik-text">
          Mobile app<br/>
          & Web app
        </p>
      </div>

      <div className="app-info__coming">
      <div className="app-info__coming-label">Up coming features</div>
        <div className="app-info__row">
          <div className="app-info__border"></div>
          <p className="app-info__text">Google Pay, Apple Pay, Samsung Pay</p>
          <div className="app-info__icon">
            <img src={GooglePayLogo} alt="google pay icon" />
          </div>
          <div className="app-info__icon">
            <img src={ApplePayLogo} alt=" apple pay icon" />
          </div>
          <div className="app-info__icon">
            <img src={PayPic} alt="pay icon" />
          </div>
        </div>

        <div className="app-info__row">
          <div className="app-info__border"></div>
          <p className="app-info__text">Crypto transaction mark-ups</p>
        </div>

        <div className="app-info__row">
          <div className="app-info__border"></div>
          <p className="app-info__text">Virtual debit card</p>
          <div className="app-info__icon">
            <img src={VisaPic} alt="visa card icon" />
          </div>
          <div className="app-info__icon">
            <img src={MartercardPic} alt="martercard icon" />
          </div>
        </div>

        <div className="app-info__row">
          <div className="app-info__border"></div>
          <p className="app-info__text">SaaS white label</p>
        </div>

        <div className="app-info__row">
          <div className="app-info__border"></div>
          <p className="app-info__text">Remittance</p>
        </div>

        <div className="app-info__row">
          <div className="app-info__border"></div>
          <p className="app-info__text">Data monetization</p>
        </div>

        <div className="app-info__row">
          <div className="app-info__border"></div>
          <p className="app-info__text">AI based spendings & portfolio analysis</p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Appinfo;