import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import StefanPic from "../../assets/img/membersSection/steffen.png";
import JohnsPic from "../../assets/img/membersSection/Sheridan.JPG";
import KraylPic from "../../assets/img/membersSection/Guido.JPG";
import NiculescuPic from "../../assets/img/membersSection/Adrian.JPG";
import StaarmanPic from "../../assets/img/membersSection/daniel.png";
import WolffPic from "../../assets/img/membersSection/Jurgen.JPG";
import InIcon from "../../assets/img/in.png";
import "./Members.scss";

const Members = () => {
     // Функция для создания универсального состояния расширения элемента
     const useExpansionState = (initialStates) => {
      const [expanded, setExpanded] = useState(initialStates.reduce((acc, curr) => {
          acc[curr.name] = curr.initialState;
          return acc;
      }, {}));
      
      const toggleExpansion = (name) => {
          setExpanded(prevState => ({
              ...prevState,
              [name]: !prevState[name],
          }));
      };
      
      return [expanded, toggleExpansion];
  };
  
  // Создаем универсальные состояния для каждого расширяемого элемента
  const [expandedStates, toggleExpansion] = useExpansionState([
      { name: 'Steffen', initialState: false },
      { name: 'Sheridan', initialState: false },
      { name: 'Guido', initialState: false },
      { name: 'Juergen', initialState: false },
      { name: 'Adrian', initialState: false },
      { name: 'Daniel', initialState: false },
      // Добавьте здесь другие элементы, если нужно
  ]);

  return (
    <>
    <div className="members">
      <div className="members__head">
        <h3 className="members__title"><span>Advisory </span> board members (OÜ)</h3>
      </div>

      <div className="roadmap__shadow">
        <div className="roadmap__shadow-inner"></div>
      </div>

      <Swiper        
        modules={[Pagination, Navigation]}
        navigation={true}
        pagination={{ clickable: true }}
        spaceBetween={26}
        slidesPerView={'auto'}
        className="mySwiper members__slider"
        // breakpoints={{
        //   320: {
        //     slidesPerView: 2,
        //   },
        //   768: {
        //     slidesPerView: 4,
        //   },
        // }}
        >

        <SwiperSlide>
          <div className="members__slide">
            <div className="members__slide-picture">
              <img className="members__slide-pic" src={StefanPic} alt="pic" />

              <a className="members__slide-icon" href="https://www.linkedin.com/in/steffenbassler/" target='_blank'>
              <img className="members__slide-icon" src={InIcon} alt="icon" />
              </a>
            </div>

            <div className="members__slide-info">
              <p className="members__slide-name">Steffen Bassler</p>
              <p className="members__slide-position">SwissOne Capital</p>
            </div>

            <div className={`members__slide-footer ${expandedStates['Steffen'] ? 'expanded' : ''}`}>
              <p className="members__slide-text">
              Steffen, 51, is CEO of Swiss One Capital and managing Partner of Capstan Capital Partners, an independent, privately owned boutique investment-banking firm with offices in London, Germany and Switzerland. He has over 15 years’ experience in Investment Banking as European Head at CreditSuisse, Strategy Consulting and Asset Management. After his studies he started with an international trainee program for Daimler Financial Services AG and holds a MBA (Dipl.-Kfm.) degree in Finance & Entrepreneurship at the European Business School (ebs) in Germany, is FCA-(UK) as well as Swiss Board School (HSG)-certified for advisory boards and actively serves as board member for a Swiss Crypto Asset Management firm. Steffen is also engaged as a business angel for Swiss start-up companies, regularly publishes articles in several Swiss business & economic publications and is university lecturer for Corporate Finance at the European Business School (ebs) in Germany.
              </p>

              <button type="button" className="members__slide-btn" onClick={() => toggleExpansion('Steffen')}>
                {expandedStates['Steffen'] ? 'Close' : 'Read more'}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" strokeWidth="1.6" />
                </svg>
              </button>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="members__slide">
            <div className="members__slide-picture">
              <img className="members__slide-pic" src={JohnsPic} alt="Sheridan Johns" />
              <a className="members__slide-icon" href="https://www.linkedin.com/in/sjohnsiv/" target='_blank'>
              <img className="members__slide-icon" src={InIcon} alt="icon" />
              </a>
            </div>

            <div className="members__slide-info">
              <p className="members__slide-name">Sheridan Johns</p>
              <p className="members__slide-position">Ocean Protocol</p>
            </div>

            <div className={`members__slide-footer ${expandedStates['Sheridan'] ? 'expanded' : ''}`}>
              <p className="members__slide-text">
              Sheridan, 53, is part of the founding team of Ocean Protocol (Bitchain DB) and serving as their Head of Partnerships & Growth. In his current role he is responsible for coordinating activities between technical engineering and business development teams aimed at delivering value for clients and increasing adoption of open source technology. Before that, Sheridan set-up Berlin School of Creative Leadership and made it grow during 8 years. Sheridan studied in Granville (Ohio), Stockholm, Paris, Berlin and graduated from John Hopkins University in Washington D.C.
              </p>

              <button type="button" className="members__slide-btn" onClick={() => toggleExpansion('Sheridan')}>
                {expandedStates['Sheridan'] ? 'Close' : 'Read more'}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" strokeWidth="1.6" />
                </svg>
              </button>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="members__slide">
            <div className="members__slide-picture">
              <img className="members__slide-pic" src={KraylPic} alt="Guido Krayl pic" />
              <a className="members__slide-icon" href="https://www.linkedin.com/in/guido-krayl-370507a/" target='_blank'>
              <img className="members__slide-icon" src={InIcon} alt="icon" />
              </a>
            </div>

            <div className="members__slide-info">
              <p className="members__slide-name">Guido Krayl</p>
              <p className="members__slide-position">Absolutweb</p>
            </div>

            <div className={`members__slide-footer ${expandedStates['Guido'] ? 'expanded' : ''}`}>
              <p className="members__slide-text">
              Guido Krayl, 56, is partner at Absolutweb. Before that, he worked as CBO at IT Works group and worked for various TV stations and streaming providers. He has marketed various TV channels. His stations were ARD Sales and Services, Super RTL. As Marketing Manager at VOX Television, he was responsible for new business, sponsoring and marketing. As CEO, he successfully built up the swiss company Video Intelligence in the DACH region and turned it into an international company. Since 2019 he is currently responsible for public media as the market leader's CBO. One of its customers is a leading crypto exchange. Guido got involved with crypto and blockchain very early on and has invested personally.
              </p>

              <button type="button" className="members__slide-btn" onClick={() => toggleExpansion('Guido')}>
                {expandedStates['Guido'] ? 'Close' : 'Read more'}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" strokeWidth="1.6" />
                </svg>
              </button>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="members__slide">
            <div className="members__slide-picture">
              <img className="members__slide-pic" src={WolffPic} alt="pic" />
              <a className="members__slide-icon" href="https://www.linkedin.com/in/dr-j%C3%BCrgen-wolff-2833652/" target='_blank'>
              <img className="members__slide-icon" src={InIcon} alt="icon" />
              </a>
            </div>

            <div className="members__slide-info">
              <p className="members__slide-name">Dr. Jürgen Wolff</p>
              <p className="members__slide-position">A352.io</p>
            </div>

            <div className={`members__slide-footer ${expandedStates['Juergen'] ? 'expanded' : ''}`}>
              <p className="members__slide-text">
              Juergen, 52, is an innovator and entrepreneur of payment solutions, digital currency, and financial technology. He was the founder and CEO of Mercedes pay (formerly PayCash) and led the company, a patent-pending fintech company offering e-wallet (B2C) and payment services (B2B) in EU, China, US, and Canada, from inception to acquisition by Daimler AG. Mercedes pay was the first regulated institution to legally cooperate with US Bitcoin exchanges in Luxembourg (CSSF approved). Specialties: Building and leading purposeful organizations.
              </p>

              <button type="button" className="members__slide-btn" onClick={() => toggleExpansion('Juergen')}>
                {expandedStates['Juergen'] ? 'Close' : 'Read more'}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" strokeWidth="1.6" />
                </svg>
              </button>
            </div>
          </div>
        </SwiperSlide>
        
        <SwiperSlide>
          <div className="members__slide">
            <div className="members__slide-picture">
              <img className="members__slide-pic" src={NiculescuPic} alt="pic" />
              <a className="members__slide-icon" href="https://www.linkedin.com/in/adrianniculescu/" target='_blank'>
              <img className="members__slide-icon" src={InIcon} alt="icon" />
              </a>
            </div>

            <div className="members__slide-info">
              <p className="members__slide-name">Adrian Niculescu</p>
              <p className="members__slide-position">CloudCoin Consortium</p>
            </div>

            <div className={`members__slide-footer ${expandedStates['Adrian'] ? 'expanded' : ''}`}>
              <p className="members__slide-text">
              Adrian Niculescu, 48, entrepreneur, investor, and visionary, currently leading as Founder and CEO of TOMORROWerse Capital. With a deep-seated passion for sales, marketing, and business innovation, Adrian's diverse journey has seen roles across blockchain, cryptocurrency, copywriting, gaming, real estate, venture capital, fintech, and culminating in music production. His extensive background includes impactful positions at Latoken, Euroest, and Business Gate Dubai. Equipped with an MBA and comprehensive non-formal education, Adrian's strategic prowess and leadership are propelling fintech projects to the forefront of innovation.
              </p>

              <button type="button" className="members__slide-btn" onClick={() => toggleExpansion('Adrian')}>
                {expandedStates['Adrian'] ? 'Close' : 'Read more'}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" strokeWidth="1.6" />
                </svg>
              </button>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="members__slide">
            <div className="members__slide-picture">
              <img className="members__slide-pic" src={StaarmanPic} alt="Daniel Staarmann pic" />
              <a className="members__slide-icon" href="https://www.linkedin.com/in/daniel-staarmann/" target='_blank'>
              <img className="members__slide-icon" src={InIcon} alt="icon" />
              </a>
            </div>

            <div className="members__slide-info">
              <p className="members__slide-name">Daniel Staarmann</p>
              <p className="members__slide-position">Advanced Digital Experts</p>
            </div>

            <div className={`members__slide-footer ${expandedStates['Daniel'] ? 'expanded' : ''}`}>
              <p className="members__slide-text">
              Daniel Staarmann, 43, is an entrepreneur and telecommunications top manager holding an MSc from Tilburg University, The Netherlands. Previous to his current management consultancy, Daniel was CEO of Ortel Mobile, a Telefonica subsidiary, and Manager at E-Plus KPN group in charge of their MVNO portfolio, responsible for 400 million EUR annual turnover. He is totaling 10 years of experience in telecommunications. He also set-up Debao babyshop, enabling parents in China to access German and European authentic, high quality products of the mom and child category, which are not available otherwise. He is CEO and co-founder of Company.kitchen, a start-up accelerator and of Advanced Digital Experts.
              </p>

              <button type="button" className="members__slide-btn" onClick={() => toggleExpansion('Daniel')}>
                {expandedStates['Daniel'] ? 'Close' : 'Read more'}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" strokeWidth="1.6" />
                </svg>
              </button>
            </div>
          </div>
        </SwiperSlide>


      </Swiper>
    </div>

    </>
  );
}

export default Members;