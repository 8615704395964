import React, { useState } from 'react';
import ViktorImg from '../../assets/img/victor.png';
import EvgeniyaMikhaylovaImg from '../../assets/img/Evgeniya.png';
import TarasImg from '../../assets/img/taras.png';
import NilsImg from '../../assets/img/nils.png';
import MarcoImg from '../../assets/img/Marco.JPG';
import InIcon from "../../assets/img/in.png";
import BloombergIcon from "../../assets/img/bloomberg.png";
import BookingIcon from "../../assets/img/booking.svg";
import './ManagementSection.scss';

const ManagementSection = () => {
   // Функция для создания универсального состояния расширения элемента
   const useExpansionState = (initialStates) => {
    const [expanded, setExpanded] = useState(initialStates.reduce((acc, curr) => {
        acc[curr.name] = curr.initialState;
        return acc;
    }, {}));
    
    const toggleExpansion = (name) => {
        setExpanded(prevState => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };
    
    return [expanded, toggleExpansion];
};

// Создаем универсальные состояния для каждого расширяемого элемента
const [expandedStates, toggleExpansion] = useExpansionState([
    { name: 'Nils', initialState: false },
    { name: 'Marco', initialState: false },
    { name: 'Victor', initialState: false },
    { name: 'Taras', initialState: false },
    { name: 'Evgeniya', initialState: false },
    // Добавьте здесь другие элементы, если нужно
]);
  return (
    <div className="management-section">
      <div className="management-section__head">
        <h3 className="management-section__title">

          <span>Managment</span>
        </h3>

        <p className="management-section__text">
        Paypolitan started in 2018 and we are proud of our achievements so far. The fundament of every success is simple: a good team. Discover the brains behind Paypolitan.
        </p>
      </div>

      <div className="team__label">
        <div className="team__label-text">Co-founders</div>
        <div className="team__label-line">
          <svg width="1005" height="1" viewBox="0 0 1005 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="4.37114e-08" y1="0.5" x2="1005" y2="0.500088" stroke="url(#paint0_linear_675_2811)" stroke-opacity="0.4"/>
            <defs>
            <linearGradient id="paint0_linear_675_2811" x1="502.5" y1="1.00004" x2="1005" y2="1.00009" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BD1D83"/>
            <stop offset="1" stop-color="#BD1D83" stop-opacity="0"/>
            </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div className="management-section__founders">
        <div className="management-section__founder">
          <div className="management-section__founder-picture">
            <img className="management-section__founder-img" src={NilsImg} alt="Nils Tharandt Ortiz" />
            <a className='management-section__founder-linkedin' href="https://www.linkedin.com/in/nils-tharandt-ortiz-b07b1016/" target="_blank" rel="noopener noreferrer">
            <img className="management-section__founder-icon" src={InIcon} alt="in" />
            </a>
          </div>

          <div className="management-section__founder-naiming">
              <h4 className="management-section__founder-name">Nils Tharandt Ortiz</h4>
              <p className="management-section__founder-position">CEO</p>
            </div>

          <div className={`management-section__founder-info ${expandedStates['Nils'] ? 'expanded' : ''}`}>

            <div className="management-section__founder-row">
              <p className="management-section__founder-descr">Nils, 51, is an entrepreneur, lawyer by education holding 2 legal masters from University of Paris Seine and a specialization in human rights from René Cassin Institute, Strasbourg. After 6 years working at embassies and international organizations, he left for the private sector and made successfully 2 exits: directory assistance SCOOT 118 000 sold to Seat Pagine Gialle (Italian Yellow Pages) and Best Of Artisans, the French craig's list, sold to Bertelsmann France. He is working in telecommunications for 20 years. He was also the official State rep of Louisiana to France until 2019 and then served as Regional director of EDB Bahrain in France and Germany. He won the 1st price of the Oracle Start-up Award Paris in 2001. He is on the board of Aragon Telecom and SwissOne Capital, a listed company.</p>

              <button type="button" className="management-section__founder-btn" onClick={() => toggleExpansion('Nils')}>
          {expandedStates['Nils'] ? 'Close' : 'Read more'}
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" strokeWidth="1.6" />
          </svg>
        </button>
            </div>

            <div className="management-section__founder-row">
              <div className="management-section__founder-subrow">
                <p className="management-section__founder-text">Favorite website:</p>
                <a className="management-section__founder-link" href="https://booking.com" target="_blank">
                  <img src={BookingIcon} alt="booking" />
                  Booking.com
                  </a>
              </div>

              <div className="management-section__founder-subrow">
                <p className="management-section__founder-text">Discovered blockchain in:</p>
                <p className="management-section__founder-text"><strong>2012</strong></p>
              </div>
            </div>

            <div className="management-section__founder-row">
              <p className="management-section__founder-words">
              “Nils is combining entrepreneurship and inspiration with fast execution.”
              </p>
              <p className="management-section__founder-author">Marco Roemeth</p>
            </div>

          </div>
        </div>

        <div className="management-section__founder">
          <div className="management-section__founder-picture">
            <img className="management-section__founder-img" src={MarcoImg} alt="Marco Roemeth" />
            <a className='management-section__founder-linkedin' href="https://www.linkedin.com/in/marco-roemeth-97937542/" target="_blank" rel="noopener noreferrer">
            <img className="management-section__founder-icon" src={InIcon} alt="in" />
            </a>
          </div>

          <div className="management-section__founder-naiming">
              <h4 className="management-section__founder-name">Marco Roemeth</h4>
              <p className="management-section__founder-position">CFO</p>
            </div>

          <div className={`management-section__founder-info ${expandedStates['Marco'] ? 'expanded' : ''}`}>
            <div className="management-section__founder-row">
              <p className="management-section__founder-descr">
              Marco, 52, is a trained lawyer and real estate economist. He has worked in the banking industry for almost 15 years, among others at Dresdner Kleinwort and Bayerische Landesbank (Bavarian State Bank). Before that he worked for a couple of years at Ernst & Young and German Thai Chamber of Commerce in Bangkok, Thailand.
              </p>

              <button type="button" className="management-section__founder-btn" onClick={() => toggleExpansion('Marco')}>
              {expandedStates['Marco'] ? 'Close' : 'Read more'}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" strokeWidth="1.6" />
                </svg>
              </button>
            </div>

            <div className="management-section__founder-row">
              <div className="management-section__founder-subrow">
                <p className="management-section__founder-text">Favorite website:</p>
                <a className="management-section__founder-link" href="https://www.bloomberg.com/europe" target="_blank">
                  <img src={BloombergIcon} alt="Bloomberg" />
                  Bloomberg.com
                  </a>
              </div>

              <div className="management-section__founder-subrow">
                <p className="management-section__founder-text">Discovered blockchain in:</p>
                <p className="management-section__founder-text"><strong>2016</strong></p>
              </div>
            </div>

            <div className="management-section__founder-row">
              <p className="management-section__founder-words">
              “Working with Marco is a perfect match: we are a complementary tandem.”
              </p>
              <p className="management-section__founder-author">Nils Tharandt Ortiz</p>
            </div>

          </div>
        </div>
      </div>

      <div className="team__label">
        <div className="team__label-text">Managers</div>
        <div className="team__label-line">
          <svg width="1005" height="1" viewBox="0 0 1005 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="4.37114e-08" y1="0.5" x2="1005" y2="0.500088" stroke="url(#paint0_linear_675_2811)" stroke-opacity="0.4"/>
            <defs>
            <linearGradient id="paint0_linear_675_2811" x1="502.5" y1="1.00004" x2="1005" y2="1.00009" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BD1D83"/>
            <stop offset="1" stop-color="#BD1D83" stop-opacity="0"/>
            </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div className="management-section__managers">
        <div className="management-section__manager">
          <div className="management-section__manager-picture">
            <img className="management-section__manager-pic" src={ViktorImg} alt="Victor Cristinari" />
            <a className='management-section__manager-icon-link' href="https://www.linkedin.com/in/victor-cristinari-11039b5/" target="_blank">
            <img className="management-section__manager-icon" src={InIcon} alt="" />
            </a>
          </div>

          <div className="management-section__manager-info">
            <p className="management-section__manager-name">Victor Cristinari</p>
            <p className="management-section__manager-position">CTO</p>
          </div>

          <div className={`management-section__manager-footer ${expandedStates['Viktor'] ? 'expanded' : ''}`}>
            <p className="management-section__manager-descr">
            Victor, 43, is an entrepreneur and software architect, holding a BS degree in Computer Science from the Technical University of Iasi, Romania. Victor has designed and maintained various highly scalable software platforms and was involved with various start-ups in Europe and US.
            </p>

            <button type='button' className="management-section__manager-btn" onClick={() => toggleExpansion('Viktor')}>
            {expandedStates['Viktor'] ? 'Close' : 'Read more'}

              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" stroke-width="1.6" />
              </svg>
            </button>
          </div>
        </div>

        <div className="management-section__manager">
          <div className="management-section__manager-picture">
            <img className="management-section__manager-pic" src={TarasImg} alt="Taras Mytalik" />
            <a className='management-section__manager-icon-link' href="https://www.linkedin.com/in/taras-mytkalyk/" target="_blank">
            <img className="management-section__manager-icon" src={InIcon} alt="" />
              </a>
          </div>

          <div className="management-section__manager-info">
            <p className="management-section__manager-name">Taras Mytalik</p>
            <p className="management-section__manager-position">Head of Marketing</p>
          </div>

          <div className={`management-section__manager-footer ${expandedStates['Taras'] ? 'expanded' : ''}`}>
            <p className="management-section__manager-descr">
            Taras, 34, is leading the marketing team. He worked previously at L’Oréal Paris and Hasbro as brand manager and head of digital marketing. He also set up a special projects department at Starlight Media, biggest media holding of Ukraine, and launched digital campaigns for major local and international companies. He holds Project Management Master’s degree from National Technical University of Ukraine “Kyiv Polytechnic Institute”, and a Marketing BA degree from university of economics and law “Krok” and English language BS degree from Kyiv National Linguistic University.
            </p>

            <button type='button' className="management-section__manager-btn" onClick={() => toggleExpansion('Taras')}>
            {expandedStates['Taras'] ? 'Close' : 'Read more'}

              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" stroke-width="1.6" />
              </svg>
            </button>
          </div>
        </div>

        <div className="management-section__manager">
          <div className="management-section__manager-picture">
            <img className="management-section__manager-pic" src={EvgeniyaMikhaylovaImg} alt="Evgeniya Mikhaylova" />
            <a className='management-section__manager-icon-link' href="https://www.linkedin.com/in/evgeniya-mihailova-1328135b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank">
            <img className="management-section__manager-icon" src={InIcon} alt="in" />
              </a>
          </div>

          <div className="management-section__manager-info">
            <p className="management-section__manager-name">Evgeniya Mikhaylova</p>
            <p className="management-section__manager-position">Director Financial Communications</p>
          </div>

          <div className={`management-section__manager-footer ${expandedStates['Evgeniya'] ? 'expanded' : ''}`}>
            <p className="management-section__manager-descr">
            Evgeniya, 39, is in charge of financial communications. She holds diplomas of business administration and finance of University of Shymkent, Kazakhstan, and Moscow State University. She worked 6 years at National Bank of Uzbekistan (NBU) in charge of currency conversion. Later, she worked as a trader in a Kiev based investment fund.
            </p>

            <button type='button' className="management-section__manager-btn" onClick={() => toggleExpansion('Evgeniya')}>
            {expandedStates['Evgeniya'] ? 'Close' : 'Read more'}

              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="#BD1D83" stroke-width="1.6" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagementSection;