// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy {
  margin: 108px auto 60px;
}
.privacy-policy p,
.privacy-policy li {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-family: "Futura PT Book", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #737380;
}
.privacy-policy a {
  color: #bd1d83;
  text-decoration: underline;
}
.privacy-policy h1,
.privacy-policy h2 {
  font-family: "Futura PT Book", sans-serif;
}
.privacy-policy ol,
.privacy-policy ul {
  list-style: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.privacy-policy li {
  display: list-item;
  list-style-type: circle;
}
.privacy-policy .privacy-alpha-list {
  list-style: lower-alpha !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPolicy/PrivacyPolicy.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AACE;;EAEE,uBAAA;EACA,qBAAA;EACA,sBAAA;EACA,oBAAA;EACA,yCAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEE;EACE,cAAA;EACA,0BAAA;AAAJ;AAGE;;EAEE,yCAAA;AADJ;AAIE;;EAEE,gBAAA;EACA,uBAAA;EACA,qBAAA;EACA,sBAAA;EACA,oBAAA;EACA,0BAAA;AAFJ;AAKE;EACE,kBAAA;EACA,uBAAA;AAHJ;AAME;EACE,kCAAA;AAJJ","sourcesContent":[".privacy-policy {\n  margin: 108px auto 60px;\n\n  p,\n  li {\n    margin-block-start: 1em;\n    margin-block-end: 1em;\n    margin-inline-start: 0;\n    margin-inline-end: 0;\n    font-family: \"Futura PT Book\", sans-serif;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 20px;\n    line-height: 24px;\n    color: #737380;\n  }\n\n  a {\n    color: #bd1d83;\n    text-decoration: underline;\n  }\n\n  h1,\n  h2 {\n    font-family: \"Futura PT Book\", sans-serif;\n  }\n\n  ol,\n  ul {\n    list-style: disc;\n    margin-block-start: 1em;\n    margin-block-end: 1em;\n    margin-inline-start: 0;\n    margin-inline-end: 0;\n    padding-inline-start: 40px;\n  }\n\n  li {\n    display: list-item;\n    list-style-type: circle;\n  }\n\n  .privacy-alpha-list {\n    list-style: lower-alpha !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
