// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookies {
  margin: 108px auto 60px;
}

.cookies p,
.cookies li,
.cookies th,
.cookies td {
  font-family: "Futura PT Book", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #737380;
}

.cookies a {
  color: #bd1d83;
  text-decoration: underline;
}

.cookies p {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.cookies h1,
.cookies h2 {
  font-family: "Futura PT Book", sans-serif;
}

.cookies table {
  border-collapse: collapse;
  border: 1px black solid;
}

.cookies th,
.cookies td {
  padding: 8px;
  border: 1px black solid;
}`, "",{"version":3,"sources":["webpack://./src/pages/Cookies/Cookies.scss"],"names":[],"mappings":"AAAA;EAEE,uBAAA;AAAF;;AAGA;;;;EAIE,yCAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,cAAA;EACA,0BAAA;AAAF;;AAGA;EACE,uBAAA;EACA,qBAAA;EACA,sBAAA;EACA,oBAAA;AAAF;;AAGA;;EAEE,yCAAA;AAAF;;AAGA;EACE,yBAAA;EACA,uBAAA;AAAF;;AAGA;;EAEE,YAAA;EACA,uBAAA;AAAF","sourcesContent":[".cookies {\n  // max-width: 900px;\n  margin: 108px auto 60px;\n}\n\n.cookies p,\n.cookies li,\n.cookies th,\n.cookies td {\n  font-family: \"Futura PT Book\", sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 24px;\n  color: #737380;\n}\n\n.cookies a {\n  color: #bd1d83;\n  text-decoration: underline;\n}\n\n.cookies p {\n  margin-block-start: 1em;\n  margin-block-end: 1em;\n  margin-inline-start: 0;\n  margin-inline-end: 0;\n}\n\n.cookies h1,\n.cookies h2 {\n  font-family: \"Futura PT Book\", sans-serif;\n}\n\n.cookies table {\n  border-collapse: collapse;\n  border: 1px black solid;\n}\n\n.cookies th,\n.cookies td {\n  padding: 8px;\n  border: 1px black solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
