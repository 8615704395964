
import React, { useRef, useState } from 'react';
import MediaBlockPic1 from '../../assets/img/Evgeniya.png';
import MediaBlockPic2 from '../../assets/img/media/media-block-pic-2.jpg';
import FileDownload from '../../assets/img/file-download.svg';
import ArrowDown from '../../assets/img/arrow-down.svg';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import { FORMSPARK_MEDIA_INQUERIES_ID } from '../../constants/config';

const FORMSPARK_ACTION_URL = "https://submit-form.com";

const AUTO_HIDE_DURATION = 5000; // 5 sec
const ANCHOR_ORIGIN = { vertical: "top", horizontal: "center" }

export const MediaInquiresForm = ({ showAll, downloads, onShowMore }) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [telegram, setTelegram] = useState("");
    const [message, setMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [failedSnackbarOpen, setFailedSnackbarOpen] = useState(false);

    const handleEmailChange = (ev) => {
        setEmail(ev.currentTarget.value);
    }

    const handleTelegramChange = (ev) => {
        setTelegram(ev.currentTarget.value);
    }

    const handleMessageChange = (ev) => {
        setMessage(ev.currentTarget.value);
    }

    const handleSubmit = (ev) => {
        ev.preventDefault();

        setLoading(true);
        submitInquiresForm({ email, telegram, message })
            .then(() => {
                setSuccessSnackbarOpen(true);
                setLoading(false)
            })
            .catch((error) => {
                console.error(error);
                setFailedSnackbarOpen(true);
                setLoading(false)
            });
    }

    const handleSuccessSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
    }

    const handleFaieldSnackbarClose = () => {
        setFailedSnackbarOpen(false);
    }

    return <form className="media__form" action="#" onSubmit={handleSubmit}>
        <Snackbar
            message="Media inquire sent"
            anchorOrigin={ANCHOR_ORIGIN}
            open={successSnackbarOpen}
            autoHideDuration={AUTO_HIDE_DURATION}
            onClose={handleSuccessSnackbarClose}
        />
        <Snackbar
            message="Failed to submit form"
            anchorOrigin={ANCHOR_ORIGIN}
            open={failedSnackbarOpen}
            autoHideDuration={AUTO_HIDE_DURATION}
            onClose={handleFaieldSnackbarClose} />
        <div className="media__downloads">
            <div className="media__form-label">Media downloads:</div>

            {downloads.slice(0, showAll ? downloads.length : 3).map((download, index) => (
                <a key={index} className="media__download" href={download.url} download>
                    <img src={FileDownload} alt="icon" />
                    <span>{download.name}</span>
                    <img src={ArrowDown} alt="icon" />
                </a>
            ))}

            <button type='button' className="media__download-btn main-btn main-btn--transparent" onClick={onShowMore}>
                {showAll ? 'Hidden back' : 'Show more'}
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" strokeWidth="1.6" />
                </svg>
            </button>
        </div>

        <div className="media__inqueries">
            <div className="media__form-label">Media inqueries:</div>

            <div className="media__blocks">
                <div className="media__block">
                    <img className="media__block-pic" src={MediaBlockPic1} width="70" height="70" alt="pic" />

                    <div className="media__block-info">
                        <p className="media__block-name">Evgeniya Mikhaylova</p>
                        <div className="media__block-descr">Director Financial Communications</div>
                    </div>
                </div>

                <div className="media__block">
                    <img className="media__block-pic" src={MediaBlockPic2} width="70" height="70" alt="pic" />

                    <div className="media__block-info">
                        <p className="media__block-name">Mhoch4 PR</p>
                        <div className="media__block-descr">Press inquiries, Julian Krebs phone: <a href="tel:+4940298131213">+49 40 298 13 1213</a></div>
                    </div>
                </div>
            </div>

            <div className="media__inputs">
                <input className="media__input" type="email" placeholder='E-mail' value={email} required onChange={handleEmailChange} />
                <input className="media__input" type="text" placeholder='Telegram' value={telegram} onChange={handleTelegramChange} />
            </div>

            <textarea className='media__input media__input--textarea' placeholder='Your Message' value={message} required onChange={handleMessageChange}></textarea>

            <button type='submit' className="media__btn main-btn main-btn--yellow" disabled={loading}>
                Submit

                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 7H13M13 7L6.74074 1M13 7L6.74074 13" stroke="black" stroke-width="1.6" />
                </svg>
            </button>

        </div>
    </form>
}

MediaInquiresForm.propTypes = {
    showAll: PropTypes.bool.isRequired,
    showMore: PropTypes.bool.isRequired,
    onShowMore: PropTypes.func.isRequired,
    downloads: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    })).isRequired
}

async function submitInquiresForm(formData) {
    const response = await fetch(FORMSPARK_ACTION_URL + "/" + FORMSPARK_MEDIA_INQUERIES_ID, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(formData),
    });

    if (!response.ok) {
        throw new Error(`failed to subscribe: ${response.status} ${response.statusText}`);
    }
}