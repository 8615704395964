import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import "./LogosSlider.scss";

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/img/partners/', false, /\.(png|jpe?g|svg)$/));

const LogosSlider = () => {
  return (
    <>
      <Swiper        
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay={{ delay: 1000 }}
        spaceBetween={30}
        className="mySwiper logos-slider"
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 4,
          },
        }}
        >

        {images.map((image, index) => {
        const imageName = image.split('/').pop().split('.')[0]; // Получаем имя файла без расширения
        return (
          <SwiperSlide key={index}>
            <img src={image} alt={imageName} />
          </SwiperSlide>
        );
      })}
      </Swiper>
    </>
  );
}

export default LogosSlider;



