// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics {
  padding: 16px;
  display: flex;
}
@media all and (min-width: 0) and (max-width: 722px) {
  .statistics {
    display: none;
  }
}
.statistics-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  min-width: 52px;
}
.statistics-item:first-child {
  margin-right: 30px;
}
.statistics-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.statistics-item-title span {
  font-family: "Futura PT", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: rgba(31, 32, 36, 0.56);
}
.statistics-item-price {
  font-size: 14px;
}
.statistics-item-price:first-child {
  margin-left: 5px;
}

.positive {
  color: #59b679;
  margin-left: 5px;
}

.negative {
  color: #df516a;
  margin-left: 5px;
}

.header-container-additional-statistics {
  background: transparent;
  border-radius: 0;
  padding: 0 0 0 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Statistics/Statistics.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;AACJ;AACI;EAJJ;IAKQ,aAAA;EAEN;AACF;AAGI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;EACA,eAAA;AADR;AAGQ;EACI,kBAAA;AADZ;AAIQ;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AAFZ;AAIY;EACI,oCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;AAFhB;AAMQ;EACI,eAAA;AAJZ;AAKY;EACI,gBAAA;AAHhB;;AASA;EACI,cAAA;EACA,gBAAA;AANJ;;AASA;EACI,cAAA;EACA,gBAAA;AANJ;;AASA;EACI,uBAAA;EACA,gBAAA;EACA,mBAAA;AANJ","sourcesContent":[".statistics {\n    padding: 16px;\n    display: flex;\n\n    @media all and (min-width: 0) and (max-width: 722px) {\n        display: none;\n    }\n\n    @media all and (min-width: 0) and (max-width: 1200px) {\n    }\n\n    &-item {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        flex-wrap: nowrap;\n        min-width: 52px;\n\n        &:first-child {\n            margin-right: 30px;\n        }\n\n        &-title {\n            display: flex;\n            flex-direction: row;\n            align-items: center;\n\n            span {\n                font-family: \"Futura PT\", sans-serif;\n                font-style: normal;\n                font-weight: normal;\n                font-size: 10px;\n                line-height: 18px;\n                color: rgba(31, 32, 36, 0.56);\n            }\n        }\n\n        &-price {\n            font-size: 14px;\n            &:first-child {\n                margin-left: 5px;\n            }\n        }\n    }\n}\n\n.positive {\n    color: #59b679;\n    margin-left: 5px;\n}\n\n.negative {\n    color: #df516a;\n    margin-left: 5px;\n}\n\n.header-container-additional-statistics {\n    background: transparent;\n    border-radius: 0;\n    padding: 0 0 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
