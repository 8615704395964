import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import BtcPic from "../../assets/img/btc-pic.png"
import BorderCircle from "../../assets/img/roadmap-border-circle.svg"
import "./Roadmap.scss";

const Roadmap = () => {
  return (
    <>
    <div className="roadmap">
      <div className="roadmap__head">
        <h3 className="roadmap__title"><span>Roadmap</span></h3>
      </div>

      <Swiper        
        modules={[Pagination, Navigation]}
        navigation={true}
        pagination={{ clickable: true }}
        spaceBetween={26}
        slidesPerView={'auto'}
        className="mySwiper roadmap__slider"
        // breakpoints={{
        //   320: {
        //     slidesPerView: 2,
        //   },
        //   768: {
        //     slidesPerView: 4,
        //   },
        // }}
        >

        <SwiperSlide>
          <div className="roadmap__slide">
            <div className="roadmap__slide-year">2007</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC doesn’t exist</span>
            </div>
            <p className="roadmap__slide-text">
              Team members work for the first time together
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">2012</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 6</span>
            </div>
            <p className="roadmap__slide-text">
            Founders discovered Bitcoin & blockchain
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">2018</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 6 267</span>
            </div>
            <p className="roadmap__slide-text">
            Team starts development
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">2019</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 3 160</span>
            </div>
            <p className="roadmap__slide-text">
            Building architecture, creating tokens
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">2020</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 6 600</span>
            </div>
            <p className="roadmap__slide-text">
            Global pandemic COVID-19 hits hard
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q4 2020</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 18 689</span>
            </div>
            <p className="roadmap__slide-text">
            Successful IDO on UNISWAP
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q1 2021</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 49 787</span>
            </div>
            <p className="roadmap__slide-text">
            Listing on BitForex
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q2 2021</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 58 060 </span>
            </div>
            <p className="roadmap__slide-text">
              App launch Apple App Store and Google Play
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q3 2021</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 45 800</span>
            </div>
            <p className="roadmap__slide-text">
            BEPAN BSC release	staking 
            </p>
            <p className="roadmap__slide-text">
            Building sales team
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q4 2021</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 38 483 </span>
            </div>
            <p className="roadmap__slide-text">
            New website	implementation p2p loans
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q2 2022</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 23 336 </span>
            </div>
            <p className="roadmap__slide-text">
            Delivery of major white label banking solution
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q4 2022</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 23 139 </span>
            </div>
            <p className="roadmap__slide-text">
            Open Banking alpha version
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q1 2023</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span> BTC: $ 29 268 </span>
            </div>
            <p className="roadmap__slide-text">
            IT relocation
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q2 2023</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 29 230</span>
            </div>
            <p className="roadmap__slide-text">
            Bitmart listing<br/>
            More then 1900 banks<br/>
            implemented
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q3 2023</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 27 968</span>
            </div>
            <p className="roadmap__slide-text">
            Paypolitan Inc. USA is founded
            </p>
            <p className="roadmap__slide-text">Start Series A round 
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q1 2024</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span>BTC: $ 71 170</span>
            </div>
            <p className="roadmap__slide-text">
              New website v4<br/>
              French, Spanish version of the website
             </p>

              <p className="roadmap__slide-text">
              New referral program </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q2 2024</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span></span>
            </div>
            <p className="roadmap__slide-text">Web Referral
            </p>

            <p className="roadmap__slide-text">New language versions of the website: Russian, Dutch, Romanian, Ukrainian, German
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q3 2024</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span></span>
            </div>
            <p className="roadmap__slide-text">Launch debit card
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q4 2024</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span></span>
            </div>
            <p className="roadmap__slide-text">SaaS lounge<br/>
             App revamp, launch of several new language versions: German, French, Spanish, Russian, Dutch, Romanian, Ukrainian
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q1 2025</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span></span>
            </div>
            <p className="roadmap__slide-text">Launch of Chinese, Hindi and Arabic versions of app
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q2 2025</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span></span>
            </div>
            <p className="roadmap__slide-text">Capital market listing
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q4 2025</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span></span>
            </div>
            <p className="roadmap__slide-text">Delivery of e-money license certificate 
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="roadmap__slide">
            <div className="roadmap__slide-year">Q2 2026</div>
            <div className="roadmap__slide-border">
              <img className='roadmap__slide-circle' src={BorderCircle} alt="border icon" />
              <div className="roadmap__slide-line"></div>
            </div>
            <div className="roadmap__slide-btc">
              <img className="roadmap__slide-icon" src={BtcPic} alt="btn icon" />

              <span></span>
            </div>
            <p className="roadmap__slide-text">Launch of remittance services
            </p>
          </div>
        </SwiperSlide>

      </Swiper>
    </div>

    </>
  );
}

export default Roadmap;